<template>
  <div v-loading="loading">
    <div v-if="(list && list.length) || loading" class="table-wrapper">
      <table class="table">
        <thead>
        <tr>
          <th>
            <SortLabel
                label="URL"
                :order="$_sortingMixin_checkOrder('url')"
                @click.native="$_sortingMixin_doSort('url')"
            />
          </th>
          <th>
            <SortLabel
                label="Anchor"
                :order="$_sortingMixin_checkOrder('anchor')"
                @click.native="$_sortingMixin_doSort('anchor')"
            />
          </th>
          <th v-if="!networkSiteDetermined">
            <SortLabel
                label="Network Site"
                :order="$_sortingMixin_checkOrder('network_site')"
                @click.native="$_sortingMixin_doSort('network_site')"
            />
          </th>
          <th>
            <SortLabel
                label="Rel"
                :order="$_sortingMixin_checkOrder('rel')"
                @click.native="$_sortingMixin_doSort('rel')"
            />
          </th>
          <th>
            <SortLabel
                label="Content Type"
                :order="$_sortingMixin_checkOrder('type')"
                @click.native="$_sortingMixin_doSort('type')"
            />
          </th>
        </tr>
        </thead>

        <tbody v-if="list">
        <tr v-for="item in list" :key="item.id">
          <td class="url-cell">
            {{ item.url }}
          </td>

          <td>{{ item.anchor }}</td>

          <td v-if="!networkSiteDetermined">
            {{ item.network_site }}
          </td>

          <td>{{ item.rel }}</td>

          <td>{{ item.type }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EmptyList from '@/components/global/EmptyList'
import SortLabel from '@/components/global/SortLabel'
import sortingMixin from '@/mixins/sortingMixin'

export default {
  name: 'OutgoingLinksList',
  mixins: [sortingMixin],
  components: {
    EmptyList,
    SortLabel
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    networkSiteDetermined: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('network', [
      'outgoingLinksList'
    ]),
    list() {
      return this.outgoingLinksList?.data
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.table-wrapper {
  max-height: calc(100vh - 325px) !important;
}

.url-cell {
  width: 40%;
  overflow: hidden;
  word-break: break-word;
}

</style>
