<template>
  <div v-loading="loading">
    <div ref="chart" class="chart" />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import * as echarts from 'echarts/core'
import {GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent} from 'echarts/components'
import {LineChart, BarChart} from 'echarts/charts'
import {CanvasRenderer} from 'echarts/renderers'

echarts.use(
  [
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    BarChart,
    CanvasRenderer,
  ],
)

export default {
  name: 'Chart',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    chartType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  computed: {
    ...mapState('leads', [
      'leadsChart',
    ]),
    chartData() {
      return this.leadsChart?.statistic
    },
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  watch: {
    leadsChart() {
      if (this.leadsChart) {
        this.setOptions()
      }
    },
    chartType() {
      this.setOptions()
    }
  },
  mounted() {
    this.chart = echarts.init(this.$refs.chart)
  },

  methods: {
    setOptions() {
      this.chart.setOption({
        color: ['#59bfef', '#ff2544', '#61a0a8', '#d48265'],
        legend: {
          data: ['Confirmed Leads', 'Missed Leads'],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: 'rgba(46,159,246,0.18)'
            }
          }
        },
        grid: {
          left: 15,
          right: 30,
          bottom: 0,
          top: 40,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: this.chartType !== 'line',
          data: this.chartData?.dates.map(x => x.split('-').join('\n')) || [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Confirmed Leads',
            data: this.chartData?.confirmed || [],
            type: this.chartType,
            barGap: 0,
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
          },
          {
            name: 'Missed Leads',
            data: this.chartData?.missed || [],
            type: this.chartType,
            barGap: 0,
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
          },
        ],
      })
    },
    onResize() {
      this.chart.resize()
    },
  },
}
</script>

<style lang="scss" scoped>

.chart {
  height: 300px;
  max-height: 300px;
  margin-left: -15px;
  margin-right: -15px;
}

</style>
