<template>
  <div class="geo-object-list">
    <div v-if="geoObjects && geoObjects.zipCodes.length">
      <div class="col-md-6">
        <div class="geo-objects-label">Zip codes</div>
        <div v-for="zip in geoObjects.zipCodes" :key="zip">
          {{ zip }}
        </div>
      </div>
      <div class="col-md-6">
        <div class="geo-objects-label">Cities</div>
        <div v-for="city in geoObjects.cities" :key="city">
          {{ city }}
        </div>
      </div>
    </div>
    <div v-else>
      <div class="geo-object-list__notification">
        <i class="el-icon-caret-left"></i>
        <el-alert
            title="There is no area yet."
            description="You can draw an area using polygon drawing tool."
            type="info"
            show-icon
            :closable="false"
        />
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'GeoObjectsList',
  props: {
    geoObjects: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

::v-deep .el-alert {
  padding-top: 15px;
  padding-bottom: 15px;
}

::v-deep .el-alert__title {
  font-size: 16px;
}

.geo-object-list {
  flex: 0 0 300px;
  height: calc(100vh - 200px);
  overflow-y: auto;
  padding-left: 15px;
}

.geo-objects-label {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  color: $color-primary;
}

.geo-object-list__notification {
  display: flex;
  align-items: center;

  i {
    font-size: 64px;
    margin-right: -15px;
    margin-left: -20px;
    color: $color-info;
  }
}

</style>
