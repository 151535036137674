<template>
  <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
  >

    <el-dialog
        :visible="visible"
        :before-close="handleClose"
        :title="isEdit ? 'Edit Client Site' : 'Create Client Site'"
        width="94%"
    >
      <el-form-item label="URL" prop="url">
        <el-input v-model="form.url"/>
      </el-form-item>

      <el-form-item label="CallRail Company ID" prop="callrail_campaign_identifier">
        <el-input v-model="form.callrail_campaign_identifier"/>
      </el-form-item>

      <el-form-item label="Campaign" prop="campaign_id">
        <el-select
            v-model="form.campaign_id"
            filterable
        >
          <el-option
              v-for="campaign in allCampaigns"
              :key="campaign.id"
              :label="campaign.name"
              :value="campaign.id"
          />
        </el-select>
      </el-form-item>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="onSubmit">Save</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import modalMixin from '@/mixins/modalMixin'

const defaultFormValues = {
  id: null,
  url: null,
  callrail_campaign_identifier: null,
  campaign_id: null
}

export default {
  name: 'AddEditSiteDialog',
  mixins: [modalMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: { ...defaultFormValues },
      originFormValues: { ...defaultFormValues },
      rules: {
        url: [
          { required: true, message: 'Please input URL', trigger: 'change' }
        ],
        callrail_campaign_identifier: [
          { required: true, message: 'Please input CallRail Company ID', trigger: 'change' }
        ],
        campaign_id: [
          { required: true, message: 'Please select Campaign', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapState('campaigns', [
      'allCampaigns'
    ]),
    ...mapState('sites', [
      'activeClientSite'
    ]),
    isEdit() {
      return !!(this.activeClientSite?.id)
    },
    isFormChanged() {
      return JSON.stringify(this.originFormValues) !== JSON.stringify(this.form)
    }
  },
  watch: {
    activeClientSite() {
      if (this.isEdit) {
        this.fillForm()
      }
    }
  },
  mounted() {
    this.fetchAllCampaigns()
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchAllCampaigns'
    ]),
    ...mapMutations('sites', [
      'setActiveClientSite'
    ]),
    ...mapActions('sites', [
      'createClientSite',
      'updateClientSite'
    ]),
    fillForm() {
      this.form = { ...this.activeClientSite }
      this.originFormValues = { ...this.form }
    },
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.isEdit) {
            await this.updateClientSite(this.form)
          } else {
            await this.createClientSite(this.form)
          }
          this.$emit('refetch-data')
          this.closeModal()
        }
      })
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal, this.isFormChanged)
    },
    closeModal() {
      this.resetForm()
      this.setActiveClientSite(null)
      this.$emit('close')
    },
    resetForm() {
      this.form = { ...defaultFormValues }
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 500px;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}
</style>
