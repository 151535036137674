<template>
  <el-form
      :model="filters"
      ref="form"
      label-position="top"
      class="filter-form"
  >
    <el-form-item label="Campaigns and Teams">
      <el-select
          v-model="filters.campaign_ids"
          multiple
          filterable
          class="multiselect"
          @change="doFilter"
      >
        <el-option
            v-for="campaign in campaignsWithTeams"
            :key="campaign.id"
            :label="campaign.name"
            :value="campaign.id"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Admin users">
      <el-select
          v-model="filters.user_ids"
          multiple
          filterable
          class="multiselect"
          @change="doFilter"
      >
        <el-option
            v-for="admin in adminsWithSuperAdmin"
            :key="admin.id"
            :label="admin.username"
            :value="admin.id"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Date Range">
      <el-date-picker
          v-model="filters.dateRange"
          :clearable="false"
          class="date-range-picker"
          end-placeholder="End date"
          format="MM/dd/yyyy"
          range-separator="To"
          start-placeholder="Start date"
          type="daterange"
          value-format="yyyy-MM-dd"
          @change="doFilter"
      />
    </el-form-item>

    <el-button
        v-if="isFiltersChanged"
        type="gray"
        @click="clearFilters"
    >
      Reset filters
    </el-button>
  </el-form>
</template>

<script>
import { mapState, mapActions } from 'vuex'

const defaultFilterValues = {
  user_ids: [],
  campaign_ids: [],
  dateRange: []
}

export default {
  name: 'ActivityLogFilters',
  data() {
    return {
      filters: {
        ...defaultFilterValues,
        dateRange: [
          this.defaultDataValues.startDate,
          this.defaultDataValues.endDate
        ]
      },
      originFilters: {
        ...defaultFilterValues,
        dateRange: [
          this.defaultDataValues.startDate,
          this.defaultDataValues.endDate
        ]
      }
    }
  },
  props: {
    defaultDataValues: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('campaigns', [
      'campaignsWithTeams'
    ]),
    ...mapState('admin', [
      'adminsWithSuperAdmin'
    ]),
    isFiltersChanged() {
      return JSON.stringify(this.originFilters) !== JSON.stringify(this.filters)
    }
  },
  mounted() {
    this.fetchCampaignsWithTeams()
    this.fetchAdminsWithSuperAdmin()
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchCampaignsWithTeams'
    ]),
    ...mapActions('admin', [
      'fetchAdminsWithSuperAdmin'
    ]),
    async doFilter() {
      this.$emit('filter', this.filters)
    },
    async clearFilters() {
      this.filters = {
        ...defaultFilterValues,
        dateRange: [
          this.defaultDataValues.startDate,
          this.defaultDataValues.endDate
        ]
      }
      this.$emit('filter', this.filters)
    }
  }
}
</script>

<style lang="scss" scoped>

.multiselect {
  min-width: 300px;
}

.date-range-picker {
  max-width: 350px;
}
</style>
