<template>
  <div class="file-uploader">
    <el-upload
        :action="`${baseApi}${action}`"
        :headers="headers"
        :on-success="handleSuccess"
        accept="image/jpeg, image/png"
        multiple
        drag
    >
      <div class="file-uploader__text">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drop file here or <em>click to upload</em><br>Allowed jpg/jpeg/png files</div>
      </div>
    </el-upload>

    <div
        :style="`background-image:url(${$_imageMixin_getImagePath(file.url)});`"
        v-for="file in files"
        :key="file.id + 'ww'"
        class="file-uploader__img"
    >
      <div class="file-uploader__img-actions">
        <div class="file-uploader__img-action" @click="zoomImage($_imageMixin_getImagePath(file.url))">
          <i class="el-icon-zoom-in"></i>
        </div>
        <div class="file-uploader__img-action file-uploader__img-action--delete" @click="handleDelete(file.id)">
          <i class="el-icon-delete"></i>
        </div>
      </div>
    </div>

    <div class="hidden">
      {{files}}
    </div>

    <el-dialog
        :visible.sync="imageZoomModalVisible"
        :before-close="zoomImageClose"
        append-to-body
    >
      <img :src="zoomedImg" class="file-uploader__image-zoom">
    </el-dialog>

  </div>
</template>

<script>
import imageMixin from '@/mixins/imageMixin'

export default {
  name: 'FileUploader',
  mixins: [imageMixin],
  props: {
    action: {
      type: String,
      required: true
    },
    files: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      headers: {
        'Authorization': `Bearer ${this.$cookies.get('token') }`
      },
      baseApi: process.env.VUE_APP_BASE_API_URI,
      imageZoomModalVisible: false,
      zoomedImg: null
    }
  },
  methods: {
    async handleDelete(fileId) {
      try {
        const res = await this.$confirm('Are you sure to remove this image?')
        if (res) {
          this.$emit('delete-file', fileId)
        }
      } catch {}
    },
    handleSuccess(file) {
      this.$emit('add-file', file)
    },
    zoomImage(img) {
      this.zoomedImg = img
      this.imageZoomModalVisible = true
    },
    zoomImageClose() {
      this.zoomedImg = null
      this.imageZoomModalVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.file-uploader {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%23BDBDBDFF' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='47' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 3px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 149px 5px 5px 5px;
}


::v-deep .el-upload {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
}

::v-deep .el-upload-dragger {
  width: 100%;
  height: auto;
  border: none;
  background: transparent;
}

::v-deep .el-upload__input {
  display: none;
}

::v-deep .el-upload-list {
  display: none;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__header {
  padding: 0;
}

.file-uploader__img {
  margin: 5px;
  height: 140px;
  width: 140px;
  background-position: center center;
  background-size: cover;
  border-radius: 5px;
  position: relative;
  z-index: 3;

  &:hover {
    .file-uploader__img-actions {
      opacity: 1;
    }
  }
}

.file-uploader__img-actions {
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: 0.2s;
}

.file-uploader__img-action {
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  color: #FFFFFF;

  &:hover {
    opacity: 0.5;
  }
}

.file-uploader__img-action--delete {
  color: $color-danger;
}

.file-uploader__text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 10px;
}

.el-upload-dragger .el-icon-upload {
  margin: 0 0 16px;
}

.file-uploader__image-zoom {
  display: block;
  width: 100%;
  height: auto;
}
</style>
