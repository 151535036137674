import { render, staticRenderFns } from "./SpammersDialog.vue?vue&type=template&id=b40f3416&scoped=true&"
import script from "./SpammersDialog.vue?vue&type=script&lang=js&"
export * from "./SpammersDialog.vue?vue&type=script&lang=js&"
import style0 from "./SpammersDialog.vue?vue&type=style&index=0&id=b40f3416&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b40f3416",
  null
  
)

export default component.exports