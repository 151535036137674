import api from '@/utils/api'
import { Message } from 'element-ui'

export default {
	state: () => ({
		activityLog: {},
		changelog: {},
		activeCampaign: null,
		activeChangelogItem: null,
		changelogGridParams: null
	}),
	mutations: {
		setActivityLog(state, payload) {
			state.activityLog = Object.freeze(payload)
		},

		setChangelog(state, payload) {
			state.changelog = Object.freeze(payload)
		},

		setActiveCampaign(state, payload) {
			state.activeCampaign = payload
		},

		setActiveChangelogItem(state, payload) {
			state.activeChangelogItem = payload
		},

		setChangelogGridParams(state, payload) {
			state.changelogGridParams = payload
		},
	},
	getters: {},
	actions: {
		async fetchActivityLog({ commit, state }, params) {
			if (params.cache && state.activityLog) { return }
			const result = await api.http.get('activity-log', { params })
			commit('setActivityLog', result?.data)
		},

		async toggleDoneStatus(_, item_id) {
			await api.http.post(`activity-log/${item_id}/toggle-status`)
		},

		async deleteActivityLogItem(_, item_id) {
			await api.http.delete(`campaign-changelog/${item_id}`)
			Message.success('Activity Log item was deleted successfully')
		},

		async createActivityLogItem(_, form) {
			await api.http.post('campaign-changelog', form)
			Message.success('Activity Log item was added successfully')
		},

		async updateActivityLogItem(_, form) {
			await api.http.put(`campaign-changelog/${form.id}`, form)
			Message.success('Activity Log item was updated successfully')
		},

		async fetchChangelog({ commit }, params) {
			const result = await api.http.get('campaign-changelog', { params })
			commit('setChangelog', result?.data)
		},
	},
	namespaced: true
}
