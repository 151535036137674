<template>
  <nav class="navbar navbar-default navbar-static-top">
    <div class="container-fluid">
      <div class="navbar-header">

        <!-- Collapsed Hamburger -->
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#app-navbar-collapse">
          <span class="sr-only">Toggle Navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>

        <!-- Branding Image -->
        <a class="navbar-brand" href="/">
        </a>
      </div>

      <div class="collapse navbar-collapse" id="app-navbar-collapse">
        <!-- Left Side Of Navbar -->
        <ul class="nav navbar-nav">
          &nbsp;
        </ul>

        <!-- Right Side Of Navbar -->
        <ul class="nav navbar-nav navbar-right">
          <li v-if="isGuest">
            <router-link :to="{name: 'Login'}">
              <i class="fas fa-sign-in-alt"/>Log in
            </router-link>
          </li>

          <li v-if="user" >
            <a href="#" @click.prevent="$emit('edit-profile')">
              <i class="fa fa-user-circle"/>{{ user.username }}
            </a>
          </li>

          <li class="">
            <a href="https://www.markmywordsmedia.com/lead-manager/" target="_blank">
              <i class="far fa-question-circle"/>
              User Guide
            </a>
          </li>

          <li>
            <a href="https://www.markmywordsmedia.com/resources/" target="_blank">
              <i class="fas fa-coins"/>
              Resources
            </a>
          </li>

          <li v-if="!isGuest">
            <a href="#" @click.prevent="logout">
              <i class="fas fa-sign-out-alt"/>
              Log out
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'TopNav',
  computed: {
    ...mapGetters('auth', [
        'isGuest'
    ]),
    ...mapState('auth', [
        'user'
    ])
  },
  methods: {
    ...mapActions('auth', [
        'logout'
    ]),
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.navbar-nav {
  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #eaeaea
  }

  i {
    font-size: 14px;
    margin-right: 6px;
    color: $color-primary;
  }
}

.icon-image {
  height: 20px;
  width: 20px;
  margin-right: 3px;
}

::v-deep .el-dialog {
  max-width: 1000px;
  z-index: 1000000000000;
}

</style>
