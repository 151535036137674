<template>
  <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
      @submit.native.prevent="onSubmit"
  >
    <el-form-item label="Username" prop="username">
      <el-input
          v-model="form.username"
          autocomplete="name"
      />
    </el-form-item>

    <el-form-item label="Password" prop="password">
      <el-input
          v-model="form.password"
          type="password"
          autocomplete="password"
      />
    </el-form-item>

    <el-alert
        v-if="errorMessage"
        class="alert"
        :title="errorMessage"
        type="error"
        :closable="false"
        show-icon>
    </el-alert>

    <div class="text-right">
      <el-button type="primary" native-type="submit">
        Login
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LoginForm',
  data() {
    return {
      errorMessage: null,
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: 'Please input username', trigger: 'change' }
        ],
        password: [
          { required: true, message: 'Please input password', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    ...mapActions('auth', [
      'login'
    ]),
    onSubmit() {
      this.errorMessage = null
      this.$refs.form.validate(async valid => {
        if (valid) {
          const res = await this.login(this.form)
          if (res?.error) {
            this.errorMessage = res?.error
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.alert {
  margin-bottom: 20px;
}

</style>
