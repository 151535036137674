import { render, staticRenderFns } from "./EditCampaignNoteDialog.vue?vue&type=template&id=5e5dae80&scoped=true&"
import script from "./EditCampaignNoteDialog.vue?vue&type=script&lang=js&"
export * from "./EditCampaignNoteDialog.vue?vue&type=script&lang=js&"
import style0 from "./EditCampaignNoteDialog.vue?vue&type=style&index=0&id=5e5dae80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e5dae80",
  null
  
)

export default component.exports