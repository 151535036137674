<template>
  <el-dialog
      :visible="visible"
      :before-close="handleClose"
      :title="title"
      width="94%"
      top="70px"
  >
    <ClientSiteLog/>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ClientSiteLog from '@/components/client-sites/ClientSiteLog'

export default {
  name: 'SiteLogsDialog',
  components: { ClientSiteLog },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('sites', [
      'activeClientSite'
    ]),
    title() {
      return `Status logs for ${this.activeClientSite?.url}`
    }
  },
  methods: {
    ...mapMutations('sites', [
      'setActiveClientSite',
      'setClientSiteLog'
    ]),
    async handleClose() {
      this.setActiveClientSite(null)
      this.setClientSiteLog(null)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 500px;
}

::v-deep .el-dialog__body {
  padding-top: 15px;
}

</style>
