<template>
  <el-form
      label-position="top"
      class="filter-form"
  >
    <el-form-item v-if="filters.host" label="Host">
      <el-input v-model="filters.host" v-debounce="doFilter" disabled/>
    </el-form-item>

    <el-form-item label="URL">
      <el-input v-model="filters.search" v-debounce="doFilter"/>
    </el-form-item>

    <el-form-item label="IP">
      <el-input v-model="filters.ip" v-debounce="doFilter"/>
    </el-form-item>

    <el-form-item label="Category">
      <el-select
          v-model="filters.category_id"
          filterable
          class="multiselect"
          @change="doFilter"
      >
        <el-option
            v-for="category in networkCategories && networkCategories.data"
            :key="category.id"
            :label="category.name"
            :value="category.id"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Status">
      <el-select
          v-model="filters.status"
          class="multiselect multiselect--status"
          @change="doFilter"
      >
        <el-option
            v-for="status in siteStatusList"
            :key="status.id"
            :label="status.label"
            :value="status.id"
        />
      </el-select>
    </el-form-item>

    <el-button
        v-if="isFiltersChanged"
        type="gray"
        @click="clearFilters"
    >
      Reset filters
    </el-button>

    <el-button
        type="success"
        @click="$emit('export')"
    >
      <i class="fas fa-cloud-download-alt"></i>
      Export
    </el-button>
  </el-form>
</template>

<script>
import { mapState, mapActions } from 'vuex'

const defaultValues = {
  search: '',
  category_id: null,
  ip: '',
  status: null
}

export default {
  name: 'NetworkSitesFilters',
  data() {
    return {
      originFilters: { ...defaultValues },
      filters: { ...defaultValues }
    }
  },
  computed: {
    ...mapState('network', [
      'networkCategories'
    ]),
    ...mapState('sites', [
      'siteStatusList'
    ]),
    isFiltersChanged() {
      return JSON.stringify(this.originFilters) !== JSON.stringify(this.filters)
    }
  },
  created() {
    this.fetchNetworkCategories()
    this.applyIncomingParams()
  },
  methods: {
    ...mapActions('network', [
      'fetchNetworkCategories'
    ]),
    async doFilter() {
      this.$emit('filter', this.filters)
    },
    clearFilters() {
      this.filters = { ...defaultValues }
      this.doFilter()
    },
    applyIncomingParams() {
      const params = this.$route?.params
      if (params?.ip) {
        this.filters.ip = params?.ip
        this.doFilter()
      }
      if (params?.category_id) {
        this.filters.category_id = params?.category_id
        this.doFilter()
      }
      if (params?.host) {
        this.filters.host = params?.host
        this.doFilter()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.multiselect {
  min-width: 180px;

  &.multiselect--status {
    width: 100px;
    min-width: 100px;
  }
}
</style>
