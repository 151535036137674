<template>
  <el-dialog
      v-if="visible"
      :visible="visible"
      :before-close="closeModal"
      fullscreen
      title="Edit Campaign Area"
  >
    <div ref="map" class="map"/>

    <GeoObjectsList :geoObjects="geoObjects"/>

    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal">Cancel</el-button>
      <el-button v-if="isGeoObjectsExist" type="danger" @click="onDelete">Remove</el-button>
      <el-button v-if="isGeoObjectsExist" type="accent" @click="onSubmit">Save</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import L, { latLng } from 'leaflet'
import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet/dist/leaflet.css'
import GeoObjectsList from '@/components/campaigns/GeoObjectsList'

export default {
  name: 'EditAreaDialog',
  components: { GeoObjectsList },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    activeCampaign: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      map: null,
      zoom: 5,
      center: latLng(38.51378825951165, -96.45996093749999),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      drawOptions: {
        polygon: {
          allowIntersection: false,
          drawError: {
            color: '#ff0000',
            message: '<strong>Oh snap!<strong> you can\'t draw that!'
          },
          shapeOptions: {
            color: this.activeCampaign?.categories[0].color
          }
        },
        polyline: false,
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false
      },
      geoObjects: null,
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      polygonObj: null,
      polygonCoords: null
    }
  },
  computed: {
    isGeoObjectsExist() {
      return !!(this.geoObjects && this.geoObjects.zipCodes.length)
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.initMap()
      this.setMapLayer()
      this.setDrawControl()
      await this.setPolygon()
      await this.loadPolygonObjects()
      this.setDrawWatcher()
    })
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchCampaignCategoryById',
      'fetchCampaignArea',
      'fetchCampaignAreaObjects',
      'createCampaignArea',
      'deleteCampaignArea'
    ]),

    initMap() {
      this.map = L.map(this.$refs.map).setView(this.center, this.zoom)
    },

    setMapLayer() {
      L.tileLayer(this.url, {
        attribution: this.attribution
      }).addTo(this.map)
    },

    setDrawControl() {
      const editableLayers = new L.FeatureGroup()
      this.map.addLayer(editableLayers)
      const drawPluginOptions = {
        position: 'topright',
        draw: this.drawOptions
      }
      const drawControl = new L.Control.Draw(drawPluginOptions)
      this.map.addControl(drawControl)
    },

    async setPolygon() {
      this.polygonCoords = await this.fetchCampaignArea(this.activeCampaign.id)
      if (this.polygonCoords.length < 1) return
      let preparedCoords = this.polygonCoords.map((item) => {
        return [item.lat, item.lng]
      })
      this.polygonObj = L.polygon(preparedCoords, { color: this.drawOptions.polygon.shapeOptions.color })
      this.makePolygonEditable()
      this.polygonObj.addTo(this.map)
      this.map.flyToBounds(this.polygonObj.getBounds())
    },

    async loadPolygonObjects() {
      this.geoObjects = await this.fetchCampaignAreaObjects({ points: this.polygonCoords })
    },

    setDrawWatcher() {
      this.map.on('draw:created', (e) => {
        if (this.polygonObj) {
          this.map.removeLayer(this.polygonObj)
        }

        this.map.addLayer(e.layer)
        this.polygonObj = e.layer
        this.makePolygonEditable()
        this.polygonCoords = []

        this.prepareCoordsToSave(e.layer.getLatLngs()[0])
      })
    },

    makePolygonEditable() {
      this.polygonObj.editing.enable()
      this.polygonObj.on('edit', (e) => {
        this.polygonObj = e.target
        this.polygonCoords = []
        this.prepareCoordsToSave(e.target.getLatLngs()[0])
      })
    },

    prepareCoordsToSave(arr) {
      for (let geoPoint of arr) {
        this.polygonCoords.push({
          lat: geoPoint.lat,
          lng: geoPoint.lng
        })
      }
      this.loadPolygonObjects()
    },

    async onSubmit() {
      await this.createCampaignArea({
        points: {
          points: this.polygonCoords
        },
        campaign_id: this.activeCampaign.id
      })
      this.closeModal()
    },

    async onDelete() {
      try {
        const res = await this.$confirm('Are you sure to remove campaign area?')
        if (res) {
          await this.deleteCampaignArea(this.activeCampaign.id)
          this.closeModal()
        }
      } catch {}
    },

    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

::v-deep .el-dialog__body {
  display: flex;
  margin-top: -20px;
}

::v-deep .el-dialog__footer {
  top: 20px;
  position: relative;
}

.map {
  flex-grow: 1;
  height: calc(100vh - 85px);
  margin-bottom: -100px;
}

</style>
