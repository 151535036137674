<template>
  <el-form
    ref="form"
    :model="filters"
    label-position="top"
  >
    <div class="filter-form">
      <div v-if="!isExtraFiltersHidden" class="collapse-button" @click="toggleFilters">
        <i v-if="!isExtraFilersCollapsed" class="fas fa-chevron-up" />
        <div>
          {{isExtraFilersCollapsed ? 'more' : 'less'}}
        </div>
        <div>
          filters
        </div>
        <i v-if="isExtraFilersCollapsed" class="fas fa-chevron-down" />
      </div>

      <el-form-item label="Date Range">
        <el-date-picker
          v-model="filters.dateRange"
          :clearable="false"
          class="date-range-picker"
          end-placeholder="End date"
          format="MM/dd/yyyy"
          range-separator="To"
          start-placeholder="Start date"
          type="daterange"
          value-format="yyyy-MM-dd"
          @change="doFilter"
        />
      </el-form-item>

      <el-form-item v-if="isAdminOrSuperAdmin" label="Category" prop="category_id">
        <el-select
          v-model="filters.category_id"
          class="multiselect"
          @change="doFilter"
        >
          <el-option
            v-for="category in campaignCategories"
            :key="category.id"
            :label="category.name"
            :value="category.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Campaigns">
        <el-select
          v-model="filters.campaign_ids"
          class="multiselect-big"
          filterable
          multiple
          @change="doFilter"
        >
          <el-option
            v-for="campaign in allCampaigns"
            :key="campaign.id"
            :label="campaign.name"
            :value="campaign.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Tags">
        <el-select
          v-model="filters.tags"
          class="multiselect"
          filterable
          multiple
          @change="doFilter"
        >
          <el-option
            v-for="tag in tagsList"
            :key="tag"
            :label="tag"
            :value="tag"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Search">
        <el-input
          v-model="filters.lead_search"
          v-debounce="doFilter"
          class="search-input"
        />
      </el-form-item>

      <el-button
        v-if="isFiltersChanged"
        type="gray"
        class="reset-button"
        @click="clearFilters"
      >
        Reset
      </el-button>
    </div>

    <div v-if="!isExtraFilersCollapsed" v-loading="loading" class="filters-wrapper">
      <div
        v-if="!isExtraFiltersHidden"
        class="radio-buttons-wrapper"
      >
        <el-radio-group
          v-model="filters.lead_type"
          size="mini"
          @change="doFilter"
        >
          <el-radio-button :label="null">All</el-radio-button>
          <el-radio-button
            v-for="filter in leadTypeFilter"
            :key="filter.key"
            :label="filter.key">
            {{ filter.label }}
            <div
              :class="{'badge--active': filter.badge}"
              class="badge"
            >
              {{ filter.badge }}
            </div>
          </el-radio-button>
        </el-radio-group>

        <el-radio-group
          v-model="filters.answering_status"
          size="mini"
          @change="doFilter"
        >
          <el-radio-button :label="null">All</el-radio-button>
          <el-radio-button
            v-for="filter in answeringStatusFilters"
            :key="filter.key"
            :label="filter.key">
            {{ filter.label }}
            <div
              :class="{'badge--active': filter.badge}"
              class="badge"
            >
              {{ filter.badge }}
            </div>
          </el-radio-button>
        </el-radio-group>

        <el-radio-group
          v-model="filters.client_progress"
          size="mini"
          @change="doFilter"
        >
          <el-radio-button :label="null">All</el-radio-button>
          <el-radio-button
            v-for="filter in clientProgressFilters"
            :key="filter.key"
            :label="filter.key">
            {{ filter.label }}
            <div
              :class="{'badge--active': filter.badge}"
              class="badge"
            >
              {{ filter.badge }}
            </div>
          </el-radio-button>
        </el-radio-group>

        <el-radio-group
          v-if="isAdminOrSuperAdmin"
          v-model="filters.source"
          size="mini"
          @change="doFilter"
        >
          <el-radio-button :label="null">All</el-radio-button>
          <el-radio-button
            v-for="filter in sourceFilters"
            :key="filter.key"
            :label="filter.key">
            {{ filter.label }}
            <div
              :class="{'badge--active': filter.badge}"
              class="badge"
            >
              {{ filter.badge }}
            </div>
          </el-radio-button>
        </el-radio-group>
      </div>

      <div v-if="!isExtraFiltersHidden && !isExtraFilersCollapsed" class="radio-buttons-wrapper">
        <el-checkbox
          v-for="filter in singleFilters"
          :key="filter.key"
          v-model="filters[filter.key]"
          :false-label="null"
          :true-label="1"
          border
          size="mini"
          @change="doFilter"
        >
          {{ filter.label }}
          <div
            :class="{'badge--active': filter.badge}"
            class="badge"
          >
            {{ filter.badge }}
          </div>
        </el-checkbox>
      </div>
    </div>

  </el-form>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'

const defaultFilterValues = {
  campaign_ids: [],
  dateRange: [],
  category_id: null,
  tags: [],
  lead_search: '',
  lead_type: null,
  answering_status: null,
  client_progress: null,
  source: null,
  confirmed: null,
  unmarked: null,
  first_call: null,
  voicemail: null,
  voicemail_issues: null,
  client_value: null,
  client_profit: null,
  won_client_value: null,
  won_client_profit: null,
  rating: null,
  no_feedback: null,
  not_rated: null,
}

export default {
  name: 'LeadsFilters',
  data() {
    return {
      filters: {
        ...defaultFilterValues,
        dateRange: [
          this.defaultDataValues.startDate,
          this.defaultDataValues.endDate,
        ],
      },
      originFilters: {
        ...defaultFilterValues,
        dateRange: [
          this.defaultDataValues.startDate,
          this.defaultDataValues.endDate,
        ],
      },
    }
  },
  props: {
    defaultDataValues: {
      type: Object,
      required: true,
    },
    isExtraFiltersHidden: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('auth', [
      'isAdminOrSuperAdmin',
    ]),
    ...mapState('campaigns', [
      'campaignCategories',
      'allCampaigns',
    ]),
    ...mapState('leads', [
      'tagsList',
      'leadsStatistics',
      'isExtraFilersCollapsed'
    ]),
    isFiltersChanged() {
      return JSON.stringify(this.originFilters) !== JSON.stringify(this.filters)
    },
    leadTypeFilter() {
      return [
        {
          key: 'Form',
          label: 'Form',
          badge: this.leadsStatistics?.countByType?.Form ?? 0,
        },
        {
          key: 'Call',
          label: 'Call',
          badge: this.leadsStatistics?.countByType?.Call ?? 0,
        },
      ]
    },
    answeringStatusFilters() {
      return [
        {
          key: 'Abandoned',
          label: 'Abandoned',
          badge: this.leadsStatistics?.countByAnsweringStatus?.Abandoned ?? 0,
        },
        {
          key: 'Answered',
          label: 'Answered',
          badge: this.leadsStatistics?.countByAnsweringStatus?.Answered ?? 0,
        },
        {
          key: 'Unanswered',
          label: 'Unanswered',
          badge: this.leadsStatistics?.countByAnsweringStatus?.Unanswered ?? 0,
        },
        {
          key: 'missed',
          label: 'Missed',
          badge: this.leadsStatistics?.countByAnsweringStatus?.missed ?? 0,
        },
      ]
    },
    clientProgressFilters() {
      return [
        {
          key: 1,
          label: 'Won',
          badge: this.leadsStatistics?.countByClientProgress && this.leadsStatistics?.countByClientProgress['1'] || 0,
        },
        {
          key: 2,
          label: 'Progress',
          badge: this.leadsStatistics?.countByClientProgress && this.leadsStatistics?.countByClientProgress['2'] || 0,
        },
        {
          key: 3,
          label: 'Lost',
          badge: this.leadsStatistics?.countByClientProgress && this.leadsStatistics?.countByClientProgress['3'] || 0,
        },
        {
          key: 4,
          label: 'Passed',
          badge: this.leadsStatistics?.countByClientProgress && this.leadsStatistics?.countByClientProgress['4'] || 0,
        },
        {
          key: 5,
          label: 'Not Lead',
          badge: this.leadsStatistics?.countByClientProgress && this.leadsStatistics?.countByClientProgress['5'] || 0,
        },
      ]
    },
    sourceFilters() {
      return [
        {
          key: 'google_organic',
          label: 'Google Organic',
          badge: this.leadsStatistics?.countBySource?.google_organic ?? 0,
        },
        {
          key: 'google_paid',
          label: 'Google Paid',
          badge: this.leadsStatistics?.countBySource?.google_paid ?? 0,
        },
        {
          key: 'google_maps',
          label: 'Google Maps',
          badge: this.leadsStatistics?.countBySource?.google_maps ?? 0,
        },
        {
          key: 'facebook',
          label: 'Facebook',
          badge: this.leadsStatistics?.countBySource?.facebook ?? 0,
        },
        {
          key: 'instagram',
          label: 'Instagram',
          badge: this.leadsStatistics?.countBySource?.instagram ?? 0,
        },

      ]
    },
    singleFilters() {
      return [
        {
          key: 'confirmed',
          label: 'Verified',
          badge: this.leadsStatistics?.confirmedCount ?? 0,
        },
        {
          key: 'unmarked',
          label: 'Unmarked',
          badge: this.leadsStatistics?.unmarkedCount ?? 0,
        },
        {
          key: 'first_call',
          label: 'First Call',
          badge: this.leadsStatistics?.firstCallCount ?? 0,
        },
        {
          key: 'voicemail',
          label: 'Voicemail',
          badge: this.leadsStatistics?.voiceMailCount ?? 0,
        },
        {
          key: 'voicemail_issues',
          label: 'Voicemail Issues',
          badge: this.leadsStatistics?.voiceMailIssuesCount ?? 0,
        },
        {
          key: 'client_value',
          label: 'Value',
          badge: this.round(this.leadsStatistics?.totalValue),
        },
        {
          key: 'client_profit',
          label: 'Profit',
          badge: this.round(this.leadsStatistics?.totalProfit),
        },
        {
          key: 'won_client_value',
          label: 'Won Value',
          badge: this.round(this.leadsStatistics?.totalWonValue),
        },
        {
          key: 'won_client_profit',
          label: 'Won Profit',
          badge: this.round(this.leadsStatistics?.totalWonProfit),
        },
        {
          key: 'rating',
          label: 'Rating',
          badge: this.leadsStatistics?.averageRating ?? 0,
        },
        {
          key: 'no_feedback',
          label: 'No feedback',
          badge: this.leadsStatistics?.noFeedbackCount ?? 0,
        },
        {
          key: 'not_rated',
          label: 'Not rated',
          badge: this.leadsStatistics?.notRatedCount ?? 0,
        },
      ]
    },
  },
  mounted() {
    this.fetchAllCampaigns()
    this.fetchCampaignCategories()
  },
  methods: {
    ...mapMutations('leads', [
      'setExtraFiltersVisibility'
    ]),
    ...mapActions('campaigns', [
      'fetchAllCampaigns',
      'fetchCampaignCategories',
    ]),
    async doFilter() {
      this.$emit('filter', this.filters)
    },
    async clearFilters() {
      this.filters = {
        ...defaultFilterValues,
        dateRange: [
          this.defaultDataValues.startDate,
          this.defaultDataValues.endDate,
        ],
      }
      this.$emit('filter', this.filters)
    },
    round(num) {
      if (!num) return 0
      return (Math.round(num * 100) / 100).toFixed(2)
    },
    triggerFilter(data) {
      this.filters = {...this.filters, ...data}
      this.doFilter()
    },
    toggleFilters() {
      this.setExtraFiltersVisibility(!this.isExtraFilersCollapsed)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.filter-form {
  margin-bottom: 1px;
}

::v-deep .date-range-picker {
  width: 230px !important;
  font-size: 12px;

  .el-icon-date {
    margin-right: 10px;
  }

  .el-range__close-icon {
    display: none;
  }
}

.multiselect-big {
  min-width: 260px;
}

.multiselect {
  width: 150px;
}

.search-input {
  width: 150px;
}

.radio-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -7px;
  margin-right: -7px;

  > * {
    margin-right: 7px !important;
    margin-left: 7px !important;
  }

  &:last-of-type {
    margin-top: 5px;
  }
}

::v-deep .el-radio-button {
  &:active, &:focus {
    box-shadow: none !important;
  }
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: $color-success;
  border-color: $color-success;
}

::v-deep .el-radio-button__inner {
  .badge {
    margin-right: -8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 5px;
    padding-left: 7px;
  }
}

::v-deep .el-checkbox__label {
  .badge {
    margin-right: -5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 5px;
    padding-left: 7px;
  }
}

.filters-wrapper {
  position: relative;

  > * {
    margin-top: 8px;
  }
}

.el-form-item {
  margin-bottom: 1px;
}

.reset-button {
  margin-bottom: 1px;
}

.el-radio-group {
  margin-bottom: 5px;
}

.collapse-button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  margin: 0;
  font-size: 15px;
  color: $color-accent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  cursor: pointer;

  &:hover {
    color: #666666;
  }

  i {
    font-size: 24px;
  }
}

</style>
