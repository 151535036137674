import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import store from './store'
import Vuex from 'vuex'
import ElementUI from 'element-ui'
import ElementUILocale from 'element-ui/lib/locale/lang/en'
import './assets/css/_element-variables.scss'
import VueCurrencyInput from 'vue-currency-input'
import vueDebounce from 'vue-debounce'
import VueClipboard from 'vue-clipboard2'
import VueMeta from 'vue-meta'
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"
import router from './router'

Vue.use(VueRouter)
if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    autoSessionTracking: false,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["netmanager.markmywordsmedia.com", "netmanager.clockwisesoftware.com"]
      })
    ],
    beforeSend: (event, hint) => {
      if (process.env.VUE_APP_ENV === 'local') {
        console.error(hint.originalException || hint.syntheticException)
        return null
      }
      return event
    },
    tracesSampleRate: 1.0
  });
}

Vue.use(VueCookies)
Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(ElementUI, { locale: ElementUILocale })
Vue.use(VueCurrencyInput, {
  globalOptions: { currency: 'USD', locale: 'en-US' }
})
Vue.use(vueDebounce, {
  listenTo: 'input'
})
Vue.use(VueMeta)
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

