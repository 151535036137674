<template>
  <div>
    <div class="mb-2">
      <el-checkbox
          v-model="allChecked"
          :indeterminate="isIndeterminate"
          @change="handleCheckAll"
      >
        Check all
      </el-checkbox>
    </div>

    <div class="checkbox-list mb-1">
      <el-checkbox-group
          v-model="checkedPages"
          @change="handleCheck"
      >
        <el-checkbox
            v-for="page in pagesList"
            :label="page.id"
            :key="page.id"
        >
          {{ page.title }}
        </el-checkbox>
      </el-checkbox-group>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'PagesAccess',
  props: {
    checkedPagesProp: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      checkedPages: []
    }
  },
  computed: {
    ...mapState('admin', {
      pagesList: state => state.pagesList
    }),
    isIndeterminate() {
      if (this.pagesList) {
        return this.checkedPages.length > 0 && this.checkedPages.length < this.pagesList.length
      }
      return false
    },
    allChecked: {
      get() {
        if (this.pagesList) {
          return this.checkedPages.length === this.pagesList.length
        }
        return false
      },
      set(newValue) {
        return newValue
      }
    },
    transformedPagesArray() {
      const obj = {}
      this.checkedPages.forEach(item => {
        obj[item] = 1
      })
      return obj
    }
  },
  mounted() {
    this.fetchPagesList()
  },
  watch: {
    checkedPagesProp() {
      this.checkedPages = []
      for (let key in this.checkedPagesProp) {
        if (this.checkedPagesProp[key] === 1) {
          this.checkedPages.push(parseInt(key))
        }
      }
    }
  },
  methods: {
    ...mapActions('admin', [
      'fetchPagesList'
    ]),
    handleCheckAll(val) {
      this.checkedPages = val ? this.pagesList.map(i => i.id) : []
      this.$emit('change', this.transformedPagesArray)
    },
    handleCheck() {
      this.$emit('change', this.transformedPagesArray)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
