<template>
  <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
  >
    <el-dialog
        :visible="visible"
        :before-close="handleClose"
        :title="'Edit Location'"
        width="94%"
    >
      <el-form-item label="Title" prop="title">
        <el-input v-model="form.title"/>
      </el-form-item>

      <div class="row">
        <div class="col-sm-8">
          <el-form-item label="Address" prop="address">
            <el-input v-model="form.address"/>
          </el-form-item>
        </div>
        <div class="col-sm-4">
          <el-form-item label="Phone" prop="phone">
            <el-input v-model="form.phone"/>
          </el-form-item>
        </div>
      </div>

      <el-form-item label="Website" prop="website">
        <el-input v-model="form.website"/>
      </el-form-item>

      <div class="row">
        <div class="col-sm-4">
          <el-form-item label="Primary Category" prop="primary_category">
            <el-select
                v-model="primary_category_id"
                filterable
            >
              <el-option
                  v-for="category in GMBCategories"
                  :key="category.name"
                  :label="category.displayName"
                  :value="category.name"
              />
            </el-select>
          </el-form-item>
        </div>
        <div class="col-sm-8">
          <el-form-item label="Additional Categories" prop="categories">
            <el-select
                v-model="categories_ids"
                multiple
                filterable
            >
              <el-option
                  v-for="category in GMBCategories"
                  :key="category.name"
                  :label="category.displayName"
                  :value="category.name"
              />
            </el-select>
          </el-form-item>
        </div>
      </div>

      <el-form-item label="Description" prop="description">
        <el-input
            v-model="form.description"
            type="textarea"
            :rows="6"
        />
      </el-form-item>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="onSubmit">Save</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import modalMixin from '@/mixins/modalMixin'

const defaultFormValues = {}

export default {
  name: 'EditLocationDialog',
  mixins: [modalMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: { ...defaultFormValues },
      originFormValues: { ...defaultFormValues },
      rules: {},
      primary_category_id: null,
      categories_ids: [],
    }
  },
  computed: {
    ...mapState('gmbs', [
      'activeGMBLocation',
      'GMBCategories'
    ]),
    isFormChanged() {
      return JSON.stringify(this.originFormValues) !== JSON.stringify(this.form)
    }
  },
  watch: {
    activeGMBLocation() {
      if (this.activeGMBLocation?.id) {
        this.fetchGmbCategories(this.activeGMBLocation?.id)
        this.fillForm()
      }
    }
  },
  methods: {
    ...mapMutations('gmbs', [
      'setActiveGMBLocation'
    ]),
    ...mapActions('gmbs', [
      'updateGMBLocation',
      'fetchGmbCategories'
    ]),
    fillForm() {
      this.form = { ...this.activeGMBLocation }
      this.primary_category_id = this.activeGMBLocation?.primary_category?.name
      this.categories_ids = this.activeGMBLocation?.categories.map(i => i.name)
      this.originFormValues = { ...this.form }
    },
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.form.primary_category = this.GMBCategories.find(x=>x.name === this.primary_category_id)
          this.form.categories = this.categories_ids.map(catId=> {
            return this.GMBCategories.find(x=>x.name === catId)
          })
          await this.updateGMBLocation(this.form)
          this.$emit('refetch-data')
          this.closeModal()
        }
      })
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal, this.isFormChanged)
    },
    closeModal() {
      this.resetForm()
      this.setActiveGMBLocation(null)
      this.$emit('close')
    },
    resetForm() {
      this.form = { ...defaultFormValues }
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 900px;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}
</style>
