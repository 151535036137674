<template>
  <div>
    <div class="page-title-group">
      <h1>Network Sites</h1>

      <el-button
          type="accent"
          icon="el-icon-plus"
          @click="openAddEditDialog"
      >
        Create New Site
      </el-button>
    </div>

    <FilterPanel>
      <NetworkSitesFilters @filter="doFilter" @export="doExport"/>
    </FilterPanel>

    <MainPanel>
      <div class="action-bar">
        <PageLimitSwitcher :active="filterObject.per_page" @click="setPageLimit"/>

        <el-pagination
            v-if="networkSites"
            background
            layout="prev, pager, next"
            :page-size="filterObject.per_page"
            :current-page="filterObject.page"
            :total="networkSites.total"
            hide-on-single-page
            @current-change="changePage"
        />

        <div class="table-total">
          Total Items: {{ networkSites && networkSites.total || '-' }}
        </div>
      </div>

      <NetworkSitesList
          :loading="loading"
          @sort="doSort"
          @edit="openAddEditDialog"
          @show-logs="openLogsDialog"
          @show-links="openLinksDialog"
          @refetch-data="getNetworkSites"
      />
    </MainPanel>

    <AddEditSiteDialog
        :visible.sync="addEditDialogVisible"
        @close="closeAddEditDialog"
        @refetch-data="getNetworkSites"
    />

    <SiteLogsDialog
        :visible.sync="logsDialogVisible"
        @close="closeLogsDialog"
    />

    <LinksDialog
        :visible.sync="linksDialogVisible"
        @close="closeLinksDialog"
    />

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PageLimitSwitcher from '@/components/global/PageLimitSwitcher'
import NetworkSitesList from '@/components/network-sites/NetworkSitesList'
import NetworkSitesFilters from '@/components/network-sites/NetworkSitesFilters'
import AddEditSiteDialog from '@/components/network-sites/AddEditSiteDialog'
import SiteLogsDialog from '@/components/network-sites/SiteLogsDialog'
import LinksDialog from '@/components/network-sites/LinksDialog'
import FilterPanel from '@/components/global/FilterPanel'
import MainPanel from '@/components/global/MainPanel'

export default {
  name: 'NetworkSitesPage',
  metaInfo: {
    title: 'Network Sites'
  },
  components: {
    MainPanel,
    FilterPanel,
    LinksDialog,
    SiteLogsDialog,
    AddEditSiteDialog,
    NetworkSitesFilters,
    NetworkSitesList,
    PageLimitSwitcher
  },
  data() {
    return {
      loading: false,
      addEditDialogVisible: false,
      logsDialogVisible: false,
      linksDialogVisible: false,
      filterObject: {
        page: 1,
        per_page: 200
      }
    }
  },
  computed: {
    ...mapState('sites', [
      'networkSites'
    ])
  },
  mounted() {
    this.getNetworkSites()
  },
  methods: {
    ...mapActions('sites', [
      'exportNetworkSites',
      'fetchNetworkSites'
    ]),
    async getNetworkSites() {
      this.loading = true
      await this.fetchNetworkSites(this.filterObject)
      this.loading = false
    },
    doFilter(filters) {
      this.filterObject = { ...this.filterObject, ...filters }
      this.getNetworkSites()
    },
    doSort(sort) {
      this.filterObject = { ...this.filterObject, ...sort }
      this.filterObject.page = 1
      this.getNetworkSites()
    },
    doExport() {
      if (!this.filterObject.ip) {
        this.filterObject.ip = null
      }
      this.exportNetworkSites(this.filterObject)
    },
    setPageLimit(val) {
      this.filterObject.per_page = val
      this.filterObject.page = 1
      this.getNetworkSites()
    },
    changePage(page) {
      this.filterObject.page = page
      this.getNetworkSites()
    },
    openAddEditDialog() {
      this.addEditDialogVisible = true
    },
    closeAddEditDialog() {
      this.addEditDialogVisible = false
    },
    openLogsDialog() {
      this.logsDialogVisible = true
    },
    closeLogsDialog() {
      this.logsDialogVisible = false
    },
    openLinksDialog() {
      this.linksDialogVisible = true
    },
    closeLinksDialog() {
      this.linksDialogVisible = false
    }
  }
}
</script>
