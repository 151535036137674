<template>
  <div>
    <div class="page-title-group">
      <h1>301 domains List</h1>

      <el-button
          type="accent"
          icon="el-icon-plus"
          @click="openAddRedirectDialog"
      >
        Add 301 domain
      </el-button>
    </div>

    <FilterPanel>
      <SearchFilter @filter="doFilter"/>
    </FilterPanel>

    <MainPanel>
      <div class="action-bar">
        <PageLimitSwitcher :active="filterObject.per_page" @click="setPageLimit"/>

        <el-pagination
            v-if="redirectDomains"
            background
            layout="prev, pager, next"
            :page-size="filterObject.per_page"
            :current-page="filterObject.page"
            :total="redirectDomains.total"
            hide-on-single-page
            @current-change="changePage"
        />

        <div class="table-total">
          Total Items: {{ redirectDomains && redirectDomains.total }}
        </div>
      </div>

      <RedirectDomainsList
          :loading="loading"
          @sort="doSort"
          @refetch-data="getRedirectDomains"
      />
    </MainPanel>

    <AddRedirectDialog
      :visible.sync="addRedirectDialogVisible"
      @close="closeAddRedirectDialog"
      @refetch-data="getRedirectDomains()"
    />

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PageLimitSwitcher from '@/components/global/PageLimitSwitcher'
import RedirectDomainsList from '@/components/redirect-domains/RedirectDomainsList'
import SearchFilter from '@/components/redirect-domains/SearchFilter'
import FilterPanel from '@/components/global/FilterPanel'
import MainPanel from '@/components/global/MainPanel'
import AddRedirectDialog from '@/components/redirect-domains/AddRedirectDialog'

export default {
  name: 'RedirectDomainPage',
  metaInfo: {
    title: '301 domains List'
  },
  components: {
    AddRedirectDialog,
    MainPanel,
    FilterPanel,
    SearchFilter,
    RedirectDomainsList,
    PageLimitSwitcher
  },
  data() {
    return {
      loading: false,
      addRedirectDialogVisible: false,
      filterObject: {
        page: 1,
        per_page: 200
      }
    }
  },
  computed: {
    ...mapState('network', [
      'redirectDomains'
    ]),
  },
  mounted() {
    this.getRedirectDomains()
  },
  methods: {
    ...mapActions('network', [
      'fetchRedirectDomains'
    ]),
    async getRedirectDomains() {
      this.loading = true
      await this.fetchRedirectDomains(this.filterObject)
      this.loading = false
    },
    doFilter(filters) {
      this.filterObject = { ...this.filterObject, ...filters }
      this.getRedirectDomains()
    },
    doSort(sort) {
      this.filterObject = { ...this.filterObject, ...sort }
      this.filterObject.page = 1
      this.getRedirectDomains()
    },
    setPageLimit(val) {
      this.filterObject.per_page = val
      this.filterObject.page = 1
      this.getRedirectDomains()
    },
    changePage(page) {
      this.filterObject.page = page
      this.getRedirectDomains()
    },
    openAddRedirectDialog() {
      this.addRedirectDialogVisible = true
    },
    closeAddRedirectDialog() {
      this.addRedirectDialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
