<template>
  <div>
    <div class="page-title-group">
      <h1>Changelog</h1>

      <el-button
          type="accent"
          icon="el-icon-plus"
          @click="openAddEditDialog"
      >
        Create New Item
      </el-button>
    </div>

    <FilterPanel>
      <ActivityLogFilters
          :defaultDataValues="defaultDataValues"
          @filter="doFilter"
      />
    </FilterPanel>

    <MainPanel>
      <ChangelogTable
          :loading="loading"
          @edit-campaign-note="openEditCampaignNoteDialog"
          @add-note="openAddEditDialog"
          @edit-note="openAddEditDialog"

      />
    </MainPanel>

    <AddEditItemDialog
        :visible.sync="addEditDialogVisible"
        @close="closeAddEditDialog"
        @refetch-data="getChangelog"
    />

    <EditCampaignNoteDialog
        :visible.sync="editCampaignNoteDialogVisible"
        @close="closeEditCampaignNoteDialog"
    />

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ActivityLogFilters from '@/components/activity-log/ActivityLogFilters'
import { sub, format } from 'date-fns'
import AddEditItemDialog from '@/components/activity-log/AddEditItemDialog'
import FilterPanel from '@/components/global/FilterPanel'
import MainPanel from '@/components/global/MainPanel'
import ChangelogTable from '@/components/changelog/ChangelogTable'
import EditCampaignNoteDialog from '@/components/changelog/EditCampaignNoteDialog'

const defaultDataValues = {
  startDate: format(sub(Date.now(), {months: 1}), 'yyyy-MM-dd'),
  endDate: format(Date.now(), 'yyyy-MM-dd'),
}

export default {
  name: 'ChangelogPage',
  metaInfo: {
    title: 'Changelog'
  },
  components: {
    EditCampaignNoteDialog,
    ChangelogTable,
    MainPanel,
    FilterPanel,
    AddEditItemDialog,
    ActivityLogFilters
  },
  data() {
    return {
      loading: false,
      addEditDialogVisible: false,
      editCampaignNoteDialogVisible: false,
      defaultDataValues,
      filterObject: {
        from: defaultDataValues.startDate,
        to: defaultDataValues.endDate,
        user_ids: null,
        campaign_ids: null,
      }
    }
  },
  mounted() {
    this.getChangelog()
  },
  methods: {
    ...mapActions('activity-log', [
      'fetchChangelog'
    ]),
    ...mapActions('campaigns', [
      'fetchCampaignsWithTeams'
    ]),
    openAddEditDialog() {
      this.addEditDialogVisible = true
    },
    closeAddEditDialog() {
      this.addEditDialogVisible = false
    },
    openEditCampaignNoteDialog() {
      this.editCampaignNoteDialogVisible = true
    },
    closeEditCampaignNoteDialog() {
      this.editCampaignNoteDialogVisible = false
    },
    editItem() {
      this.openAddEditDialog()
    },
    async getChangelog() {
      this.loading = true
      await this.fetchChangelog(this.filterObject)
      this.loading = false
    },
    doFilter(filters) {
      this.filterObject = {...this.filterObject, ...filters}
      this.filterObject.from = filters.dateRange[0]
      this.filterObject.to = filters.dateRange[1]
      this.filterObject.user_ids = filters.user_ids
      this.filterObject.campaign_ids = filters.campaign_ids
      this.getChangelog()
    }
  }
}
</script>
