<template>
  <div class="empty-list">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'EmptyList',
  props: {
    text: {
      type: String,
      default: 'List is empty'
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-list {
  min-height: 100px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #cccccc;
  font-weight: bold;
}
</style>
