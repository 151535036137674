<template>
  <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
  >
    <el-dialog
        :visible="visible"
        :before-close="handleClose"
        title="Edit Profile"
        width="94%"
        @open="modalOpened"
    >
      <div class="row">
        <div class="col-sm-6">
          <el-form-item label="Username">
            <el-input :value="user && user.username" disabled/>
          </el-form-item>
        </div>
        <div class="col-sm-6">
          <el-form-item label="Email" prop="email">
            <el-input :value="user && user.email" disabled/>
          </el-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <el-form-item label="Old password" prop="old_password">
            <el-input v-model="form.old_password" type="password"/>
          </el-form-item>
        </div>
        <div class="col-sm-4">
          <el-form-item label="New password" prop="new_password">
            <el-input v-model="form.new_password" type="password"/>
          </el-form-item>
        </div>
        <div class="col-sm-4">
          <el-form-item label="New password confirmation" prop="new_password_confirmation">
            <el-input v-model="form.new_password_confirmation" type="password"/>
          </el-form-item>
        </div>
      </div>


      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="onSubmit">Save</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import { mapActions } from 'vuex'
import modalMixin from '@/mixins/modalMixin'

const defaultFormValues = {
  old_password: null,
  new_password: null,
  new_password_confirmation: null
}

export default {
  name: 'ProfileEditDialog',
  mixins: [modalMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (this.form.new_password_confirmation !== '') {
        this.$refs.form.validateField('new_password_confirmation')
      }
      callback()
    }
    const validatePasswordConfirmation = (rule, value, callback) => {
      if (value !== this.form.new_password) {
        callback(new Error('Passwords don\'t match!'))
      } else {
        callback()
      }
    }

    return {
      user: null,
      form: { ...defaultFormValues },
      originFormValues: { ...defaultFormValues },
      rules: {
        old_password: [
          { required: true, message: 'Please input old password', trigger: 'change' }
        ],
        new_password: [
          { required: true, message: 'Please input new password', trigger: 'change' },
          { validator: validatePassword, trigger: 'blur' }
        ],
        new_password_confirmation: [
          { required: true, message: 'Please input new password confirmation', trigger: 'change' },
          { validator: validatePasswordConfirmation, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    isFormChanged() {
      return JSON.stringify(this.originFormValues) !== JSON.stringify(this.form)
    }
  },
  async created() {
    this.user = await this.getUser()
    this.fillForm()
  },
  methods: {
    ...mapActions('auth', [
      'getUser',
      'updateProfile'
    ]),
    fillForm() {
      this.form = { ...this.user }
      this.originFormValues = { ...this.form }
    },
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          await this.updateProfile(this.form)
          await this.getUser()
          this.closeModal()
        }
      })
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal, this.isFormChanged)
    },
    closeModal() {
      this.resetForm()
      this.$emit('close')
    },
    resetForm() {
      this.form = { ...defaultFormValues }
      this.$refs.form.resetFields()
    },
    modalOpened() {
      this.fillForm()
    },
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 700px;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}
</style>
