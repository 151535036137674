<template>
  <el-dialog
      :visible="visible"
      :before-close="handleClose"
      :title="`Status logs for: ${activeGmb && activeGmb.title}`"
      width="94%"
      top="70px"
  >
    <GmbLog/>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import GmbLog from '@/components/gmbs/GmbLog'

export default {
  name: 'GmbLogsDialog',
  components: { GmbLog },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('gmbs', [
      'activeGmb'
    ])
  },
  methods: {
    ...mapMutations('gmbs', [
      'setActiveGmb',
      'setGmbLog'
    ]),
    async handleClose() {
      this.setActiveGmb(null)
      this.setGmbLog([])
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 500px;
}

::v-deep .el-dialog__body {
  padding-top: 15px;
}

</style>
