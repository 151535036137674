<template>
  <div v-if="lead">
    <div v-if="lead.message && lead.message.length > messageMaxLength">
      <div v-if="messageCollapsed">
        <nl2br tag="p" :text="croppedMessage"/>
        <div
            class="read-more-link"
            @click="messageCollapsed = !messageCollapsed"
        >
          Read More
        </div>
      </div>
      <div v-else>
        <nl2br tag="p" :text="lead.message"/>
        <div
            class="read-more-link"
            @click="messageCollapsed = !messageCollapsed"
        >
          Read Less
        </div>
      </div>
    </div>
    <div v-else>
      {{ lead.message }}
    </div>
  </div>
</template>

<script>
import Nl2br from 'vue-nl2br'

export default {
  name: 'LeadMessage',
  components: {
    Nl2br
  },
  props: {
    lead: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      messageMaxLength: 100,
      messageCollapsed: true
    }
  },
  computed: {
    croppedMessage() {
      if (!this.lead.message) return
      return this.lead.message.substring(0, this.messageMaxLength) + '...'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.read-more-link {
  color: $color-info;
  cursor: pointer;
  margin-top: 5px;

  &:hover {
    text-decoration: underline;
  }
}

</style>
