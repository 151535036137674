import api from '@/utils/api'
import { Message } from 'element-ui'
import fileSaver from '@/utils/fileSaver'

export default {
  state: () => ({
    leads: null,
    leadsStatistics: null,
    leadsForTable: null,
    globalRelatedLeads: null,
    relatedLeads: null,
    activeLead: null,
    blacklist: null,
    leadsChart: null,
    spammers: [],
    isExtraFilersCollapsed: false,
    tagsList: [
      'Spam',
      'Duplicate',
      'Other',
      'Missed Call',
      'Voicemail',
      'Voicemail Issues',
      'Connection Issues',
      'Low Value',
      'Autospam',
      'BLSpam',
      'Auto Missed Call',
      'After Hours'
    ],
    clientProgressList: [
      {
        key: 1,
        label: 'Won',
      },
      {
        key: 2,
        label: 'In Progress',
      },
      {
        key: 3,
        label: 'Lost',
      },
      {
        key: 4,
        label: 'Passed',
      },
      {
        key: 5,
        label: 'Not Lead',
      },
    ],
  }),
  mutations: {
    setLeads(state, payload) {
      state.leads = payload
    },
    setLeadsStatistics(state, payload) {
      state.leadsStatistics = payload
    },
    setGlobalRelatedLeads(state, payload) {
      state.globalRelatedLeads = payload
    },
    setRelatedLeads(state, payload) {
      state.relatedLeads = payload
    },
    setActiveLead(state, payload) {
      state.activeLead = payload
    },
    setBlacklist(state, payload) {
      state.blacklist = payload
    },
    setSpammers(state, payload) {
      state.spammers = payload
    },
    setLeadsChart(state, payload) {
      state.leadsChart = payload
    },
    setLeadsForTable(state, payload) {
      state.leadsForTable = payload
    },
    setExtraFiltersVisibility(state, payload) {
      state.isExtraFilersCollapsed = payload
    },
  },
  getters: {
    blacklistFormatted(state) {
      return state?.blacklist?.map(x=>x.keyword).join('\n')
    },
  },
  actions: {
    async fetchLeads({commit}, params) {
      const result = await api.http.get('leads/list/data', {params})
      commit('setLeads', result?.data)
    },

    async fetchLeadsForTable({commit}, params) {
      const result = await api.http.post('lead/chart', params)
      commit('setLeadsForTable', result?.data)
    },

    async fetchLeadsStatistics({commit}, params) {
      const result = await api.http.get('leads/list/statistic', {params})
      commit('setLeadsStatistics', result?.data)
    },

    async fetchLeadsArchive({commit}, params) {
      const result = await api.http.get('leads/list/archived/data', {params})
      commit('setLeads', result?.data)
    },

    async fetchLeadsArchiveStatistics({commit}, params) {
      const result = await api.http.get('leads/list/archived/statistic', {params})
      commit('setLeadsStatistics', result?.data)
    },

    async fetchGlobalRelatedLeads({commit}, params) {
      const result = await api.http.get('lead/related', {params})
      commit('setGlobalRelatedLeads', result?.data)
    },

    async fetchRecord(_, id) {
      const result = await api.http.get(`recording/${id}`)
      return result?.data?.url
    },

    async createLead(_, params) {
      await api.http.post('lead/create', params)
    },

    async updateLead(_, params) {
      try {
        await api.http.put(`lead/${params.id}`, params)
      } catch(e) {
        console.error(e);
      }
    },

    async resetClientProgress(_, id) {
      await api.http.post(`lead/${id}/reset-progress`)
    },

    async sendWarning(_, id) {
      await api.http.post(`lead/send-warning/${id}`)
      Message.success('Warning was sent successfully')
    },

    async fetchBlacklist({ commit }) {
      const result = await api.http.get('leads/keywords')
      commit('setBlacklist', result?.data)
    },

    async updateBlacklist(_, params) {
      await api.http.post('leads/keywords', params)
    },

    async fetchSpammers({ commit }) {
      const result = await api.http.get('leads/spammers')
      commit('setSpammers', result?.data?.data)
    },

    async createSpammer(_, params) {
      await api.http.post('leads/spammers', params)
    },

    async deleteSpammer(_, id) {
      await api.http.delete(`leads/spammers/${id}`)
    },

    async sendNotification(_, id) {
      await api.http.post(`lead/notification/${id}`)
      Message.success('Notification was sent successfully')
    },

    async fetchRelatedLeads({state, commit}, params) {
      const result = await api.http.get(`lead/${state.activeLead.id}/related`, {params})
      commit('setRelatedLeads', result?.data)
    },

    async exportLeads(_, params) {
      const response = await api.http.post(`lead/export`, params)
      fileSaver.saveFileFromResponse(response, 'leads.csv')
    },

    async exportLeadsChart(_, params) {
      const response = await api.http.post(`lead/chart/export`, params)
      fileSaver.saveFileFromResponse(response, 'lead_chart.csv')
    },

    async fetchLeadsChart({commit}, params) {
      const result = await api.http.get(`lead/chart/${params.campaign_id || 'total'}`, {params})
      commit('setLeadsChart', result?.data)
    },

    async archiveLead(_, id) {
      await api.http.post(`lead/${id}/archive`)
    },

    async unarchiveLead(_, id) {
      await api.http.post(`lead/${id}/unarchive`)
    },

    async fetchLeadForRate(_, {leadId, authKey,}) {
      return (await api.http.get(`rate/${leadId}?auth_key=${authKey}`)).data;
    },

    async saveLeadFromMail(_, {leadId, authKey, leadData}) {
      return (await api.http.post(`rate/${leadId}?auth_key=${authKey}`, leadData)).data;
    }
  },
  namespaced: true,
}
