<template>
  <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
  >
    <el-dialog
        :visible="visible"
        :before-close="handleClose"
        :title="isEdit ? 'Edit Campaign Category' : 'Create Campaign Category'"
        width="94%"
    >
      <div class="row">
        <div class="col-sm-8">
          <el-form-item label="Category Name" prop="name">
            <el-input v-model="form.name"/>
          </el-form-item>
        </div>
        <div class="col-sm-4">
          <el-form-item label="Category Color" prop="name">
            <el-color-picker v-model="form.color" />
          </el-form-item>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="onSubmit">Save</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import modalMixin from '@/mixins/modalMixin'

const defaultFormValues = {
  id: null,
  name: null,
  color: '#ffffff',
}

export default {
  name: 'AddEditCategoryDialog',
  mixins: [modalMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: { ...defaultFormValues },
      rules: {
        name: [
          { required: true, message: 'Please input category name', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapState('campaigns', [
      'activeCampaignCategory'
    ]),
    isEdit() {
      return !!(this.activeCampaignCategory?.id)
    }
  },
  watch: {
    activeCampaignCategory() {
      if (this.isEdit) {
        this.fillForm()
      }
    }
  },
  methods: {
    ...mapMutations('campaigns', [
      'setActiveCampaignCategory'
    ]),
    ...mapActions('campaigns', [
      'createCampaignCategory',
      'updateCampaignCategory'
    ]),
    fillForm() {
      this.form.id = this.activeCampaignCategory?.id
      this.form.name = this.activeCampaignCategory?.name
      this.form.color = this.activeCampaignCategory?.color
    },
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.isEdit) {
            await this.updateCampaignCategory(this.form)
          } else {
            await this.createCampaignCategory(this.form)
          }
          this.$emit('refetch-data')
          this.closeModal()
        }
      })
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal)
    },
    closeModal() {
      this.resetForm()
      this.setActiveCampaignCategory(null)
      this.$emit('close')
    },
    resetForm() {
      this.form = { ...defaultFormValues }
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 500px;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}
</style>
