import Vue from 'vue'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import { Message } from 'element-ui'

Vue.use(VueCookies)

class Api {

	token = null
	http = axios.create({
		baseURL: process.env.VUE_APP_BASE_API_URI,
		headers: {
			'Authorization': `Bearer ${this.getToken()}`
		}
	})

	constructor() {
		this.setInterceptors()
	}


	async setToken(token) {
		const d = new Date()
		const nextMonth = d.setMonth(d.getMonth() + 1)
		await Vue.$cookies.set('token', token, nextMonth, '', process.env.VUE_APP_COOKIES_DOMAIN)

		this.http.defaults.headers = {
			'Authorization': 'Bearer ' + token
		}
	}

	setInterceptors() {
		this.http.interceptors.response.use(
			response => response,
			error => {
				this.errorHandler(error)
				if (error.response?.status === 401) {
					window.location = '/login'
				} else {
					return Promise.reject(error)
				}
			}
		)
	}

	errorHandler(e) {
		const res = e?.request?.response
		let message
		if (res) {
			const response = JSON.parse(res)
			if (response.errors) {
				const errorsArray = Object.values(response?.errors)
				message = `${response.message}\n${errorsArray.map(i => i.join('\n')).join('\n')}`
			} else {
				message = response.message
			}
		} else {
			message = e.message
		}
		Message.error(message)
	}

	clearToken() {
		Vue.$cookies.remove('token')
		this.http.defaults.headers = {
			'Authorization': null
		}
	}

	getToken() {
		return Vue.$cookies.get('token') || null
	}
}

export default new Api
