<template>
  <div v-loading="loading" ref="loading-wrapper">
    <div
      v-if="(list && list.length) || loading"
      ref="table-wrapper"
      :class="{
        'table-wrapper--related-leads': isRelatedLeads,
        'table-wrapper--base-related-lead': isBaseRelatedLead,
        'table-wrapper--global-related-leads': isGlobalRelatedLeads
      }"
      class="table-wrapper"
    >
      <table :class="{}" class="table">
        <thead>
        <tr>
          <th v-if="isColumnVisible('campaign')">
            <SortLabel
              :order="$_sortingMixin_checkOrder('campaign.name')"
              label="Campaign"
              @click.native="$_sortingMixin_doSort('campaign.name')"
            />
          </th>

          <th v-if="isColumnVisible('lead_date')" class="text-center">
            <SortLabel
              :order="$_sortingMixin_checkOrder('lead_date')"
              label="Date"
              @click.native="$_sortingMixin_doSort('lead_date')"
            />
          </th>

          <th v-if="isColumnVisible('lead_type')" class="text-center">
            <SortLabel
              :order="$_sortingMixin_checkOrder('lead_type')"
              label="Type"
              @click.native="$_sortingMixin_doSort('lead_type')"
            />
          </th>

          <th v-if="isColumnVisible('tracking_phone_number') && isAdminOrSuperAdmin">
            Tracking Phone
          </th>
          <th v-if="isColumnVisible('tracking_source') && isAdminOrSuperAdmin">
            Tracking Source
          </th>

          <th v-if="isColumnVisible('lead_name')">
            <SortLabel
              :order="$_sortingMixin_checkOrder('lead_name')"
              label="Name"
              @click.native="$_sortingMixin_doSort('lead_name')"
            />
          </th>

          <th v-if="isColumnVisible('lead_location')">
            <SortLabel
              :order="$_sortingMixin_checkOrder('lead_location')"
              label="Location"
              @click.native="$_sortingMixin_doSort('lead_location')"
            />
          </th>

          <th v-if="isColumnVisible('lead_email')">
            <SortLabel
              :order="$_sortingMixin_checkOrder('lead_email')"
              label="Email"
              @click.native="$_sortingMixin_doSort('lead_email')"
            />
          </th>

          <th v-if="isColumnVisible('lead_phone')">
            <SortLabel
              :order="$_sortingMixin_checkOrder('lead_phone')"
              label="Phone"
              @click.native="$_sortingMixin_doSort('lead_phone')"
            />
          </th>

          <th v-if="isColumnVisible('message')">
            <SortLabel
              :order="$_sortingMixin_checkOrder('duration')"
              label="Message"
              @click.native="$_sortingMixin_doSort('duration')"
            />
          </th>

          <th v-if="isColumnVisible('related') && !isRelatedLeads">Related</th>
          <th v-if="isColumnVisible('notes')">Notes</th>
          <th v-if="isColumnVisible('tags') && user.permissions.show_lead_http_columns">
            Tags
          </th>

          <th v-if="isColumnVisible('confirmed')">
            <SortLabel
              :order="$_sortingMixin_checkOrder('confirmed')"
              label="Verified"
              @click.native="$_sortingMixin_doSort('confirmed')"
            />
          </th>

          <th v-if="isColumnVisible('changers') && isAdminOrSuperAdmin && showLeadHttpColumns">Changers</th>

          <th v-if="isColumnVisible('client_progress')">
            <SortLabel
              :order="$_sortingMixin_checkOrder('client_progress')"
              label="Progress"
              @click.native="$_sortingMixin_doSort('client_progress')"
            />
          </th>

          <th v-if="isColumnVisible('company_name')">Company Name</th>
          <th v-if="isColumnVisible('client_notes')">Client Notes</th>

          <th v-if="isColumnVisible('client_value')">
            <SortLabel
              :order="$_sortingMixin_checkOrder('client_value')"
              label="Value"
              @click.native="$_sortingMixin_doSort('client_value')"
            />
          </th>

          <th v-if="isColumnVisible('client_profit')">
            <SortLabel
              :order="$_sortingMixin_checkOrder('client_profit')"
              label="Profit"
              @click.native="$_sortingMixin_doSort('client_profit')"
            />
          </th>

          <th v-if="isColumnVisible('rating')">
            <SortLabel
              :order="$_sortingMixin_checkOrder('rating')"
              label="Rating"
              @click.native="$_sortingMixin_doSort('rating')"
            />
          </th>

          <th v-if="isColumnVisible('url') && isAdminOrSuperAdmin && showLeadHttpColumns">URL</th>
          <th v-if="isColumnVisible('referrer') && isAdminOrSuperAdmin && showLeadHttpColumns">Referrer</th>

          <th v-if="isColumnVisible('source') && isAdminOrSuperAdmin && showLeadHttpColumns">
            <SortLabel
              :order="$_sortingMixin_checkOrder('source')"
              label="Source"
              @click.native="$_sortingMixin_doSort('source')"
            />
          </th>

          <th v-if="isColumnVisible('keywords') && isAdminOrSuperAdmin && showLeadHttpColumns">Keyword</th>
          <th v-if="isColumnVisible('gclid') && isAdminOrSuperAdmin && showLeadHttpColumns">gclid</th>
          <th v-if="isColumnVisible('hide') && isAdminOrSuperAdmin">Is Hidden</th>

          <th
            v-if="isColumnVisible('action')"
            class="text-center"
          >
            Actions
          </th>

        </tr>
        </thead>

        <tbody v-if="list">
        <LeadsListItem
          v-for="lead in list"
          :key="lead.id"
          :is-related-leads="isRelatedLeads"
          :lead="lead"
          @filter="(obj) => $emit('filter', obj)"
          @open-related-leads-dialog="$emit('open-related-leads-dialog')"
          @refetch-data="$emit('refetch-data')"
        />
        </tbody>
      </table>
    </div>

    <EmptyList v-else />
  </div>
</template>

<script>
import EmptyList from '@/components/global/EmptyList'
import SortLabel from '@/components/global/SortLabel'
import sortingMixin from '@/mixins/sortingMixin'
import {mapGetters, mapState} from 'vuex'
import LeadsListItem from '@/components/leads/LeadsListItem'

export default {
  name: 'LeadsList',
  mixins: [sortingMixin],
  components: {
    LeadsListItem,
    EmptyList,
    SortLabel,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default() {
        return []
      },
    },
    isRelatedLeads: {
      type: Boolean,
      default: false,
    },
    isGlobalRelatedLeads: {
      type: Boolean,
      default: false,
    },
    isBaseRelatedLead: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('auth', [
      'hiddenColumns',
      'isColumnVisible',
      'isAdminOrSuperAdmin',
      'showLeadHttpColumns',
    ]),
    ...mapState('auth', ['user']),
    ...mapState('leads', [
      'isExtraFilersCollapsed',
    ]),
  },
  created() {
    window.addEventListener('resize', this.myEventHandler)
  },
  watch: {
    list() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.myEventHandler()
        })
      })
    },
    isExtraFilersCollapsed() {
      this.myEventHandler()
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.myEventHandler)
  },
  methods: {
    myEventHandler() {
      const tableWrapper = this.$refs['table-wrapper']
      const loadingWrapper = this.$refs['loading-wrapper']
      if (tableWrapper && loadingWrapper) {
        tableWrapper.style.maxHeight = `calc(100vh - ${loadingWrapper.offsetTop + 90}px)`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.table-wrapper {
  &.table-wrapper--base-related-lead {
    min-height: 0 !important;
  }

  &.table-wrapper--related-leads {
    max-height: initial !important;
  }
}

</style>
