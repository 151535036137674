<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    label-position="top"
  >
    <el-dialog
      :before-close="handleClose"
      :visible="visible"
      title="Spammers Identifiers List"
      width="94%"
    >
      <div class="row">
        <div class="col-sm-5">
          <el-form-item prop="name">
            <label class="el-form-item__label">
              Name
            </label>
            <el-input v-model="form.name" />
          </el-form-item>

          <el-alert
            class="alert"
            type="info"
            :closable="false"
            show-icon>
            <template #title>
              Either Phone or Email is required
            </template>
          </el-alert>

          <el-form-item class="phone-field-wrapper" prop="phone">
            <label class="el-form-item__label">
              Phone
            </label>
            <el-input
              v-model="form.phone"
              v-mask="'+# ###-###-####'"
              placeholder="+_ ___-___-____"
            />
          </el-form-item>

          <el-form-item prop="email">
            <label class="el-form-item__label">
              Email
            </label>
            <el-input v-model="form.email" />
          </el-form-item>

          <el-button
            :disabled="!((form.phone || form.email) && form.name && form.name.length > 1 )"
            icon="el-icon-plus"
            type="accent"
            @click="onSubmit"
          >
            Add
          </el-button>
        </div>

        <div class="col-md-7">
          <SpammersList
            :loading="loading"
            @refetch-data="getSpammers"
            @enable-loading="loading = true"
          />
        </div>
      </div>
    </el-dialog>
  </el-form>
</template>

<script>
import {mapActions} from 'vuex'
import modalMixin from '@/mixins/modalMixin'
import SpammersList from '@/components/leads/SpammersList'
import {mask} from 'vue-the-mask'

const defaultFormValues = {
  name: '',
  phone: '',
  email: '',
}

export default {
  name: 'SpammersDialog',
  components: {SpammersList},
  directives: { mask },
  mixins: [modalMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateLink = (rule, value, callback) => {
      const regExp = /^\+\d\s\d{3}-\d{3}-\d{4}$/
      if (value === '') {
        callback();
      }
      if (!regExp.test(value)) {
        callback(new Error('Wrong phone format'));
      } else {
        callback();
      }
    };

    return {
      loading: false,
      form: {...defaultFormValues},
      originFormValues: {...defaultFormValues},
      rules: {
        name: [
          {required: true, message: 'Please input name', trigger: 'blur'},
          { min: 2, message: 'Length should be at least 2 characters', trigger: 'blur' }
        ],
        phone: [
          { validator: validateLink, trigger: 'blur' }
        ],
        email: [
          {type: 'email', message: 'Email is not valid', trigger: 'blur'},
        ],
      },
    }
  },
  computed: {
    isFormChanged() {
      return JSON.stringify(this.originFormValues) !== JSON.stringify(this.form)
    },
  },
  created() {
    this.getSpammers()
  },
  methods: {
    ...mapActions('leads', [
      'fetchSpammers',
      'createSpammer',
    ]),
    async getSpammers() {
      this.loading = true
      await this.fetchSpammers()
      this.loading = false
    },
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            await this.createSpammer(this.form)
          } finally {
            this.loading = false
            this.resetForm()
          }
          await this.getSpammers()
        }
      })
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal, this.isFormChanged)
    },
    closeModal() {
      this.$emit('close')
    },
    resetForm() {
      this.form = {...defaultFormValues}
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 1100px;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
}

.alert {
  margin-top: 10px;
  margin-bottom: 5px;
}

.el-form-item__label {

  span {
    color: #ff0000;

    span {
      color: #b1b1b1
    }
  }
}

.phone-field-wrapper {
  margin-bottom: 8px;
}
</style>
