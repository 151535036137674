<template>
  <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
  >
    <el-dialog
        :visible="visible"
        :before-close="handleClose"
        :title="isEdit ? 'Edit Activity Log Item' : 'Create Activity Log Item'"
        width="94%"
    >

      <div class="row">
        <div class="col-md-8">
          <el-form-item label="Campaigns or teams" prop="campaign_ids">
            <el-select
                v-model="form.campaign_ids"
                :disabled="isEdit"
                multiple
                filterable
            >
              <el-option
                  v-for="campaign in campaignsWithTeams"
                  :key="campaign.id"
                  :label="campaign.name"
                  :value="campaign.id"
              />
            </el-select>
          </el-form-item>
        </div>

        <div class="col-md-4">
          <el-form-item label="Date" prop="date">
            <el-date-picker
                v-model="form.date"
                type="date"
                format="MM/dd/yyyy"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                :clearable="false"
                :disabled="isEdit"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </div>

      <el-form-item class="switch-wrapper">
        <el-switch
            v-model="form.is_task"
            active-text="Task"
            @change="switchIsTask"
        />
      </el-form-item>

      <div class="row" v-if="form.is_task">
        <div class="col-sm-6">
          <el-form-item label="Admin user" prop="assignee_id">
            <el-select
                v-model="form.assignee_id"
                filterable
            >
              <el-option
                  v-for="admin in adminsWithSuperAdmin"
                  :key="admin.id"
                  :label="admin.username"
                  :value="admin.id"
              />
            </el-select>
          </el-form-item>
        </div>
      </div>

      <el-form-item label="Note" prop="note">
        <el-input
            v-model="form.note"
            type="textarea"
            class="note-input"
            :rows="8"
        />
      </el-form-item>

      <FileUploader
          action="/campaign-changelog/files"
          :files="form.files"
          @add-file="addFile"
          @delete-file="deleteFile"
      />

      <span slot="footer" class="dialog-footer">
        <el-button
            v-if="isEdit"
            type="danger"
            @click="onDelete"
        >
          Delete
        </el-button>

        <el-button @click="handleClose">
          Cancel
        </el-button>

        <el-button
            type="primary"
            @click="onSubmit"
        >
          Save
        </el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { sub, format } from 'date-fns'
import FileUploader from '@/components/global/FileUploader'
import modalMixin from '@/mixins/modalMixin'

const defaultFormValues = {
  id: null,
  campaign_ids: [],
  date: format(Date.now(), 'yyyy-MM-dd'),
  note: null,
  assignee_id: null,
  is_task: false,
  files: []
}

export default {
  name: 'AddEditItemDialog',
  mixins: [modalMixin],
  components: { FileUploader },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: { ...defaultFormValues },
      originFormValues: { ...defaultFormValues },
      rules: {
        campaign_ids: [
          { required: true, message: 'Please select campaign of team', trigger: 'change' }
        ],
        assignee_id: [
          { required: true, message: 'Please select admin user', trigger: 'blur' }
        ],
        date: [
          { required: true, message: 'Please pick a date', trigger: 'change' }
        ],
        note: [
          { required: true, message: 'Please input your note', trigger: 'change' }
        ]
      },
      pickerOptions: {
        disabledDate: this.disabledDate
      }
    }
  },
  computed: {
    ...mapState('activity-log', [
      'activeChangelogItem',
      'changelogGridParams'
    ]),
    ...mapState('campaigns', [
      'campaignsWithTeams'
    ]),
    ...mapState('admin', [
      'adminsWithSuperAdmin'
    ]),
    isEdit() {
      return !!(this.activeChangelogItem?.id)
    },
    isFormChanged() {
      return JSON.stringify(this.originFormValues) !== JSON.stringify(this.form)
    }
  },
  watch: {
    activeChangelogItem() {
      if (this.activeChangelogItem) {
        this.fillForm()
      }
    },
    changelogGridParams() {
      if (!this.isEdit) {
        this.fillFormFromGrid()
      }
    }
  },
  methods: {
    ...mapMutations('activity-log', [
      'setActiveChangelogItem',
      'setChangelogGridParams'
    ]),
    ...mapActions('activity-log', [
      'createActivityLogItem',
      'updateActivityLogItem',
      'deleteActivityLogItem'
    ]),
    fillForm() {
      this.form = {...this.activeChangelogItem}
      this.form.campaign_ids = [this.activeChangelogItem?.campaign_id || this.activeChangelogItem?.team_id]
      this.originFormValues = {...this.form}
    },
    fillFormFromGrid() {
      this.form.campaign_ids = [this.changelogGridParams?.campaignId]
      this.form.date = this.changelogGridParams?.date
    },
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.form.responsible_user_id = this.form?.assignee_id
          if (this.isEdit) {
            await this.updateActivityLogItem(this.form)
          } else {
            await this.createActivityLogItem(this.form)
          }
          this.$emit('refetch-data')
          this.closeModal()
        }
      })
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal, this.isFormChanged)
    },
    closeModal() {
      this.resetForm()
      this.$emit('close')
      this.setActiveChangelogItem(null)
      this.setChangelogGridParams(null)
    },
    resetForm() {
      this.form = { ...defaultFormValues }
      this.form.files = []
      this.$refs.form.resetFields()
    },
    disabledDate(date) {
      return sub(Date.now(), { days: 1 }) > date
    },
    switchIsTask(val) {
      if (!val) {
        this.form.assignee_id = null
      }
    },
    addFile(file) {
      this.form.files.push(file.data)
    },
    deleteFile(fileId) {
      this.form.files = this.form.files.filter(item => item.id !== fileId)
    },
    async onDelete() {
      try {
        const res = await this.$confirm('Are you sure to remove this item?')
        if (res) {
          await this.deleteActivityLogItem(this.activeChangelogItem.id)
          this.closeModal()
          this.$emit('refetch-data')
        }
      } catch {}
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 800px;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}
</style>
