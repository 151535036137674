<template>
  <div class="panel panel-default filter-panel">
    <div class="filter-panel__left">
      <i class="fas fa-filter"></i>
    </div>
    <div class="panel-body">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterPanel'
}
</script>

<style lang="scss" scoped>

.panel {
  margin-bottom: 12px;
}

</style>
