<template>
  <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
  >
    <el-dialog
        :visible="visible"
        :before-close="handleClose"
        :title="isEdit ? 'Edit Network Category' : 'Create Network Category'"
        width="94%"
    >
      <el-form-item label="Category Name" prop="name">
        <el-input v-model="form.name"/>
      </el-form-item>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="onSubmit">Save</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import modalMixin from '@/mixins/modalMixin'

const defaultFormValues = {
  id: null,
  name: null
}

export default {
  name: 'AddEditCategoryDialog',
  mixins: [modalMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: { ...defaultFormValues },
      originFormValues: { ...defaultFormValues },
      rules: {
        name: [
          { required: true, message: 'Please input category name', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapState('network', [
      'activeNetworkCategory'
    ]),
    isEdit() {
      return !!(this.activeNetworkCategory?.id)
    },
    isFormChanged() {
      return JSON.stringify(this.originFormValues) !== JSON.stringify(this.form)
    }
  },
  watch: {
    activeNetworkCategory() {
      if (this.isEdit) {
        this.fillForm()
      }
    }
  },
  methods: {
    ...mapMutations('network', [
      'setActiveNetworkCategory'
    ]),
    ...mapActions('network', [
      'createNetworkCategory',
      'updateNetworkCategory'
    ]),
    fillForm() {
      this.form = { ...this.activeNetworkCategory }
      this.originFormValues = {...this.form}
    },
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.isEdit) {
            await this.updateNetworkCategory(this.form)
          } else {
            await this.createNetworkCategory(this.form)
          }
          this.$emit('refetch-data')
          this.closeModal()
        }
      })
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal, this.isFormChanged)
    },
    closeModal() {
      this.resetForm()
      this.setActiveNetworkCategory(null)
      this.$emit('close')
    },
    resetForm() {
      this.form = { ...defaultFormValues }
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 500px;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}
</style>
