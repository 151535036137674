<template>
  <el-form
    ref="form"
    :model="filters"
    class="filter-form"
    label-position="top"
  >
    <el-form-item label="Campaign categories">
      <el-select
        v-model="filters.campaign_category_ids"
        filterable
        class="multiselect"
        multiple
        @change="doFilter"
      >
        <el-option
          v-for="category in campaignCategories"
          :key="category.id"
          :label="category.name"
          :value="category.id"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Year">
      <el-select
        v-model="filters.year"
        class="multiselect"
        @change="doFilter"
      >
        <el-option
          v-for="year in years"
          :key="year.value"
          :label="year.label"
          :value="year.value"
        />
      </el-select>
    </el-form-item>


    <el-button
      v-if="isFiltersChanged"
      type="gray"
      @click="clearFilters"
    >
      Reset filters
    </el-button>
  </el-form>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {format, sub} from 'date-fns'


const defaultDataValues = {
  year: null,
  campaign_category_ids: [],
}

export default {
  name: 'LeadsTableFilters',
  data() {
    return {
      years: [
        {
          value: null,
          label: 'Last 12 Month'
        },
        this.getPresetItem(0),
        this.getPresetItem(1),
        this.getPresetItem(2),
        this.getPresetItem(3),
        this.getPresetItem(4),
        this.getPresetItem(5),
        this.getPresetItem(6),
        this.getPresetItem(7),
      ],
      filters: {...defaultDataValues},
      originFilters: {...defaultDataValues},
    }
  },
  computed: {
    ...mapState('campaigns', [
      'campaignCategories'
    ]),
    isFiltersChanged() {
      return JSON.stringify(this.originFilters) !== JSON.stringify(this.filters)
    },
  },
  created() {
    this.fetchCampaignCategories()
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchCampaignCategories'
    ]),
    async doFilter() {
      this.$emit('filter', this.filters)
    },
    async clearFilters() {
      this.filters = {...defaultDataValues}
      this.$emit('filter', this.filters)
    },
    getPresetItem(val) {
      return {
        label: format(sub(Date.now(), {years: val}), 'yyyy'),
        value: format(sub(Date.now(), {years: val}), 'yyyy'),
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.multiselect {
  min-width: 300px;
}

.date-range-picker {
  max-width: 350px;
}

</style>
