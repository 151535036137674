<template>
  <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
  >
    <el-dialog
        :visible="visible"
        :before-close="handleClose"
        title="cPanel Addon Domains Tool"
        width="94%"
    >
      <el-form-item label="Domain" prop="domain">
        <el-input v-model="form.domain"/>
      </el-form-item>

      <el-form-item label="Redirect Url" prop="redirect_url">
        <el-input v-model="form.redirect_url"/>
      </el-form-item>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="onSubmit">Save</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import { mapActions } from 'vuex'
import modalMixin from '@/mixins/modalMixin'

const defaultFormValues = {
  domain: '',
  redirect_url: ''
}

export default {
  name: 'AddRedirectDialog',
  mixins: [modalMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: { ...defaultFormValues },
      originFormValues: { ...defaultFormValues },
      rules: {
        domain: [
          { required: true, message: 'Please input domain', trigger: 'change' }
        ],
        redirect_url: [
          { required: true, message: 'Please input redirect url', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    isFormChanged() {
      return JSON.stringify(this.originFormValues) !== JSON.stringify(this.form)
    }
  },
  methods: {
    ...mapActions('network', [
      'createRedirect',
    ]),
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          await this.createRedirect(this.form)
          this.$emit('refetch-data')
          this.closeModal()
        }
      })
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal, this.isFormChanged)
    },
    closeModal() {
      this.resetForm()
      this.$emit('close')
    },
    resetForm() {
      this.form = { ...defaultFormValues }
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 500px;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}
</style>
