<template>
  <el-form
    ref="form"
    :model="filters"
    class="filter-form"
    label-position="top"
  >
    <el-form-item label="Show by">
      <el-radio-group v-model="filters.group" @change="doFilter">
        <el-radio-button label="week">Week</el-radio-button>
        <el-radio-button label="month">Month</el-radio-button>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="Campaign categories">
      <el-select
        v-model="filters.campaign_category_ids"
        filterable
        class="multiselect"
        multiple
        @change="doFilter"
      >
        <el-option
          v-for="category in campaignCategories"
          :key="category.id"
          :label="category.name"
          :value="category.id"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Date Range">
      <el-select
        v-model="filters.date_preset"
        filterable
        @change="changeDatePresets"
      >
        <el-option
          v-for="item in dateRangePresets"
          :key="item.label"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>

    <el-form-item v-show="isCustomDateRangeVisible" label="Custom Date Range">
      <el-date-picker
        v-model="filters.date_range"
        :clearable="false"
        class="date-range-picker"
        end-placeholder="End date"
        format="MM/dd/yyyy"
        range-separator="To"
        start-placeholder="Start date"
        type="daterange"
        value-format="yyyy-MM-dd"
        @change="doFilter"
      />
    </el-form-item>

    <el-form-item label="Chart Type">
      <el-radio-group :value="chartType">
        <el-radio-button label="line" @click.native="$emit('change-chart-type', 'line')">Line</el-radio-button>
        <el-radio-button label="bar" @click.native="$emit('change-chart-type', 'bar')">Bar</el-radio-button>
      </el-radio-group>
    </el-form-item>

    <el-button
      v-if="isFiltersChanged"
      type="gray"
      @click="clearFilters"
    >
      Reset filters
    </el-button>
  </el-form>
</template>

<script>
import {format, sub} from 'date-fns'
import startOfYear from 'date-fns/startOfYear'
import endOfYear from 'date-fns/endOfYear'
import {mapActions, mapState} from 'vuex'

const defaultFilterValues = {
  group: 'week',
  date_preset: 'last_12_months',
  campaign_category_ids: null,
  date_range: [],
}

export default {
  name: 'ChartFilters',
  props: {
    chartType: {
      type: String,
      required: true,
    },
    defaultDataValues: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCustomDateRangeVisible: false,
      filters: {
        ...defaultFilterValues,
        date_range: [
          this.defaultDataValues.startDate,
          this.defaultDataValues.endDate,
        ],
      },
      originFilters: {
        ...defaultFilterValues,
        date_range: [
          this.defaultDataValues.startDate,
          this.defaultDataValues.endDate,
        ],
      },
      dateRangePresets: [
        {
          label: 'Last 12 months',
          value: 'last_12_months',
          range: [
            this.defaultDataValues.startDate,
            this.defaultDataValues.endDate,
          ],
        },
        {
          label: format(Date.now(), 'yyyy'),
          value: format(Date.now(), 'yyyy'),
          range: [
            format(startOfYear(Date.now()), 'yyyy-MM-dd'),
            format(Date.now(), 'yyyy-MM-dd'),
          ],
        },
        this.getPresetItem(1),
        this.getPresetItem(2),
        this.getPresetItem(3),
        this.getPresetItem(4),
        this.getPresetItem(5),
        this.getPresetItem(6),
        this.getPresetItem(7),
        {
          label: 'Custom',
          value: 'custom'
        },
      ],
    }
  },
  computed: {
    ...mapState('campaigns', [
      'campaignCategories'
    ]),
    isFiltersChanged() {
      return JSON.stringify(this.originFilters) !== JSON.stringify(this.filters)
    },
  },
  created() {
    this.fetchCampaignCategories()
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchCampaignCategories'
    ]),
    changeDatePresets(val) {
      if (val === 'custom') {
        this.isCustomDateRangeVisible = true
      } else {
        this.filters.date_range = this.dateRangePresets.find(x=>x.value === val)?.range
        this.isCustomDateRangeVisible = false
        this.doFilter()
      }
    },
    async doFilter() {
      this.$emit('filter', this.filters)
    },
    async clearFilters() {
      this.filters = {
        ...defaultFilterValues,
        date_range: [
          this.defaultDataValues.startDate,
          this.defaultDataValues.endDate,
        ],
      }
      this.$emit('filter', this.filters)
    },
    getPresetItem(val) {
      return {
        label: format(sub(Date.now(), {years: val}), 'yyyy'),
        value: format(sub(Date.now(), {years: val}), 'yyyy'),
        range: [
          format(startOfYear(sub(Date.now(), {years: val})), 'yyyy-MM-dd'),
          format(endOfYear(sub(Date.now(), {years: val})), 'yyyy-MM-dd'),
        ],
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.multiselect {
  min-width: 300px;
}

.date-range-picker {
  max-width: 350px;
}

</style>
