<template>
  <el-form
      :model="filters"
      ref="form"
      label-position="top"
      class="filter-form"
  >
    <el-form-item class="filter-form__form-item">
      <el-input
          v-model="filters.q"
          v-debounce="doFilter"
          :placeholder="placeholder"
          class="search-input"
          prefix-icon="el-icon-search"
      />
      <div
        v-if="isFiltersChanged"
        class="filter-form__clear-icon"
        @click="clearFilters"
      >
        <i class="el-icon-close"/>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>

const defaultValues = {
  q: '',
}

export default {
  name: 'SearchFilterAlt',
  data() {
    return {
      filters: {...defaultValues},
      originFilters: {...defaultValues}
    }
  },
  props: {
    placeholder: {
      type: String,
      default: 'Search',
    },
  },
  computed: {
    isFiltersChanged() {
      return JSON.stringify(this.originFilters) !== JSON.stringify(this.filters)
    }
  },
  created() {
    this.applyIncomingParams()
  },
  methods: {
    async doFilter() {
      this.$emit('filter', this.filters)
    },
    async clearFilters() {
      this.filters = {...defaultValues}
      this.$emit('filter', this.filters)
    },
    applyIncomingParams() {
      const params = this.$route?.params
      if (params?.url) {
        this.filters.q = params?.url
        this.doFilter()
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.filter-form {
  position: relative;
  top: 2px;
}

.filter-form__form-item {

}

.filter-form__clear-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #aaaaaa;

  &:hover {
    color: #333333
  }

}

.search-input {

  ::v-deep .el-input__inner {
    min-width: 350px;
    height: 41px;
  }
}
</style>
