<template>
  <div class="panel panel-default">
    <div class="panel-body">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainPanel'
}
</script>

<style lang="scss" scoped>
.panel {
  margin-bottom: 0;
}
</style>
