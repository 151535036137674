<template>
  <div>
    <div ref="map" class="map"/>
  </div>
</template>

<script>
import L, { latLng } from 'leaflet'
import { mapState } from 'vuex'

export default {
  name: 'GmbsMap',
  props: {
    currentCategory: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      map: null,
      zoom: 4,
      center: latLng(42, -96),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      geoObjects: null,
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      bounds: null,
      markersLayer: [],
      polygonsLayer: []
    }
  },
  computed: {
    ...mapState('gmbs', [
      'gmbsMap'
    ]),
    ...mapState('campaigns', [
      'allCampaignsAreas'
    ])
  },
  mounted() {
    this.$nextTick(async () => {
      this.initMap()
      this.setMapLayer()
      this.fetchGeoObjects()
    })
  },
  methods: {
    initMap() {
      this.map = L.map(this.$refs.map, {
        preferCanvas: true
      }).setView(this.center, this.zoom)
    },
    setMapLayer() {
      L.tileLayer(this.url, {
        attribution: this.attribution
      }).addTo(this.map)
    },
    fetchGeoObjects() {
      this.getMapBounds()
      this.$emit('refetch-data', this.bounds)
    },
    renderGeoObjects() {
      this.renderPolygons()
      this.renderMarkers()
    },
    renderPolygons() {
      if (this.polygonsLayer) {
        this.map.removeLayer(this.polygonsLayer)
      }
      let polygons = []

      this.allCampaignsAreas.forEach(data => {
        let preparedPoints = data.points.map((item) => {
          return [item.latitude, item.longitude]
        })

        polygons.push(L.polygon(preparedPoints, { color: this.currentCategory?.color || data.color }).bindPopup(data.campaign_title))
        this.polygonsLayer = L.layerGroup(polygons).addTo(this.map)
      })
    },
    renderMarkers() {
      if (this.markersLayer) {
        this.map.removeLayer(this.markersLayer)
      }
      const markers = []

      let popupContent
      for (let geoPointData of this.gmbsMap) {
        let markerColor
        if (geoPointData.color) {
          markerColor = this.currentCategory?.color || geoPointData.color
        } else {
          switch (geoPointData.status) {
            case 1: {
              markerColor = 'green'
              break
            }
            case 4: {
              markerColor = 'red'
              break
            }
            default: {
              markerColor = 'orange'
            }
          }
        }
        popupContent = geoPointData.title
        if (geoPointData.website) {
          popupContent += `<br /><a target="_blank" href="${geoPointData.website}">${geoPointData.website}</a>`
        }
        if (geoPointData.address) {
          popupContent += `<br />${geoPointData.address}`
        }
        if (geoPointData.phone) {
          popupContent += `<br />${geoPointData.phone}`
        }

        const icon = new L.divIcon({
          type: 'div',
          html: `<svg xmlns="http://www.w3.org/2000/svg" height="45" width="32.294117" version="1.1" x="0px" y="0px" viewBox="0 0 56.284032 85" enable-background="new 0 0 61 85" xml:space="preserve">
          <path fill="${markerColor}" d="m 29.194444,0 c 16.568,0 29.25,12.488 29.25,29.057 V 30 c 0,21.834 -19.322,49 -29.75,55 h -0.25 C 18.016444,79 -2.5555556,51.834 -2.5555556,30 V 29.057 C -2.5555556,12.488 11.376444,0 27.944444,0 c 0.167,0 1.083,0 1.25,0 z" id="path2"/>
          ${this.getMapIcon(geoPointData)}
          </svg>`,
          className: '',
          iconAnchor: [17, 44]
        })

        const marker = L.marker([geoPointData.latitude, geoPointData.longitude], { icon, message: popupContent })
        .bindPopup(popupContent, {className: 'map-marker-popup'})

        markers.push(marker)
      }

      this.markersLayer = L.layerGroup(markers).addTo(this.map)
    },
    getMapBounds() {
      let bounds = this.map.getBounds()
      this.bounds = {
        'right-longitude': bounds._northEast.lng,
        'upper-latitude': bounds._northEast.lat,
        'down-latitude': bounds._southWest.lat,
        'left-longitude': bounds._southWest.lng
      }
    },
    getMapIcon(data) {
      if (data.is_client_location) {
        return `<g style="fill:#ffffff" transform="matrix(0.07626449,0,0,0.07626449,8.5986004,9.8588284)">
                  <polygon points="256,152.96 79.894,288.469 79.894,470.018 221.401,470.018 221.401,336.973 296.576,336.973 296.576,470.018 432.107,470.018 432.107,288.469 "/>
                  <polygon points="256,115.062 476.662,284.856 512,238.92 439.482,183.132 439.482,90.307 365.316,90.307 365.316,126.077 256,41.982 0,238.919 35.339,284.855 "/>
                </g>`
      } else {
        return `<g style="fill:#ffffff" transform="matrix(0.07897359,0,0,0.07897359,11.84269,15.627754)">
                  <path d="M 421.985,229.833 217.847,25.981 C 210.612,18.743 200.907,12.607 188.726,7.565 176.541,2.522 165.407,0 155.318,0 H 36.547 C 26.648,0 18.083,3.619 10.85,10.848 3.617,18.081 0.002,26.646 0.002,36.545 v 118.771 c 0,10.088 2.519,21.219 7.564,33.404 5.045,12.185 11.182,21.792 18.417,28.837 L 230.118,421.98 c 7.043,7.043 15.602,10.564 25.697,10.564 9.89,0 18.558,-3.521 25.98,-10.564 L 421.981,281.51 c 7.043,-7.046 10.561,-15.604 10.561,-25.693 0,-9.898 -3.518,-18.559 -10.557,-25.984 z M 117.202,117.201 c -7.142,7.138 -15.752,10.709 -25.841,10.709 -10.085,0 -18.699,-3.571 -25.837,-10.709 -7.138,-7.139 -10.706,-15.749 -10.706,-25.837 0,-10.089 3.568,-18.702 10.706,-25.837 7.139,-7.139 15.752,-10.71 25.837,-10.71 10.089,0 18.702,3.571 25.841,10.71 7.135,7.135 10.706,15.749 10.706,25.837 0,10.088 -3.567,18.698 -10.706,25.837 z"/>
                </g>`
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.map {
  flex-grow: 1;
  height: calc(100vh - 240px);
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

::v-deep .leaflet-popup {
  margin-bottom: 50px!important;
}


</style>
