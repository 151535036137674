<template>
  <div>
    <div class="page-title-group">
      <h1>IP Addresses</h1>
    </div>

    <FilterPanel>
      <IPsFilters @filter="doFilter"/>
    </FilterPanel>

    <MainPanel>
      <div class="action-bar">
        <PageLimitSwitcher :active="filterObject.per_page" @click="setPageLimit"/>

        <el-pagination
            v-if="ipsList"
            background
            layout="prev, pager, next"
            :page-size="filterObject.per_page"
            :current-page="filterObject.page"
            :total="ipsList.total"
            hide-on-single-page
            @current-change="changePage"
        />

        <div class="table-total">
          Total Items: {{ ipsList && ipsList.total || '-' }}
        </div>
      </div>

      <IPsList
          :loading="loading"
          :list="ipsList && ipsList.data"
          @sort="doSort"
      />
    </MainPanel>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import IPsFilters from '@/components/ips/IPsFilters'
import PageLimitSwitcher from '@/components/global/PageLimitSwitcher'
import IPsList from '@/components/ips/IPsList'
import FilterPanel from '@/components/global/FilterPanel'
import MainPanel from '@/components/global/MainPanel'

export default {
  name: 'IPsPage',
  metaInfo: {
    title: 'IP Addresses'
  },
  components: {
    MainPanel,
    FilterPanel,
    IPsFilters,
    PageLimitSwitcher,
    IPsList
  },
  data() {
    return {
      loading: false,
      IPsList: null,
      filterObject: {
        page: 1,
        per_page: 200,
        cache: true
      }
    }
  },
  computed: {
    ...mapState('network', [
      'ipsList'
    ])
  },
  mounted() {
    this.getIps()
  },
  methods: {
    ...mapActions('network', [
      'fetchIPs'
    ]),
    async getIps() {
      this.loading = true
      await this.fetchIPs(this.filterObject)
      this.loading = false
    },
    doFilter(filters) {
      this.filterObject = { ...this.filterObject, ...filters }
      this.filterObject.cache = false
      this.getIps()
    },
    doSort(sort) {
      this.filterObject = { ...this.filterObject, ...sort }
      this.filterObject.page = 1
      this.filterObject.cache = false
      this.getIps()
    },
    setPageLimit(val) {
      this.filterObject.per_page = val
      this.filterObject.page = 1
      this.filterObject.cache = false
      this.getIps()
    },
    changePage(page) {
      this.filterObject.page = page
      this.filterObject.cache = false
      this.getIps()
    }
  }
}
</script>
