<template>
  <div @click="doCopy">
    <slot/>
  </div>
</template>

<script>
import { Message } from 'element-ui'

export default {
  name: 'CopyToClipboard',
  props: {
    copy: {
      type: String,
      required: true
    }
  },
  methods: {
    async doCopy() {
      await this.$copyText(this.copy)
      Message.success('Copied to clipboard')
    }
  }
}
</script>

<style scoped>

</style>
