<template>
  <div v-loading="loading">
    <div v-if="(list && list.length) || loading" class="table-wrapper">
      <table class="table">
        <thead>
        <tr>
          <th>Date</th>
          <th>User</th>
          <th>Campaign/Team</th>
          <th>Responsible admin</th>
          <th class="text-center">Is done</th>
          <th>Notes</th>
          <th class="text-center">Files</th>
          <th class="fixed-right-column text-center">Actions</th>
        </tr>
        </thead>

        <tbody v-if="list">
        <tr v-for="item in list" :key="item.id">
          <td>
            {{ item.date }}
          </td>
          <td>
            {{ item.user && item.user.username }}
          </td>
          <td>
            {{ campaignOrTeamById(item.campaign_id) }}
            {{ campaignOrTeamById(item.team_id)}}
          </td>
          <td>
            {{ item.assignee && item.assignee.username }}
          </td>
          <td class="text-center">
            <div v-if="item.is_task">
              <el-switch
                  v-model="item.is_done"
                  active-color="#67C23A"
                  @change="toggleStatus(item.id)"
              />
            </div>
          </td>
          <td class="note-cell">
            <nl2br tag="p" :text="item.note"/>
          </td>
          <td class="text-center">
            {{ item.files.length || '-' }}
          </td>
          <td class="fixed-right-column">
            <el-button-group>
              <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  title="Edit Campaign"
                  @click="onEdit(item)"
              />
              <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  title="Archive Campaign"
                  @click="onDelete(item)"
              />
            </el-button-group>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else/>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import formatterMixin from '@/mixins/formatterMixin'
import Nl2br from 'vue-nl2br'
import EmptyList from '@/components/global/EmptyList'

export default {
  name: 'ActivityLogList',
  mixins: [formatterMixin],
  components: {
    EmptyList,
    Nl2br
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState('activity-log', [
      'activityLog'
    ]),
    ...mapGetters('campaigns', [
      'campaignOrTeamById'
    ]),
    list() {
      return this.activityLog?.data
    }
  },
  methods: {
    ...mapMutations('activity-log', [
      'setActiveChangelogItem'
    ]),
    ...mapActions('activity-log', [
      'toggleDoneStatus',
      'deleteActivityLogItem'
    ]),
    onEdit(item) {
      this.setActiveChangelogItem(item)
      this.$emit('edit')
    },
    async onDelete(item) {
      try {
        const res = await this.$confirm('Are you sure to remove this item?')
        if (res) {
          await this.deleteActivityLogItem(item.id)
          this.$emit('refetch-data')
        }
      } catch {}
    },
    async toggleStatus(id) {
      await this.toggleDoneStatus(id)
      this.$emit('refetch-data')
    }
  }
}
</script>

<style lang="scss" scoped>

.table-wrapper {
  max-height: calc(100vh - 350px) !important;
}

.table td {
  vertical-align: top !important;
}

.note-cell {
  max-width: 400px;
}
</style>
