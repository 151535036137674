import Vue from 'vue'

export default {
    computed: {
        token() {
            return Vue.$cookies.get('token')
        }
    },
    methods: {
        $_imageMixin_getImagePath(path) {
            return `${path}?api_token=${this.token}`
        }
    }
}
