<template>
  <div>
    <div class="page-title-group">
      <h1>Campaign Categories</h1>

      <el-button
          type="accent"
          icon="el-icon-plus"
          @click="openAddEditDialog"
      >
        Create New Category
      </el-button>
    </div>

    <MainPanel>
      <div class="action-bar">
        <div></div>
        <div class="table-total">
          Total Items: {{ campaignCategories && campaignCategories.length || '-' }}
        </div>
      </div>

      <CampaignCategoriesList
          :loading="loading"
          @edit="openAddEditDialog"
          @refetch-data="getCategoriesList(false)"
      />
    </MainPanel>

    <AddEditCategoryDialog
        :visible.sync="addEditDialogVisible"
        @close="closeAddEditDialog"
        @refetch-data="getCategoriesList(false)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CampaignCategoriesList from '@/components/campaign-categories/CampaignCategoriesList'
import AddEditCategoryDialog from '@/components/campaign-categories/AddEditCategoryDialog'
import MainPanel from '@/components/global/MainPanel'

export default {
  name: 'CampaignCategoriesPage',
  metaInfo: {
    title: 'Campaign Categories'
  },
  components: {
    MainPanel,
    AddEditCategoryDialog,
    CampaignCategoriesList
  },
  data() {
    return {
      loading: false,
      addEditDialogVisible: false
    }
  },
  computed: {
    ...mapState('campaigns', [
      'campaignCategories'
    ])
  },
  mounted() {
    this.getCategoriesList()
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchCampaignCategories'
    ]),
    async getCategoriesList(cache = true) {
      this.loading = true
      await this.fetchCampaignCategories(cache)
      this.loading = false
    },
    openAddEditDialog() {
      this.addEditDialogVisible = true
    },
    closeAddEditDialog() {
      this.addEditDialogVisible = false
    }
  }
}
</script>
