<template>
  <el-dialog
      :visible="visible"
      :before-close="handleClose"
      :title="`Links for ${activeNetworkSite && activeNetworkSite.url}`"
      width="94%"
      top="30px"
  >

    <OutgoingLinksFilters
        :exportLoading="exportLoading"
        @filter="doFilter"
        @export="doExport"
        network-site-determined
    />

    <div class="mb-2"></div>

    <div class="action-bar">
      <PageLimitSwitcher :active="filterObject.per_page" @click="setPageLimit"/>

      <el-pagination
          v-if="outgoingLinksList"
          background
          layout="prev, pager, next"
          :page-size="filterObject.per_page"
          :current-page="filterObject.page"
          :total="outgoingLinksList.total"
          hide-on-single-page
          @current-change="changePage"
      />

      <div class="table-total">
        Total Items: {{ outgoingLinksList && outgoingLinksList.total || '-' }}
      </div>
    </div>

    <OutgoingLinksList
        :loading="loading"
        network-site-determined
        @sort="doSort"
    />
  </el-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import OutgoingLinksFilters from '@/components/outgoing-links/OutgoingLinksFilters'
import OutgoingLinksList from '@/components/outgoing-links/OutgoingLinksList'
import PageLimitSwitcher from '@/components/global/PageLimitSwitcher'

export default {
  name: 'LinksDialog',
  components: { PageLimitSwitcher, OutgoingLinksList, OutgoingLinksFilters },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      exportLoading: false,
      filterObject: {
        page: 1,
        per_page: 200
      }
    }
  },
  computed: {
    ...mapState('sites', [
      'activeNetworkSite'
    ]),
    ...mapState('network', [
      'outgoingLinksList'
    ])
  },
  watch: {
    activeNetworkSite() {
      if (this.activeNetworkSite) {
        this.getOutgoingLinks()
      }
    }
  },
  methods: {
    ...mapMutations('sites', [
      'setActiveNetworkSite',
      'setNetworkSiteLog'
    ]),
    ...mapActions('network', [
      'fetchOutgoingLinks',
      'exportOutgoingLinks'
    ]),
    async getOutgoingLinks() {
      this.loading = true
      this.filterObject.network_site_q = this.activeNetworkSite.url
      await this.fetchOutgoingLinks(this.filterObject)
      this.loading = false
    },
    async handleClose() {
      this.setActiveNetworkSite(null)
      this.setNetworkSiteLog(null)
      this.$emit('close')
    },
    doFilter(filters) {
      this.filterObject = { ...this.filterObject, ...filters }
      this.getOutgoingLinks()
    },
    doSort(sort) {
      this.filterObject = { ...this.filterObject, ...sort }
      this.filterObject.page = 1
      this.getOutgoingLinks()
    },
    setPageLimit(val) {
      this.filterObject.per_page = val
      this.filterObject.page = 1
      this.getOutgoingLinks()
    },
    changePage(page) {
      this.filterObject.page = page
      this.getOutgoingLinks()
    },
    doExport() {
      this.exportOutgoingLinks(this.filterObject)
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 1500px;
}

::v-deep .el-dialog__body {
  padding-top: 15px;
}

</style>
