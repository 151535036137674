<template>
  <el-dialog
    :before-close="handleClose"
    :visible="visible"
    bottom="0"
    title=""
    top="30px"
    width="96%"
    @opened="getRelatedLeads()"
  >
    <LeadsList
      :list="baseLead && [baseLead]"
      :loading="loading"
      is-base-related-lead
      @sort="doSort"
      @refetch-data="getRelatedLeads(true)"
    />

    <div class="icons-container">
      <i class="fas fa-arrow-down" />
      Related Leads
      <i class="fas fa-arrow-down" />
    </div>

    <div class="action-bar">
      <PageLimitSwitcher :active="filterObject.per_page" @click="setPageLimit" />

      <el-pagination
        v-if="leads"
        :current-page="filterObject.page"
        :page-size="filterObject.per_page"
        :total="leads.meta.total"
        background
        hide-on-single-page
        layout="prev, pager, next"
        @current-change="changePage"
      />

      <div class="table-total">
        Total Items: {{ leads && leads.meta.total || '-' }}
      </div>
    </div>

    <LeadsList
      :list="list"
      :loading="loading"
      is-related-leads
      @sort="doSort"
      @refetch-data="getRelatedLeads(true)"
    />
  </el-dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import PageLimitSwitcher from '@/components/global/PageLimitSwitcher'
import LeadsList from '@/components/leads/LeadsList'

export default {
  name: 'RelatedLeadsDialog',
  components: {LeadsList, PageLimitSwitcher},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      filterObject: {
        page: 1,
        per_page: 50,
        global: this.$route.name === 'LeadsRelated' ? 1 : 0
      },
      baseLead: null,
    }
  },
  computed: {
    ...mapState('leads', {
      leads: 'relatedLeads',
    }),
    list() {
      if (!this.leads) return []
      const list = []
      this.leads.data.forEach((item, index) => {
        if (index === 0) {
          this.baseLead = item
        } else {
          list.push(item)
        }
      })
      return list
    },
  },
  methods: {
    ...mapMutations('leads', [
      'setActiveLead',
      'setRelatedLeads',
    ]),
    ...mapActions('leads', [
      'fetchRelatedLeads',
    ]),
    async getRelatedLeads(isSilent = false) {
      this.loading = !isSilent
      await this.fetchRelatedLeads(this.filterObject)
      this.loading = false
    },
    async handleClose() {
      this.$emit('close')
      this.setRelatedLeads(null)
    },
    doSort(sort) {
      this.filterObject = {...this.filterObject, ...sort}
      this.filterObject.page = 1
      this.getRelatedLeads()
    },
    setPageLimit(val) {
      this.filterObject.per_page = val
      this.filterObject.page = 1
      this.getRelatedLeads()
    },
    changePage(page) {
      this.filterObject.page = page
      this.getRelatedLeads()
    },
  },
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog__body {
  padding-top: 15px;
}

::v-deep .el-dialog {
  margin-bottom: 0 !important;
}

.icons-container {
  display: flex;
  justify-content: center;
  font-size: 30px;
  color: #d5d5d5;
  font-weight: 600;
  align-items: center;

  > * {
    margin-right: 20px;
    margin-left: 20px;
  }
}

</style>
