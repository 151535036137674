<template>
  <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
  >
    <el-dialog
        :visible="visible"
        :before-close="handleClose"
        :title="isEdit ? 'Edit Campaign GMB' : 'Create Campaign GMB'"
        width="94%"
        top="2%"
    >
      <div class="row">
        <div class="col-md-6">
          <el-form-item label="Title" prop="title">
            <el-input
                v-model="form.title"
                type="textarea"
                :rows="3"
            />
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item label="Address" prop="address">
            <el-input
                v-model="form.address"
                type="textarea"
                :rows="3"
            />
          </el-form-item>
        </div>
      </div>


      <div class="row">
        <div class="col-md-6">
          <el-form-item label="Website" prop="website">
            <el-input v-model="form.website"/>
          </el-form-item>
        </div>

        <div class="col-md-3">
          <el-form-item label="Phone" prop="phone">
            <el-input v-model="form.phone"/>
          </el-form-item>
        </div>

        <div class="col-md-3">
          <el-form-item label="Password" prop="password">
            <el-input v-model="form.password"/>
          </el-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <el-form-item label="Email" prop="email">
            <el-input v-model="form.email"/>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Recovery Email" prop="recovery_email">
            <el-input v-model="form.recovery_email"/>
          </el-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <el-form-item label="GMB Link" prop="link">
            <el-input
              v-model="form.link"
              v-mask="'https\://m!aps\.google\.com/m!aps?cid=####################'"
              placeholder="https://maps.google.com/maps?cid=___________________"
              masked="true"
            />
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Review link" prop="review_link">
            <el-input v-model="form.review_link"/>
          </el-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <el-form-item label="Campaign" prop="campaign_id">
            <el-select
                v-model="form.campaign_id"
                filterable
            >
              <el-option
                  v-for="campaign in allCampaigns"
                  :key="campaign.id"
                  :label="campaign.name"
                  :value="campaign.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="Status" prop="status">
            <el-select
                v-model="form.status"
                filterable
            >
              <el-option
                  v-for="campaign in statusesList"
                  :key="campaign.id"
                  :label="campaign.name"
                  :value="campaign.id"
              />
            </el-select>
          </el-form-item>


          <el-form-item label="" prop="client_owned">
            <div class="switch-wrapper">
              <el-switch v-model="form.client_owned"/>
              <div class="switch-label">
                Client Owned
              </div>
            </div>
          </el-form-item>

        </div>
        <div class="col-md-6">
          <el-form-item label="Notes" prop="notes">
            <el-input
                v-model="form.notes"
                type="textarea"
                :rows="6"
            />
          </el-form-item>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="onSubmit">Save</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import modalMixin from '@/mixins/modalMixin'
import { mask } from "vue-the-mask";
const defaultFormValues = {
  id: null,
  title: '',
  address: '',
  website: '',
  phone: '',
  password: '',
  email: '',
  recovery_email: '',
  link: '',
  review_link: '',
  campaign_id: null,
  status: null,
  client_owned: false,
  notes: ''
}

export default {
  name: 'AddEditGMBDialog',
  directives: { mask },
  mixins: [modalMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validateLink = (rule, value, callback) => {
      const regExp = /https:\/\/maps\.google\.com\/maps\?cid=\d{15,20}/
      if (!regExp.test(value)) {
        callback(new Error('Wrong link format'));
      } else {
        callback();
      }
    };

    return {
      form: { ...defaultFormValues },
      originFormValues: { ...defaultFormValues },
      rules: {
        title: [
          { required: true, message: 'Please input category name', trigger: 'change' }
        ],
        link: [
          { required: true, message: 'Please input GMB link', trigger: 'change' },
          { validator: validateLink, trigger: 'blur' }
        ],
        status: [
          { required: true, message: 'Please select status', trigger: 'change' }
        ],
        campaign_id: [
          { required: true, message: 'Please select category', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapState('gmbs', [
      'activeGmb',
      'statusesList'
    ]),
    ...mapState('campaigns', [
      'allCampaigns'
    ]),
    isEdit() {
      return !!(this.activeGmb?.id)
    },
    isFormChanged() {
      return  JSON.stringify(this.originFormValues) !== JSON.stringify(this.form)
    }
  },
  watch: {
    activeGmb() {
      if (this.isEdit) {
        this.fillForm()
      }
    }
  },
  mounted() {
    this.fetchAllCampaigns()
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchAllCampaigns',
    ]),
    ...mapMutations('gmbs', [
      'setActiveGmb'
    ]),
    ...mapActions('gmbs', [
      'createGmb',
      'updateGmb'
    ]),
    fillForm() {
      this.form = { ...this.activeGmb }
      this.originFormValues = {...this.form}
    },
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.isEdit) {
            await this.updateGmb(this.form)
          } else {
            await this.createGmb(this.form)
          }
          this.$emit('refetch-data')
          this.closeModal()
        }
      })
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal, this.isFormChanged)
    },
    closeModal() {
      this.resetForm()
      this.setActiveGmb(null)
      this.$emit('close')
    },
    resetForm() {
      this.form = { ...defaultFormValues }
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 1000px;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}

.switch-wrapper {
  display: flex;
  align-items: center;
}

.switch-label {
  padding-left: 15px;
  line-height: 1.1;
}

</style>
