<template>
  <el-form
      :model="form"
      ref="form"
      label-position="top"
  >
    <el-dialog
        :visible="visible"
        :before-close="handleClose"
        title="Edit Campaign Note"
        width="94%"
    >
      <el-form-item label="Note" prop="note">
        <el-input v-model="form.note" type="textarea" :rows="10"/>
      </el-form-item>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="onSubmit">Save</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import modalMixin from '@/mixins/modalMixin'

const defaultFormValues = {
  id: null,
  note: null
}

export default {
  name: 'EditCampaignNoteDialog',
  mixins: [modalMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: { ...defaultFormValues }
    }
  },
  computed: {
    ...mapState('activity-log', [
      'activeCampaign'
    ])
  },
  watch: {
    activeCampaign() {
      this.fillForm()
    }
  },
  methods: {
    ...mapMutations('activity-log', [
      'setActiveCampaign'
    ]),
    ...mapActions('campaigns', [
      'updateCampaignNote'
    ]),
    fillForm() {
      this.form.id = this.activeCampaign?.id
      this.form.note = this.activeCampaign?.note
    },
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          await this.updateCampaignNote(this.form)
          this.$emit('refetch-data')
          this.closeModal()
        }
      })
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal)
    },
    closeModal() {
      this.resetForm()
      this.setActiveCampaign(null)
      this.$emit('close')
    },
    resetForm() {
      this.form = { ...defaultFormValues }
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 500px;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}
</style>
