<template>
  <tr :class="trClass">
    <td v-if="isColumnVisible('campaign')">{{ lead.campaign_name }}</td>
    <td v-if="isColumnVisible('lead_date')" class="text-center date-cell">{{ lead.lead_date }}</td>
    <td v-if="isColumnVisible('lead_type')" class="text-center">{{ lead.lead_type }}</td>
    <td v-if="isColumnVisible('tracking_phone_number') && isAdminOrSuperAdmin">
      <div class="phone-cell__inner">
        {{ lead.tracking_phone_number }}
      </div>
    </td>
    <td v-if="isColumnVisible('tracking_source') && isAdminOrSuperAdmin">{{ lead.tracking_source }}</td>
    <td v-if="isColumnVisible('lead_name')">{{ lead.lead_name }}</td>
    <td v-if="isColumnVisible('lead_location')">{{ lead.lead_location }}</td>

    <td v-if="isColumnVisible('lead_email')">
      <a :href="`mailto:${lead.lead_email}`">
        {{ lead.lead_email }}
      </a>
    </td>

    <td v-if="isColumnVisible('lead_phone')">
      <div class="phone-cell__inner">
        <a v-if="lead.lead_phone" :href="`tel:${lead.lead_phone}`">
          <i class="fas fa-phone-alt" />
          {{ lead.lead_phone }}
        </a>
        <el-tooltip
          v-if="lead.first_call"
          content="First Call"
          effect="dark"
          placement="right"
        >
          <div class="first-call-badge-wrapper" @click="$emit('filter', {'first_call': 1})">
            <div class="first-call-badge" />
          </div>
        </el-tooltip>
      </div>
    </td>

    <td v-if="isColumnVisible('message')" class="record-cell">
      <div v-if="lead.lead_type === 'Form'">
        <LeadMessage :lead="lead" />
      </div>
      <div v-if="lead.lead_type === 'Call' && !lead.recording">
        No recording
      </div>
      <div v-if="lead.lead_type === 'Call' && lead.recording">
        <LeadRecord :lead="lead" />
      </div>
    </td>

    <td v-if="isColumnVisible('related') && !isRelatedLeads">
      <el-button
        v-if="lead.has_related"
        size="mini"
        type="primary"
        @click="showRelatedLeads"
      >
        <i class="fas fa-link" />
        Related Leads
      </el-button>
    </td>

    <td v-if="isColumnVisible('notes')">
      <el-input
        v-if="isAdminOrSuperAdmin"
        v-model="lead.notes"
        autosize
        class="big-input"
        size="medium"
        type="textarea"
        @change="changeTextField($event, 'notes')"
      />
      <div v-else>
        {{ lead.notes }}
      </div>
    </td>

    <td v-if="isColumnVisible('tags') && user.permissions.show_lead_http_columns">
      <div v-if="isAdminOrSuperAdmin">
        <el-select
          :value="lead.tags.map(x=>x.name)"
          class="multiselect multiselect-tags"
          filterable
          multiple
          @change="changeMultiselectField($event, 'tags')"
        >
          <el-option
            v-for="tag in tagsList"
            value-key="name"
            :key="tag.name"
            :label="tag.name"
            :value="tag"
          />
        </el-select>
      </div>
      <div v-else>
        <div v-for="tag in lead.tags" :key="tag.name" class="tag">
          <i class="fas fa-hashtag" />
          {{ tag.name }}
        </div>
      </div>
    </td>

    <td v-if="isColumnVisible('confirmed')" class="text-center">
      <el-switch
        v-if="isAdminOrSuperAdmin"
        v-model="lead.confirmed"
        :active-value="1"
        :inactive-value="0"
        @change="changeTextField($event, 'confirmed')"
      />
      <div v-else>
        {{lead.confirmed ? 'Yes' : 'No'}}
      </div>
    </td>

    <td v-if="isColumnVisible('changers') && isAdminOrSuperAdmin && showLeadHttpColumns">
      <div v-for="changer in lead.changers" :key="changer.username" class="changer">
        <i class="fa fa-user-circle" />
        {{ changer.username }}
      </div>
    </td>

    <td v-if="isColumnVisible('client_progress')">
      <el-button-group>
        <el-button
          v-for="item in clientProgressList"
          :key="item.key"
          :title="`Set ${item.label}`"
          :type="item.key === lead.client_progress ? 'primary' : ''"
          size="mini"
          @click="changeClientProgress(item.key)"
        >
          {{ item.label }}
        </el-button>
      </el-button-group>
    </td>

    <td v-if="isColumnVisible('company_name')">
      <el-input
        v-model="lead.company_name"
        class="big-input"
        size="medium"
        @change="changeTextField($event, 'company_name')"
      />
    </td>

    <td v-if="isColumnVisible('client_notes')" class="client-notes-cell">
      <el-input
        v-model="lead.client_notes"
        autosize
        class="big-input"
        size="medium"
        type="textarea"
        @change="changeTextField($event, 'client_notes')"
      />
    </td>

    <td v-if="isColumnVisible('client_value')">
      <el-input
        :value="lead.client_value"
        class="small-input"
        size="medium"
        @change="changeTextField($event, 'client_value')"
        @input="val => lead.client_value = formatNumber(val)"
      />
    </td>

    <td v-if="isColumnVisible('client_profit')">
      <el-input
        :value="lead.client_profit"
        class="small-input"
        size="medium"
        @change="changeTextField($event, 'client_profit')"
        @input="val => lead.client_profit = formatNumber(val)"
      />
    </td>

    <td v-if="isColumnVisible('rating')">
      <div class="rating-wrapper">
        <el-rate
          :value="parseInt(lead.rating)"
          @change="changeRating"
          @input="val => lead.rating = val"
        />
      </div>
    </td>

    <td v-if="isColumnVisible('url') && isAdminOrSuperAdmin && showLeadHttpColumns" class="url-cell">{{ lead.last_url }}</td>
    <td v-if="isColumnVisible('referrer') && isAdminOrSuperAdmin && showLeadHttpColumns" class="url-cell">{{ lead.referrer }}</td>
    <td v-if="isColumnVisible('source') && isAdminOrSuperAdmin && showLeadHttpColumns" class="source-cell">{{ lead.source }}</td>
    <td v-if="isColumnVisible('keywords') && isAdminOrSuperAdmin && showLeadHttpColumns">{{ lead.keywords }}</td>
    <td v-if="isColumnVisible('gclid') && isAdminOrSuperAdmin && showLeadHttpColumns" class="url-cell">{{ lead.gclid }}</td>

    <td v-if="isColumnVisible('hide') && isAdminOrSuperAdmin" class="url-cell">
      <el-switch
        v-model="lead.hide"
        :active-value="1"
        :inactive-value="0"
        @change="changeTextField($event, 'hide')"
      />
    </td>

    <td v-if="isColumnVisible('action')">
      <div class="action-buttons-wrapper">
        <el-button
          v-if="isAdminOrSuperAdmin && (leadHasTag('Missed Call') || leadHasTag('Auto Missed Call'))"
          :loading="sendWarningLoading"
          size="mini"
          title="Archive Campaign"
          type="danger"
          @click="onSendWarning"
        >
          Send Warning
        </el-button>
        <el-button
          v-if="isAdminOrSuperAdmin && (leadHasTag('Missed Call') || leadHasTag('Auto Missed Call'))"
          :loading="sendNotificationLoading"
          size="mini"
          title="Archive Campaign"
          type="info"
          @click="onSendNotification"
        >
          Resend Notification
        </el-button>

        <el-button
          v-if="!isAdminOrSuperAdmin && !isLeadsArchive"
          size="mini"
          title="Archive"
          type="primary"
          @click="onArchive(lead)"
        >Archive</el-button>

        <el-button
          v-if="!isAdminOrSuperAdmin && isLeadsArchive"
          size="mini"
          title="Archive"
          type="primary"
          @click="onUnarchive(lead)"
        >Unarchive</el-button>
      </div>
    </td>
  </tr>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import LeadRecord from '@/components/leads/LeadRecord'
import LeadMessage from '@/components/leads/LeadMessage'

export default {
  name: 'LeadsListItem',
  components: {
    LeadMessage,
    LeadRecord,
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
    isRelatedLeads: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      sendWarningLoading: false,
      sendNotificationLoading: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('leads', [
      'clientProgressList',
      'tagsList',
    ]),
    ...mapGetters('auth', [
      'hiddenColumns',
      'isColumnVisible',
      'isAdminOrSuperAdmin',
      'showLeadHttpColumns',
    ]),
    trClass() {
      return {
        'tr-confirmed': this.lead.confirmed,
        'tr-danger': this.leadHasTag('Missed Call'),
        'tr-loading': this.loading,
      }
    },
    isLeadsArchive() {
      return this.$route.name === 'LeadsArchive'
    },
  },
  methods: {
    ...mapMutations('leads', [
      'setActiveLead',
    ]),

    ...mapActions('leads', [
      'updateLead',
      'resetClientProgress',
      'sendWarning',
      'sendNotification',
      'fetchRelatedLeads',
      'archiveLead',
      'unarchiveLead'
    ]),

    leadHasTag(key) {
      return this.lead.tags.some(tag => tag.name === key)
    },

    showRelatedLeads() {
      this.setActiveLead(this.lead)
      this.$emit('open-related-leads-dialog')
    },

    async changeClientProgress(progress_id) {
      this.loading = true
      if (progress_id === this.lead.client_progress) {
        await this.resetClientProgress(this.lead.id)
        this.lead.client_progress = null
      } else {
        await this.updateLead({
          id: this.lead.id,
          client_progress: progress_id,
        })
        this.lead.client_progress = progress_id
      }
      this.$emit('refetch-data')
      this.loading = false
    },

    async changeTextField(val, field_name) {
      this.loading = true
      await this.updateLead({
        id: this.lead.id,
        [field_name]: val,
      })
      this.$emit('refetch-data')
      this.loading = false
    },

    async changeMultiselectField(val, field_name) {
      this.loading = true
      const arr = val.map(x=>{
        return {name: x}
      })
      this.lead[field_name] = arr
      await this.updateLead({
        id: this.lead.id,
        [field_name]: arr,
      })
      this.$emit('refetch-data')
      this.loading = false
    },

    async changeRating(val) {
      this.loading = true
      await this.updateLead({
        id: this.lead.id,
        rating: val.toString(),
      })
      this.$emit('refetch-data')
      this.loading = false
    },

    formatNumber(value) {
      return value.split('').filter(x => /\d/.test(x)).join('')
    },

    async onSendWarning() {
      this.sendWarningLoading = true
      await this.sendWarning(this.lead.id)
      this.sendWarningLoading = false
    },

    async onSendNotification() {
      this.sendNotificationLoading = true
      await this.sendNotification(this.lead.id)
      this.sendNotificationLoading = false
    },

    async onArchive(lead) {
      this.loading = true
      await this.archiveLead(lead.id)
      this.$emit('refetch-data')
      this.loading = false
    },

    async onUnarchive(lead) {
      this.loading = true
      await this.unarchiveLead(lead.id)
      this.$emit('refetch-data')
      this.loading = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.date-cell {
  min-width: 100px;
}

.phone-cell__inner {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.first-call-badge-wrapper {
  padding: 8px;
  cursor: pointer;
}

.first-call-badge {
  background: $color-success;
  height: 6px;
  width: 6px;
  border-radius: 50%;
}

.table {
  td {
    vertical-align: top !important;
  }
}

.date-cell {
  width: 105px;
}

.record-cell {
  min-width: 400px;
}

.tr-danger {
  background: $color-danger-bg;
}

.tr-confirmed {
  background: $color-success-bg;
}

.tr-loading {
  opacity: 0.4;
}

.tag {
  white-space: nowrap;
  font-size: 12px;

  i {
    color: #b8b8b8;
  }
}

.source-cell {
  white-space: nowrap;
}

.changer {
  display: flex;
  align-items: center;
  white-space: nowrap;

  i {
    margin-right: 6px;
    color: #b8b8b8;
  }
}

.big-input {
  width: 250px;
}

.small-input {
  width: 100px;
}

.client-notes-cell {
  ::v-deep textarea {
    min-height: 36px !important;
  }
}

.el-rate {
  display: flex;

  ::v-deep .el-rate__icon {
    margin-right: 1px;
  }
}

.url-cell {
  max-width: 800px;
  min-width: 400px;
  word-break: break-all
}

.action-buttons-wrapper {
  width: 100%;

  .el-button {
    white-space: pre-wrap !important;
    display: flex;
    margin-left: 0;
    margin-bottom: 4px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    &:last-of-type {
      margin-bottom: 0;
    }

    ::v-deep span {
      margin-left: 0 !important;
    }

    ::v-deep .el-icon-loading {
      margin-bottom: 2px;
    }
  }
}

.btn-light-gray:not(.el-button--primary) {
  background: #fafafa;
}

.multiselect {
  width: 200px;
}

.multiselect-tags {
  min-width: 350px;
}

</style>
