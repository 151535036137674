<template>
  <div v-loading="loading">
    <div v-if="(list && list.length) || loading" class="table-wrapper">
      <table class="table">
        <thead>
        <tr>
          <th>
            <SortLabel
                label="URL"
                :order="$_sortingMixin_checkOrder('domain')"
                @click.native="$_sortingMixin_doSort('domain')"
            />
          </th>
          <th>
            <SortLabel
                label="Redirect"
                :order="$_sortingMixin_checkOrder('redirect_url')"
                @click.native="$_sortingMixin_doSort('redirect_url')"
            />
          </th>
          <th class="text-center">
            <SortLabel
                label="Status"
                :order="$_sortingMixin_checkOrder('live_status')"
                @click.native="$_sortingMixin_doSort('live_status')"
            />
          </th>
          <th>
            <SortLabel
                label="Registrar"
                :order="$_sortingMixin_checkOrder('registrar')"
                @click.native="$_sortingMixin_doSort('registrar')"
            />
          </th>
          <th>
            <SortLabel
                label="Registrant"
                :order="$_sortingMixin_checkOrder('registrant')"
                @click.native="$_sortingMixin_doSort('registrant')"
            />
          </th>
          <th class="text-center">
            <SortLabel
                label="Registration"
                :order="$_sortingMixin_checkOrder('registration')"
                @click.native="$_sortingMixin_doSort('registration')"
            />
          </th>
          <th class="text-center">
            <SortLabel
                label="Expiration"
                :order="$_sortingMixin_checkOrder('expiration')"
                @click.native="$_sortingMixin_doSort('expiration')"
            />
          </th>
          <th>
            <SortLabel
                label="Nameserver 1"
                :order="$_sortingMixin_checkOrder('nameserver_1')"
                @click.native="$_sortingMixin_doSort('nameserver_1')"
            />
          </th>
          <th>
            <SortLabel
                label="Nameserver 2"
                :order="$_sortingMixin_checkOrder('nameserver_2')"
                @click.native="$_sortingMixin_doSort('nameserver_2')"
            />
          </th>
          <th class="fixed-right-column text-center">
            Actions
          </th>
        </tr>
        </thead>

        <tbody v-if="list">
        <tr v-for="domain in list" :key="domain.id">
          <td>
            <a v-if="domain.domain" :href="`//${domain.domain}`" target="_blank" class="external-link">
              {{ domain.domain }} <i class="fas fa-external-link-alt"/>
            </a>
          </td>
          <td>
            <a v-if="domain.redirect_url" :href="`${domain.redirect_url}`" target="_blank" class="external-link">
              {{ domain.redirect_url }} <i class="fas fa-external-link-alt"/>
            </a>
          </td>
          <td class="text-center">
            <div :class="+domain.live_status ? 'text-success' : 'text-danger'">
              {{ getSiteStatusById(domain.live_status) }}
            </div>
          </td>
          <td>{{ domain.registrar }}</td>
          <td>{{ domain.registrant }}</td>
          <td class="text-center">{{ domain.registration }}</td>
          <td class="text-center">{{ domain.expiration }}</td>
          <td class="cell-ns">{{ domain.nameserver_1 }}</td>
          <td class="cell-ns">{{ domain.nameserver_2 }}</td>
          <td class="fixed-right-column table__action-column">
            <el-button-group>
              <el-button
                  type="danger"
                  size="mini"
                  title="Delete"
                  icon="el-icon-delete"
                  @click="handleDelete(domain)"
              />
            </el-button-group>
          </td>

        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import EmptyList from '@/components/global/EmptyList'
import SortLabel from '@/components/global/SortLabel'
import sortingMixin from '@/mixins/sortingMixin'

export default {
  name: 'RedirectDomainsList',
  mixins: [sortingMixin],
  components: {
    EmptyList,
    SortLabel
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState('network', [
      'redirectDomains'
    ]),
    ...mapGetters('sites', [
      'getSiteStatusById'
    ]),
    list() {
      return this.redirectDomains?.data
    }
  },
  methods: {
    ...mapActions('network', [
      'deleteRedirectDomain'
    ]),
    async handleDelete(domain) {
      try {
        const res = await this.$confirm('Are you sure you want to delete this additional domain? It will also be deleted from CPanel.')
        if (res) {
          await this.deleteRedirectDomain(domain.id)
          this.$emit('refetch-data')
        }
      } catch {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.table-wrapper {
  max-height: calc(100vh - 340px) !important;
}

.cell-ns {
  font-size: 13px;
  text-transform: lowercase;
}

</style>
