<template>
  <div>
    <div class="page-title-group">
      <h1>Campaign Map</h1>
    </div>

    <FilterPanel>
      <GmbsMapFilters @filter="doFilter"/>
    </FilterPanel>

    <GmbsMap ref="map" :current-category="category" @refetch-data="getGmbsMap"/>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FilterPanel from '@/components/global/FilterPanel'
import GmbsMapFilters from '@/components/gmbs-map/GmgsMapFilters'
import GmbsMap from '@/components/gmbs-map/GmbsMap'

export default {
  name: 'GMBsMapPage',
  metaInfo: {
    title: 'Campaign Map'
  },
  components: {
    GmbsMap,
    GmbsMapFilters,
    FilterPanel
  },
  data() {
    return {
      loading: false,
      filterObject: {},
      category: null,
    }
  },
  methods: {
    ...mapActions('gmbs', [
      'fetchGmbsMap'
    ]),
    ...mapActions('campaigns', [
      'fetchAllCampaignsAreas',
      'fetchCampaignCategoryById',
    ]),
    async getGmbsMap(bounds = {}) {
      this.filterObject = { ...this.filterObject, ...bounds }
      this.loading = true
      await Promise.all([
        this.fetchAllCampaignsAreas(this.filterObject),
        this.fetchGmbsMap(this.filterObject),
        new Promise( (resolve) => {
          if(this.filterObject.category_id) {
            this.fetchCampaignCategoryById(this.filterObject.category_id).then(res => {
              this.category = res
              resolve()
            })
          } else {
            this.category = null
            resolve()
          }
        })
      ])

      this.$refs.map.renderGeoObjects()
      this.loading = false
    },
    doFilter(filters) {
      this.filterObject = { ...this.filterObject, ...filters }
      this.getGmbsMap()
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .panel-body {
  flex-grow: 1
}

</style>
