<template>
  <div>
    <div class="page-title-group">
      <h1>GMB Manager</h1>

      <el-button
          type="info"
          @click="openManageAccountsDialog"
      >
        <i class="fa fa-user-circle"/>
        Manage Accounts
      </el-button>
    </div>

    <FilterPanel>
      <SearchFilter @filter="doFilter"/>
    </FilterPanel>

    <MainPanel>
      <div class="action-bar">
        <PageLimitSwitcher :active="filterObject.per_page" @click="setPageLimit"/>

        <el-pagination
            v-if="list.length"
            background
            layout="prev, pager, next"
            :page-size="filterObject.per_page"
            :current-page="filterObject.page"
            :total="list.total"
            hide-on-single-page
            @current-change="changePage"
        />

        <div class="table-total">
          Total Items: {{ list.data && list.data.length || '-' }}
        </div>
      </div>

      <GMBLocationsList
          :loading="loading"
          :list="list"
          @edit="openEditLocationDialog"
          @refetch-data="getGmbLocations"
          @sort="doSort"
      />
    </MainPanel>

    <EditLocationDialog
        :visible.sync="editLocationDialogVisible"
        @close="closeEditLocationDialog"
        @refetch-data="getGmbLocations"
    />

    <ManageAccountsModal
        :visible.sync="manageAccountsDialogVisible"
        @close="closeManageAccountsDialog"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MainPanel from '@/components/global/MainPanel'
import FilterPanel from '@/components/global/FilterPanel'
import SearchFilter from '@/components/redirect-domains/SearchFilter'
import PageLimitSwitcher from '@/components/global/PageLimitSwitcher'
import GMBLocationsList from '@/components/gmb-locations/GMBLocationsList'
import ManageAccountsModal from '@/components/gmb-locations/ManageAccountsModal'
import EditLocationDialog from '@/components/gmb-locations/EditLocationDialog'

export default {
  name: 'GMBManagerPage',
  metaInfo: {
    title: 'GMB Manager'
  },
  components: {
    EditLocationDialog,
    ManageAccountsModal,
    GMBLocationsList,
    PageLimitSwitcher,
    SearchFilter,
    FilterPanel,
    MainPanel
  },
  data() {
    return {
      loading: false,
      manageAccountsDialogVisible: false,
      editLocationDialogVisible: false,
      filterObject: {
        page: 1,
        per_page: 200
      }
    }
  },
  computed: {
    ...mapState('gmbs', {
      list: 'gmbLocations'
    })
  },
  mounted() {
    this.getGmbLocations()

    const code = this.$route.query?.code
    if (code) {
      this.storeAccesToken({ code })
      this.$router.replace({ 'query': null })
    }
  },
  methods: {
    ...mapActions('gmbs', [
      'fetchGmbLocations',
      'storeAccesToken'
    ]),
    async getGmbLocations() {
      this.loading = true
      await this.fetchGmbLocations(this.filterObject)
      this.loading = false
    },
    openManageAccountsDialog() {
      this.manageAccountsDialogVisible = true
    },
    closeManageAccountsDialog() {
      this.manageAccountsDialogVisible = false
    },
    openEditLocationDialog() {
      this.editLocationDialogVisible = true
    },
    closeEditLocationDialog() {
      this.editLocationDialogVisible = false
    },
    doFilter(filters) {
      this.filterObject = { ...this.filterObject, ...filters }
      this.getGmbLocations()
    },
    doSort(sort) {
      this.filterObject = { ...this.filterObject, ...sort }
      this.filterObject.page = 1
      this.getGmbLocations()
    },
    setPageLimit(val) {
      this.filterObject.per_page = val
      this.filterObject.page = 1
      this.getGmbLocations()
    },
    changePage(page) {
      this.filterObject.page = page
      this.getGmbLocations()
    }
  }
}
</script>
