<template>
  <div>
    <div class="mb-2">
      <el-checkbox
          v-model="allChecked"
          :indeterminate="isIndeterminate"
          @change="handleCheckAll"
      >
        Check all
      </el-checkbox>
    </div>

    <div class="checkbox-list mb-1">
      <el-checkbox-group
          v-model="checkedCampaigns"
          @change="handleCheck"
      >

        <el-checkbox
            v-for="campaign in campaigns"
            :label="campaign.id"
            :key="campaign.id"
        >
          {{ campaign.name }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'CampaignsAccess',
  props: {
    checkedCampaignsProp: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      checkedCampaigns: []
    }
  },
  computed: {
    ...mapState('campaigns', {
      campaigns: 'allCampaigns'
    }),
    isIndeterminate() {
      if (this.campaigns) {
        return this.checkedCampaigns.length > 0 && this.checkedCampaigns.length < this.campaigns.length
      }
      return false
    },
    allChecked: {
      get() {
        if (this.campaigns) {
          return this.checkedCampaigns.length === this.campaigns.length
        }
        return false
      },
      set(newValue) {
        return newValue
      }
    },
    transformedCategoriesArray() {
      const obj = {}
      this.checkedCampaigns.forEach(item => {
        obj[item] = 1
      })
      return obj
    }
  },
  mounted() {
    this.fetchAllCampaigns()
  },
  watch: {
    checkedCampaignsProp() {
      this.checkedCampaigns = []
      for (let key in this.checkedCampaignsProp) {
        if (this.checkedCampaignsProp[key] === 1) {
          this.checkedCampaigns.push(parseInt(key))
        }
      }
    }
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchAllCampaigns'
    ]),
    handleCheckAll(val) {
      this.checkedCampaigns = val ? this.campaigns.map(i => i.id) : []
      this.$emit('change', this.transformedCategoriesArray)
    },
    handleCheck() {
      this.$emit('change', this.transformedCategoriesArray)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
