<template>
  <el-dialog
    :visible="visible"
    title="Select Table Columns"
    width="94%"
    @close="closeModal"
  >
    <div class="columns-selector">
      <el-dropdown-item
        v-for="column in columns"
        :key="column.label"
        :class="{
          'dropdown-item-active': isColumnVisible(column.key),
          'hidden': column.isHiddenForCustomer && !isAdminOrSuperAdmin
        }"
        @click.native="toggleColumn(column.key)"
      >
        <i class="dropdown-item-icon-checked far fa-check-square" />
        <i class="dropdown-item-icon-unchecked far fa-square" />
        {{ column.label }}
      </el-dropdown-item>
    </div>
  </el-dialog>
</template>

<script>
import {mapActions, mapMutations, mapGetters} from 'vuex'

export default {
  name: 'ColumnSelectorDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {key: 'campaign', label: 'Campaign'},
        {key: 'lead_date', label: 'Date'},
        {key: 'lead_type', label: 'Type'},
        {key: 'tracking_phone_number', label: 'Tracking Phone', isHiddenForCustomer: true},
        {key: 'tracking_source', label: 'Tracking Source', isHiddenForCustomer: true},
        {key: 'lead_name', label: 'Name'},
        {key: 'lead_location', label: 'Location'},
        {key: 'lead_email', label: 'Email'},
        {key: 'lead_phone', label: 'Phone'},
        {key: 'message', label: 'Message'},
        {key: 'related', label: 'Related'},
        {key: 'notes', label: 'Notes'},
        {key: 'tags', label: 'Tags'},
        {key: 'confirmed', label: 'Verified'},
        {key: 'changers', label: 'Changers', isHiddenForCustomer: true},
        {key: 'client_progress', label: 'Progress'},
        {key: 'company_name', label: 'Company Name'},
        {key: 'client_notes', label: 'Client Notes'},
        {key: 'client_value', label: 'Value'},
        {key: 'client_profit', label: 'Profit'},
        {key: 'rating', label: 'Rating'},
        {key: 'url', label: 'URL', isHiddenForCustomer: true},
        {key: 'referrer', label: 'Referrer', isHiddenForCustomer: true},
        {key: 'source', label: 'Source', isHiddenForCustomer: true},
        {key: 'keywords', label: 'Keyword', isHiddenForCustomer: true},
        {key: 'gclid', label: 'gclid', isHiddenForCustomer: true},
        {key: 'hide', label: 'Is Hidden', isHiddenForCustomer: true},
        {key: 'action', label: 'Action'},
      ],
    }
  },
  computed: {
    ...mapGetters('auth', [
      'hiddenColumns',
      'isColumnVisible',
      'isAdminOrSuperAdmin',
    ]),
  },
  methods: {
    ...mapMutations('auth', [
      'toggleColumnVisibility',
    ]),
    ...mapActions('auth', [
      'saveColumns',
    ]),
    closeModal() {
      this.$emit('close')
    },
    async toggleColumn(key) {
      this.toggleColumnVisibility(key)
      await this.saveColumns({
        hidden_columns: Object.keys(this.hiddenColumns).join(','),
        view: 'leads.list',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

::v-deep .el-dialog {
  max-width: 500px;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}

.columns-selector {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.el-dropdown-menu__item {
  color: #c6c4c4;
  font-weight: 400;
  line-height: 1.2;
  padding: 7px 0 7px 20px;
  white-space: nowrap;

  &:active, &:focus {
    background: transparent;
    color: #c6c4c4;
  }

  &:hover {
    color: #c6c4c4 !important;
  }

  .dropdown-item-icon-checked {
    display: none;
  }

  .dropdown-item-icon-unchecked {
    display: inline-block;
  }
}

.dropdown-item-active {
  font-weight: 500;
  color: $color-primary;

  &:hover {
    color: $color-primary !important;
  }

  &:active, &:focus {
    color: $color-primary !important;
  }

  .dropdown-item-icon-checked {
    display: inline-block;
  }

  .dropdown-item-icon-unchecked {
    display: none;
  }
}

</style>
