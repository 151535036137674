<template>
  <div>
    <div class="page-title-group">
      <h1>Dashboard</h1>
    </div>

    <p class="lead" v-if="user">
      Hello {{ user.username }}

      <ul v-if="user.isSuperAdmin">
        <li v-for="(link, key) in user.tools_links" :key="key">
          <a :href="link.url" target="_blank">{{ link.label }}</a>
        </li>
      </ul>
    </p>
    <p class="lead" v-else>
      Hello guest, thank you for installing me.
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Dashboard',
  metaInfo: {
    title: 'Dashboard'
  },
  computed: {
    ...mapState('auth', [
      'user'
    ])
  },
}
</script>
