<template>
  <el-form
      label-position="top"
      class="filter-form"
  >
    <el-form-item label="URL">
      <el-input v-model="filters.url" v-debounce="doFilter"/>
    </el-form-item>

    <el-form-item label="IP">
      <el-input v-model="filters.ip" v-debounce="doFilter"/>
    </el-form-item>

    <el-form-item label="Campaign">
      <el-select
          v-model="filters.campaign_id"
          filterable
          class="multiselect"
          @change="doFilter"
      >
        <el-option
            v-for="campaign in allCampaigns"
            :key="campaign.id"
            :label="campaign.name"
            :value="campaign.id"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Status">
      <el-select
          v-model="filters.status"
          class="multiselect multiselect--status"
          @change="doFilter"
      >
        <el-option
            v-for="status in siteStatusList"
            :key="status.id"
            :label="status.label"
            :value="status.id"
        />
      </el-select>
    </el-form-item>

    <el-button
        v-if="isFiltersChanged"
        type="gray"
        @click="clearFilters"
    >
      Reset filters
    </el-button>
  </el-form>
</template>

<script>
import { mapState } from 'vuex'

const defaultValues = {
  url: '',
  campaign_id: null,
  ip: '',
  status: null
}

export default {
  name: 'ClientSitesFilters',
  data() {
    return {
      originFilters: {...defaultValues},
      filters: { ...defaultValues }
    }
  },
  created() {
    this.applyIncomingParams()
  },
  computed: {
    ...mapState('sites', [
      'siteStatusList'
    ]),
    ...mapState('campaigns', [
      'allCampaigns'
    ]),
    isFiltersChanged() {
      return JSON.stringify(this.originFilters) !== JSON.stringify(this.filters)
    }
  },
  methods: {
    async doFilter() {
      this.$emit('filter', this.filters)
    },
    clearFilters() {
      this.filters = { ...defaultValues }
      this.doFilter()
    },
    applyIncomingParams() {
      const params = this.$route?.params
      if (params?.ip) {
        this.filters.ip = params?.ip
        this.doFilter()
      }
      if (params?.campaign_id) {
        this.filters.campaign_id = params?.campaign_id
        this.doFilter()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.multiselect {
  min-width: 180px;

  &.multiselect--status {
    width: 100px;
    min-width: 100px;
  }
}
</style>
