<template>
  <div v-loading="loading">
    <div v-if="(list && list.length) || loading" class="table-wrapper">
      <table class="table">
        <thead>
        <tr>
          <th>
            Category Name
          </th>
          <th class="text-center">
            Campaigns
          </th>
          <th class="fixed-right-column text-center">Actions</th>
        </tr>
        </thead>

        <tbody v-if="list">
        <tr v-for="category in list" :key="category.id">
          <td>
            <div class="category-name-wrapper">
              <div v-if="category.color" class="color-example" :style="`background:${category.color}`"></div>
              {{ category.name }}
            </div>
          </td>
          <td class="text-center">
            <router-link
                v-if="category.campaigns_count"
                :to="{name: 'Campaigns', query: {campaign_category_id: category.id}}"
                class="external-link"
            >
              {{ category.campaigns_count }} <i class="fas fa-external-link-alt"></i>
            </router-link>
            <div v-else>
              {{ category.campaigns_count }}
            </div>
          </td>

          <td class="fixed-right-column table__action-column">
            <el-button-group>
              <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  title="Edit"
                  @click="handleEdit(category)"
              />

              <el-button
                  type="danger"
                  size="mini"
                  title="Delete"
                  icon="el-icon-delete"
                  @click="handleDelete(category)"
              />
            </el-button-group>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else/>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import EmptyList from '@/components/global/EmptyList'

export default {
  name: 'CampaignCategoriesList',
  components: {
    EmptyList
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState('campaigns', [
      'campaignCategories'
    ]),
    list() {
      return this.campaignCategories
    }
  },
  methods: {
    ...mapMutations('campaigns', [
      'setActiveCampaignCategory'
    ]),
    ...mapActions('campaigns', [
      'deleteCampaignCategory'
    ]),
    async handleDelete(category) {
      try {
        const res = await this.$confirm('Are you sure to remove this category?')
        if (res) {
          await this.deleteCampaignCategory(category.id)
          this.$emit('refetch-data')
        }
      } catch {}
    },
    async handleEdit(category) {
      this.$emit('edit')
      this.setActiveCampaignCategory(category)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.table-wrapper {
  max-height: calc(100vh - 230px) !important;
}

.color-example {
  height: 30px;
  width: 30px;
  background: #000;
  margin-right: 15px;
  border-radius: 2px;
}

.category-name-wrapper {
  display: flex;
  align-items: center;
}

</style>
