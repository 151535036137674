export default {
	methods: {
		async $_modalMixin_closeConfirmation(callback, condition = true) {
			try {
				if(condition) {
					await this.$confirm('Are you sure to close this dialog?', '', { type: 'warning' })
					callback()
				} else {
					callback()
				}
			} catch {
				console.log('canceled')
			}
		}
	}
}
