<template>
  <el-form
    ref="form"
    :model="form"
    label-position="top"
  >
    <el-dialog
      :before-close="handleClose"
      title="Edit Blacklist"
      :visible="visible"
      width="94%"
    >
      <el-form-item prop="keywords">
        <el-input
          v-model="form.keywords"
          type="textarea"
          rows="12"
        />
      </el-form-item>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="onSubmit">Save</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import modalMixin from '@/mixins/modalMixin'

const defaultFormValues = {
  keywords: null,
}

export default {
  name: 'BlacklistDialog',
  mixins: [modalMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {...defaultFormValues},
      originFormValues: {...defaultFormValues},
    }
  },
  computed: {
    ...mapState('leads', [
      'blacklist',
    ]),
    ...mapGetters('leads', [
      'blacklistFormatted',
    ]),
    isFormChanged() {
      return JSON.stringify(this.originFormValues) !== JSON.stringify(this.form)
    },
  },
  async created() {
    await this.fetchBlacklist()
    this.fillForm()
  },
  methods: {
    ...mapActions('leads', [
      'fetchBlacklist',
      'updateBlacklist',
    ]),
    fillForm() {
      this.form.keywords = this.blacklistFormatted
      this.originFormValues = {...this.form}
    },
    onSubmit() {
      try {
        this.updateBlacklist(this.form)
        this.$emit('refetch-data')
        this.closeModal()
      } catch {}
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal, this.isFormChanged)
    },
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 500px;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}
</style>
