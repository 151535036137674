<template>
  <div>
    <div v-if="gmbLog.total">
      <div class="action-bar action-bar--without-paginator">
        <PageLimitSwitcher :active="filterObject.per_page" @click="setPageLimit"/>

        <div class="table-total">
          Total Items: {{ gmbLog && gmbLog.total }}
        </div>
      </div>

      <el-pagination
          background
          layout="prev, pager, next"
          :page-size="filterObject.per_page"
          :current-page="filterObject.page"
          :total="gmbLog.total"
          hide-on-single-page
          @current-change="changePage"
      />
    </div>

    <GmbLogList :loading="loading"/>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PageLimitSwitcher from '@/components/global/PageLimitSwitcher'
import GmbLogList from '@/components/gmbs/GmbLogList'

export default {
  name: 'GmbLog',
  components: {
    GmbLogList,
    PageLimitSwitcher
  },
  data() {
    return {
      loading: false,
      filterObject: {
        page: 1,
        per_page: 200
      }
    }
  },
  computed: {
    ...mapState('gmbs', [
      'activeGmb',
      'gmbLog'
    ])
  },
  watch: {
    activeGmb() {
      if (this.activeGmb) {
        this.getLog()
      }
    }
  },
  methods: {
    ...mapActions('gmbs', [
      'fetchGmbLog'
    ]),
    async getLog() {
      this.loading = true
      this.filterObject.id = this.activeGmb.id
      await this.fetchGmbLog(this.filterObject)
      this.loading = false
    },
    setPageLimit(val) {
      this.filterObject.per_page = val
      this.filterObject.page = 1
      this.getLog()
    },
    changePage(page) {
      this.filterObject.page = page
      this.getLog()
    }
  }
}
</script>
