<template>
  <div class="page-limit-switcher">
    <div class="page-limit-switcher__label">
      Per page:
    </div>
    <div
        v-for="variant in variants"
        class="page-limit-switcher__item"
        :class="{'page-limit-switcher__item--active': variant === active}"
        :key="variant"
        @click="$emit('click', variant)"
    >
      {{variant}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLimitSwitcher',
  props: {
    variants: {
      type: Array,
      default() {
        return [50, 100, 200]
      }
    },
    active: {
      type: Number,
      default: 50
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.page-limit-switcher {
  display: flex;
}

.page-limit-switcher__label {
  font-weight: bold;
  margin-right: 10px;
}

.page-limit-switcher__item {
  position: relative;
  padding-right: 5px;
  padding-left: 5px;
  color: #ddd;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: $color-accent;
  }
}

.page-limit-switcher__item--active {
  color: $color-accent;
  border-bottom: 2px solid $color-accent;
  line-height: 1.2;
  position: relative;
  top: 2px;
}
</style>
