import api from '@/utils/api'
import {Message, MessageBox} from 'element-ui'

export default {
  state: () => ({
    gmbs: [],
    activeGmb: null,
    gmbLog: [],
    statusesList: [],
    gmbsMap: [],
    gmbLocations: [],
    activeGMBLocation: null,
    GMBAccounts: [],
    GMBCategories: [],
  }),
  mutations: {
    setGmbs(state, payload) {
      state.gmbs = payload
    },

    setActiveGmb(state, payload) {
      state.activeGmb = payload
    },

    setClientOwned(state, id) {
      state.gmbs.data = state.gmbs.data.map(item => {
        if (item.id === id) {
          item.client.client_owned = !item.client.client_owned
        }
        return item
      })
    },

    setClientLocation(state, id) {
      state.gmbs.data = state.gmbs.data.map(item => {
        if (item.id === id) {
          item.client.is_client_location = !item.client.is_client_location
        }
        return item
      })
    },

    setGmbLog(state, payload) {
      state.gmbLog = payload
    },

    setStatusesList(state, payload) {
      state.statusesList = payload
    },

    setGmbsMap(state, payload) {
      state.gmbsMap = Object.freeze(payload)
    },

    setGmbLocations(state, payload) {
      state.gmbLocations = payload
    },

    setActiveGMBLocation(state, payload) {
      state.activeGMBLocation = payload
    },

    setGmbAccounts(state, payload) {
      state.GMBAccounts = payload
    },

    removeGMBAccount(state, google_account_id) {
      state.GMBAccounts = state.GMBAccounts.filter(item => item.id !== google_account_id)
    },

    setGmbCategories(state, payload) {
      state.GMBCategories = payload
    },
  },
  getters: {
    getStatusById(state) {
      return (id) => {
        return state.statusesList.find(item => item.id === id).name || null
      }
    },
  },
  actions: {
    async fetchGmbs({commit}, params) {
      const result = await api.http.get('campaign-gmb', {params})
      commit('setGmbs', result?.data?.campaign_gmbs)
    },

    async createGmb(_, form) {
      await api.http.post('campaign-gmb', form)
      Message.success('Campaign GMB was created successfully')
    },

    async updateGmb(_, form) {
      await api.http.put(`campaign-gmb/${form.id}`, form)
      Message.success('Campaign GMB was changed successfully')
    },

    async deleteGmb(_, id) {
      try {
        const res = await MessageBox.confirm('Are you sure you want to delete this item?')
        if (res) {
          await api.http.delete(`campaign-gmb/${id}`)
          Message.success('Campaign GMB was deleted successfully')
          return true
        }
      } catch {
      }
    },

    async changeClientOwned({commit}, id) {
      await api.http.post(`campaign-gmb/${id}/toggle-client-owned`)
      commit('setClientOwned')
    },

    async changeClientLocation({commit}, id) {
      await api.http.post(`campaign-gmb/${id}/toggle-client-location`)
      commit('setClientLocation')
    },

    async fetchGmbLog({commit}, params) {
      const result = await api.http.get(`campaign-gmb/${params.id}/logs`, {params})
      commit('setGmbLog', result?.data)
    },

    async fetchStatusesList({commit}) {
      const result = await api.http.get(`campaign-gmb/statuses-list`)
      commit('setStatusesList', result?.data)
    },

    async fetchGmbsMap({commit}, params) {
      const result = await api.http.get('campaign-gmb/map', {params})
      commit('setGmbsMap', result?.data?.data)
    },

    async fetchGmbLocations({commit}, params) {
      const result = await api.http.get('gmb/locations', {params})
      commit('setGmbLocations', result?.data)
    },

    async fetchGmbAccounts({commit}) {
      const result = await api.http.get('gmb/auth/accounts')
      commit('setGmbAccounts', result?.data?.data)
    },

    async linkNewAccount() {
      const result = await api.http.get('gmb/get_auth_url')
      document.location = result?.data?.auth_url
    },

    async unlinkAccount({commit}, google_account_id) {
      await api.http.post(`gmb/auth/${google_account_id}/unlink`)
      Message.success('Account was unlinked successfully')
      commit('removeGMBAccount', google_account_id)
    },

    async updateGMBLocation(_, form) {
      await api.http.post(`gmb/locations/${form.id}`, form)
      Message.success('GMB location was changed successfully')
    },

    async storeAccesToken({dispatch}, params) {
      await api.http.post(`gmb/store_access_token`, params)
      dispatch('fetchGmbAccounts')
      await dispatch('fetchGmbLocations', {force: true})
      Message.success('Account was linked successfully')
    },

    async fetchGmbCategories({state, commit}, id) {
      if (state.GMBCategories.length > 0) return
      const result = await api.http.get(`gmb/locations/${id}/available_categories`, {
        headers: {
          Accept: 'application/json',
        },
      })
      commit('setGmbCategories', result?.data)
    },
  },
  namespaced: true,
}
