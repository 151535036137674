<template>
  <div>
    <div class="page-title-group">
      <h1>Related Leads</h1>

      <div class="el-button-group">
        <el-button @click="openColumnSelectorDialog">
          <i class="fas fa-border-all" />
          Select Table Columns
        </el-button>

<!--        <el-button-->
<!--          :loading="exportLoading"-->
<!--          @click="doExport"-->
<!--        >-->
<!--          <i class="fas fa-cloud-download-alt" />-->
<!--          Export-->
<!--        </el-button>-->

        <el-button @click="openBlacklistDialog">
          <i class="fas fa-comment-slash" />
          Blacklist
        </el-button>

        <el-button @click="openSpammersDialog">
          <i class="fas fa-users-slash" />
          Spammers
        </el-button>
      </div>
    </div>

    <FilterPanel>
      <LeadsFilters
        ref="filterPanel"
        :defaultDataValues="defaultDataValues"
        is-extra-filters-hidden
        @filter="doFilter"
      />
    </FilterPanel>

    <MainPanel>
      <div class="action-bar">
        <PageLimitSwitcher :active="filterObject.per_page" @click="setPageLimit" />

        <el-pagination
          v-if="leads"
          :current-page="filterObject.page"
          :page-size="filterObject.per_page"
          :total="leads.meta.total"
          background
          hide-on-single-page
          layout="prev, pager, next"
          @current-change="changePage"
        />

        <div v-if="leads" class="table-total">
          Total Items: {{ leads.meta && leads.meta.total || '-' }}
        </div>
      </div>

      <LeadsList
        :loading="loading"
        :list="leads && leads.data"
        is-global-related-leads
        @open-related-leads-dialog="openRelatedLeadsDialog"
        @filter="triggerFilter"
        @sort="doSort"
        @refetch-data="getLeads(true)"
      />

    </MainPanel>

    <ColumnSelectorDialog
      :visible.sync="columnSelectorDialogVisible"
      @close="closeColumnSelectorDialog"
    />

    <RelatedLeadsDialog
      :visible.sync="relatedLeadsDialogVisible"
      @close="closeRelatedLeadsDialog"
    />

    <BlacklistDialog
      :visible.sync="blacklistDialogVisible"
      @close="closeBlacklistDialog"
      @refetch-data="getLeads(true)"
    />

    <SpammersDialog
      :visible.sync="spammersDialogVisible"
      @close="closeSpammersDialog"
    />

  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {format, sub} from 'date-fns'
import MainPanel from '@/components/global/MainPanel'
import FilterPanel from '@/components/global/FilterPanel'
import LeadsFilters from '@/components/leads/LeadsFilters'
import LeadsList from '@/components/leads/LeadsList'
import PageLimitSwitcher from '@/components/global/PageLimitSwitcher'
import BlacklistDialog from '@/components/leads/BlacklistDialog'
import ColumnSelectorDialog from '@/components/leads/ColumnSelectorDialog'
import SpammersDialog from '@/components/leads/SpammersDialog'
import RelatedLeadsDialog from '@/components/leads/RelatedLeadsDialog'

const defaultDataValues = {
  startDate: format(sub(Date.now(), {months: 1}), 'yyyy-MM-dd'),
  endDate: format(Date.now(), 'yyyy-MM-dd'),
}

export default {
  name: 'RelatedLeadsPage',
  metaInfo: {
    title: 'Leads',
  },
  components: {
    RelatedLeadsDialog,
    ColumnSelectorDialog,
    SpammersDialog,
    BlacklistDialog,
    PageLimitSwitcher,
    LeadsList,
    LeadsFilters,
    FilterPanel,
    MainPanel,
  },
  data() {
    return {
      loading: false,
      exportLoading: false,
      columnSelectorDialogVisible: false,
      blacklistDialogVisible: false,
      relatedLeadsDialogVisible: false,
      spammersDialogVisible: false,
      defaultDataValues,
      filterObject: {
        page: 1,
        per_page: 50,
        sort: 'lead_date',
        order: 'desc',
        date_from: defaultDataValues.startDate,
        date_to: defaultDataValues.endDate,
      },
    }
  },
  computed: {
    ...mapGetters('auth', [
      'hiddenColumns',
    ]),
    ...mapState('leads', {
      leads: 'globalRelatedLeads',
    }),
  },
  mounted() {
    this.getLeads()
  },
  methods: {
    ...mapActions('leads', [
      'fetchGlobalRelatedLeads',
      'exportLeads'
    ]),
    openColumnSelectorDialog() {
      this.columnSelectorDialogVisible = true
    },
    closeColumnSelectorDialog() {
      this.columnSelectorDialogVisible = false
    },
    openBlacklistDialog() {
      this.blacklistDialogVisible = true
    },
    closeBlacklistDialog() {
      this.blacklistDialogVisible = false
    },
    openSpammersDialog() {
      this.spammersDialogVisible = true
    },
    closeSpammersDialog() {
      this.spammersDialogVisible = false
    },
    openRelatedLeadsDialog() {
      this.relatedLeadsDialogVisible = true
    },
    closeRelatedLeadsDialog() {
      this.relatedLeadsDialogVisible = false
    },
    async getLeads(isSilent = false) {
      this.loading = !isSilent
      await this.fetchGlobalRelatedLeads(this.filterObject)
      this.loading = false
    },
    async doExport() {
      this.exportLoading = true
      await this.exportLeads(this.filterObject)
      this.exportLoading = false
    },
    doFilter(filters) {
      this.filterObject = {...this.filterObject, ...filters}
      this.filterObject.date_from = filters.dateRange[0]
      this.filterObject.date_to = filters.dateRange[1]
      this.getLeads()
    },
    doSort(sort) {
      this.filterObject = {...this.filterObject, ...sort}
      this.filterObject.page = 1
      this.getLeads()
    },
    setPageLimit(val) {
      this.filterObject.per_page = val
      this.filterObject.page = 1
      this.getLeads()
    },
    changePage(page) {
      this.filterObject.page = page
      this.getLeads()
    },
    triggerFilter(data) {
      this.$refs.filterPanel.triggerFilter(data)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

</style>
