<template>
  <div>
    <div class="action-bar">
      <PageLimitSwitcher :active="filterObject.per_page" @click="setPageLimit" />

      <el-pagination
        v-if="campaigns"
        :current-page="filterObject.page"
        :page-size="filterObject.per_page"
        :total="campaigns.meta.total"
        background
        hide-on-single-page
        layout="prev, pager, next"
        @current-change="changePage"
      />

      <div class="table-total">
        Total Campaigns: {{ summary && summary.total || '-' }}
      </div>
    </div>

    <div v-loading="loading">
      <div class="table-wrapper">
        <table class="table">
          <thead>
          <tr>
            <th class="fixed-left-column">
              <SortLabel
                :order="$_sortingMixin_checkOrder('name')"
                label="Name"
                @click.native="doSort('name')"
              />
            </th>
            <th>
              <SortLabel
                :order="$_sortingMixin_checkOrder('campaigncategory.name')"
                label="Category"
                @click.native="doSort('campaigncategory.name')"
              />
            </th>
            <th>
              <SortLabel
                :order="$_sortingMixin_checkOrder('client.name')"
                label="Client"
                @click.native="doSort('client.name')"
              />
            </th>
            <th>
              <SortLabel
                :order="$_sortingMixin_checkOrder('client_sites')"
                label="Client Sites"
                @click.native="doSort('client_sites')"
              />
            </th>
            <th>
              <SortLabel
                :order="$_sortingMixin_checkOrder('manager')"
                label="Manager"
                @click.native="doSort('manager')"
              />
            </th>
            <th class="text-center">
              <SortLabel
                :order="$_sortingMixin_checkOrder('start_date')"
                label="Start Date"
                @click.native="doSort('start_date')"
              />
            </th>
            <th class="text-center">
              <SortLabel
                :order="$_sortingMixin_checkOrder('due_date')"
                label="Due Date"
                @click.native="doSort('due_date')"
              />
            </th>
            <th class="text-center">
              <SortLabel
                :order="$_sortingMixin_checkOrder('current_target')"
                label="Current Target"
                @click.native="doSort('current_target')"
              />
            </th>
            <th class="text-center">
              <SortLabel
                :order="$_sortingMixin_checkOrder('initial_target')"
                label="Initial Target"
                @click.native="doSort('initial_target')"
              />
            </th>
            <th class="text-center">
              <SortLabel
                :order="$_sortingMixin_checkOrder('month_3_target')"
                label="3 Month Target"
                @click.native="doSort('month_3_target')"
              />
            </th>
            <th class="text-center">
              <SortLabel
                :order="$_sortingMixin_checkOrder('month_6_target')"
                label="6 Month Target"
                @click.native="doSort('month_6_target')"
              />
            </th>
            <th class="text-center">
              <SortLabel
                :order="$_sortingMixin_checkOrder('month_9_target')"
                label="9 Month Target"
                @click.native="doSort('month_9_target')"
              />
            </th>
            <th class="text-center">
              <SortLabel
                :order="$_sortingMixin_checkOrder('month_12_target')"
                label="12 Month Target"
                @click.native="doSort('month_12_target')"
              />
            </th>
            <th class="text-center">
              <SortLabel
                :order="$_sortingMixin_checkOrder('service_fee')"
                label="Service Fee"
                @click.native="doSort('service_fee')"
              />
            </th>
            <th class="text-center">
              <SortLabel
                :order="$_sortingMixin_checkOrder('approved_ppc')"
                label="Approved PPC"
                @click.native="doSort('approved_ppc')"
              />
            </th>
            <th class="text-center">
              <SortLabel
                :order="$_sortingMixin_checkOrder('ppc')"
                label="PPC %"
                @click.native="doSort('ppc')"
              />
            </th>
            <th class="text-center">
              <SortLabel
                :order="$_sortingMixin_checkOrder('net')"
                label="Net"
                @click.native="doSort('net')"
              />
            </th>
            <th class="text-center">
              <SortLabel
                :order="$_sortingMixin_checkOrder('gmbs_count')"
                label="GMBs"
                @click.native="doSort('gmbs_count')"
              />
            </th>
            <th class="fixed-right-column text-center">Actions</th>
          </tr>
          </thead>
          <tbody v-if="campaigns">
          <tr
            v-for="(campaign, index) in campaigns && campaigns.data"
            :key="`${campaign.id}-${index}`"
            :class="{'disactivated': (campaign.archived && !isArchived) || (!campaign.archived && isArchived)}"
          >
            <td class="fixed-left-column">{{ campaign.name }}</td>
            <td>
              <div v-for="category in campaign.categories" :key="category.id" class="category-wrapper">
                <div :style="`background: ${category.color}`" class="category-marker" />
                {{ category.name }}
              </div>
            </td>
            <td>{{ campaign.client && campaign.client.name }}</td>
            <td>
              <template v-if="campaign.client_sites.length">
                <a v-for="site in campaign.client_sites"
                   :href="`//${site.url}`"
                   :key="site.id"
                   class="show client-website"
                   target="_blank"
                >
                  {{ site.url }}
                </a>
              </template>
            </td>
            <td>{{ campaign.manager }}</td>
            <td class="text-center">{{ $_formatterMixin_formatDate(campaign.start_date) }}</td>
            <td class="text-center">{{ campaign.due_date }}</td>
            <td class="text-center">{{ campaign.targets.current_target }}</td>
            <td class="text-center">{{ campaign.targets.initial_target }}</td>
            <td class="text-center">{{ campaign.targets.month_3_target }}</td>
            <td class="text-center">{{ campaign.targets.month_6_target }}</td>
            <td class="text-center">{{ campaign.targets.month_9_target }}</td>
            <td class="text-center">{{ campaign.targets.month_12_target }}</td>
            <td class="text-center">{{ $_formatterMixin_formatCurrency(campaign.service_fee) }}</td>
            <td class="text-center">{{ $_formatterMixin_formatCurrency(campaign.approved_ppc) }}</td>
            <td class="text-center">{{ $_formatterMixin_formatPercent(campaign.ppc) }}</td>
            <td class="text-center">{{ $_formatterMixin_formatCurrency(campaign.net) }}</td>
            <td class="text-center">{{ campaign.gmbs_count }}</td>

            <td class="fixed-right-column table__action-column">
              <el-button-group>
                <el-button
                  icon="el-icon-edit"
                  size="mini"
                  title="Edit Campaign"
                  type="primary"
                  @click="editCampaign(campaign)"
                >
                  Edit
                </el-button>
                <el-button
                  icon="el-icon-date"
                  size="mini"
                  title="Edit Working Hours"
                  @click="editWorkingHours(campaign)"
                >
                  Working hours
                </el-button>

                <el-button
                  icon="el-icon-map-location"
                  size="mini"
                  title="Edit Campaign Area"
                  type="success"
                  @click="editArea(campaign)"
                >
                  Map
                </el-button>

                <el-button
                  v-if="campaign.archived === 0"
                  icon="el-icon-download"
                  size="mini"
                  title="Archive Campaign"
                  type="danger"
                  @click="onArchive(campaign)"
                >
                  Archive
                </el-button>

                <el-button
                  v-if="campaign.archived === 1"
                  icon="el-icon-upload2"
                  size="mini"
                  title="Restore Campaign"
                  type="default"
                  @click="onRestore(campaign)"
                >
                  Restore
                </el-button>

              </el-button-group>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import formatterMixin from '../../mixins/formatterMixin'
import SortLabel from '@/components/global/SortLabel'
import sortingMixin from '@/mixins/sortingMixin'
import PageLimitSwitcher from '@/components/global/PageLimitSwitcher'

export default {
  name: 'CampaignsList',
  mixins: [formatterMixin, sortingMixin],
  components: {
    PageLimitSwitcher,
    SortLabel,
  },
  props: {
    isArchived: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      defaultSortBy: 'due_date',
      filterObject: {
        page: 1,
        per_page: 50,
        sort: 'due_date',
        order: 'asc',
        campaign_category_id: null,
      },
    }
  },
  computed: {
    ...mapState('campaigns', {
      activeCampaigns: state => state.activeCampaigns,
      archivedCampaigns: state => state.archivedCampaigns,
      activeCampaignsSummary: state => state.activeCampaignsSummary,
      archivedCampaignsSummary: state => state.archivedCampaignsSummary
    }),
    campaigns() {
      return this.isArchived ? this.archivedCampaigns : this.activeCampaigns
    },
    summary() {
      return this.isArchived ? this.archivedCampaignsSummary : this.activeCampaignsSummary
    }
  },
  mounted() {
    this.attachGetParams()
    this.getCampaigns()
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchActiveCampaigns',
      'fetchArchivedCampaigns',
      'archiveCampaign',
      'restoreCampaign',
    ]),
    async getCampaigns() {
      this.loading = true
      if (this.isArchived) {
        await this.fetchArchivedCampaigns(this.filterObject)
      } else {
        await this.fetchActiveCampaigns(this.filterObject)
      }
      this.loading = false
    },
    editCampaign(campaign) {
      this.$emit('edit-campaign', campaign)
    },
    editArea(campaign) {
      this.$emit('edit-area', campaign)
    },
    editWorkingHours(campaign) {
      this.$emit('edit-working-hours', campaign)
    },
    async onArchive(campaign) {
      await this.archiveCampaign(campaign)
      await this.fetchCampaignsInOtherTab()
    },
    async onRestore(campaign) {
      await this.restoreCampaign(campaign)
      await this.fetchCampaignsInOtherTab()
    },
    fetchCampaignsInOtherTab() {
      if (this.isArchived) {
        this.fetchActiveCampaigns()
      } else {
        this.fetchArchivedCampaigns()
      }
    },
    doSort(sortBy) {
      this.$_sortingMixin_prepareSort(sortBy, this.defaultSortBy)
      this.filterObject.sort = this.sortingMixin_sortBy
      this.filterObject.order = this.sortingMixin_order
      this.filterObject.page = 1
      this.getCampaigns()
    },
    doFilter(filterBy) {
      this.filterObject = { ...this.filterObject, ...filterBy }
      this.getCampaigns()
    },
    setPageLimit(val) {
      this.filterObject.per_page = val
      this.filterObject.page = 1
      this.getCampaigns()
    },
    changePage(page) {
      this.filterObject.page = page
      this.getCampaigns()
    },
    attachGetParams() {
      this.filterObject.campaign_category_id = this.$route.query?.campaign_category_id
    }
  },
}
</script>

<style lang="scss" scoped>

.table-wrapper {
  max-height: calc(100vh - 380px) !important;
}

.category-wrapper {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: nowrap;
}

.category-marker {
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin-right: 8px;
  border-radius: 5px;
}

</style>
