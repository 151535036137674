<template>
  <div
      class="sort-label"
      :class="{
        'sort-asc': order === 'asc',
        'sort-desc': order === 'desc'
      }"
  >
    <div class="sort-label__label">
      {{ label }}
    </div>
    <div class="sort-label__icon">
      <i class="fas fa-sort-up"></i>
      <i class="fas fa-sort-down"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SortLabel',
  props: {
    label: {
      type: String,
      required: true
    },
    order: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>

.sort-label {
  color: #337ab7;
  cursor: pointer;
  display: inline-flex;
  align-items: flex-end;
  font-weight: 500;
  font-size: 13px;

  &:hover {
    color: #194a72;
  }
}

.sort-label__label {
  margin-right: 5px;
}

.fas {
  color: #d0d0d0;
  position: absolute;
}

.sort-asc {
  .fa-sort-up {
    color: #fc4f08;
  }
}

.sort-desc {
  .fa-sort-down {
    color: #fc4f08;
  }
}

.sort-label__icon {
  position: relative;
  height: 20px;
  width: 10px;
  top: 4px;
}

</style>
