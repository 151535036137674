<template>
  <el-form
      :model="filters"
      ref="form"
      label-position="top"
      class="filter-form"
  >

    <el-form-item label="Search">
      <el-input
          v-model="filters.q"
          v-debounce="doFilter"
          class="search-input"
      />
    </el-form-item>

    <el-button
        v-if="isFiltersChanged"
        type="gray"
        @click="clearFilters"
    >
      Reset filters
    </el-button>
  </el-form>
</template>

<script>

const defaultValues = {
  q: '',
}

export default {
  name: 'SearchFilter',
  data() {
    return {
      filters: {...defaultValues},
      originFilters: {...defaultValues}
    }
  },
  computed: {
    isFiltersChanged() {
      return JSON.stringify(this.originFilters) !== JSON.stringify(this.filters)
    }
  },
  created() {
    this.applyIncomingParams()
  },
  methods: {
    async doFilter() {
      this.$emit('filter', this.filters)
    },
    async clearFilters() {
      this.filters = {...defaultValues}
      this.$emit('filter', this.filters)
    },
    applyIncomingParams() {
      const params = this.$route?.params
      if (params?.url) {
        this.filters.q = params?.url
        this.doFilter()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.search-input {

  ::v-deep .el-input__inner {
    min-width: 350px;
  }
}
</style>
