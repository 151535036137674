<template>
  <div v-loading="loading">
    <div v-if="(list && list.length) || loading" class="table-wrapper">
      <table class="table">
        <thead>
        <tr>
          <th>
            <SortLabel
                label="IP Address"
                :order="$_sortingMixin_checkOrder('ip')"
                @click.native="$_sortingMixin_doSort('ip')"
            />
          </th>
          <th class="text-center">
            <SortLabel
                label="Network Sites"
                :order="$_sortingMixin_checkOrder('network_sites_count')"
                @click.native="$_sortingMixin_doSort('network_sites_count')"
            />
          </th>
          <th class="text-center">
            <SortLabel
                label="Client Sites"
                :order="$_sortingMixin_checkOrder('client_sites_count')"
                @click.native="$_sortingMixin_doSort('client_sites_count')"
            />
          </th>
          <th>
            <SortLabel
                label="Owner"
                :order="$_sortingMixin_checkOrder('owner')"
                @click.native="$_sortingMixin_doSort('owner')"
            />
          </th>
          <th>
            <SortLabel
                label="Country"
                :order="$_sortingMixin_checkOrder('country')"
                @click.native="$_sortingMixin_doSort('country')"
            />
          </th>
          <th>
            <SortLabel
                label="Region"
                :order="$_sortingMixin_checkOrder('region')"
                @click.native="$_sortingMixin_doSort('region')"
            />
          </th>
        </tr>
        </thead>

        <tbody v-if="list">
        <tr v-for="item in list" :key="item.id">
          <td>{{ item.ip }}</td>
          <td class="text-center">
            <router-link
                v-if="item.network_sites_count"
                :to="{name: 'NetworkSites', params: {ip: item.ip}}"
                class="external-link"
            >
              {{ item.network_sites_count }} <i class="fas fa-external-link-alt"></i>
            </router-link>
            <div v-else>
              {{ item.network_sites_count }}
            </div>
          </td>
          <td class="text-center">
            <router-link
                v-if="item.client_sites_count"
                :to="{name: 'ClientSites', params: {ip: item.ip}}"
                class="external-link"
            >
              {{ item.client_sites_count }} <i class="fas fa-external-link-alt"></i>
            </router-link>
            <div v-else>
              {{ item.client_sites_count }}
            </div>
          </td>
          <td>{{ item.owner }}</td>
          <td>{{ item.country }}</td>
          <td>{{ item.region }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else/>
  </div>
</template>

<script>
import EmptyList from '@/components/global/EmptyList'
import SortLabel from '@/components/global/SortLabel'
import sortingMixin from '@/mixins/sortingMixin'

export default {
  name: 'IPsList',
  mixins: [sortingMixin],
  components: {
    EmptyList,
    SortLabel
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.table-wrapper {
  max-height: calc(100vh - 325px) !important;
}

</style>
