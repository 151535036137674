<template>
  <div>
    <TopNav/>
    <div class="auth-wrapper">
      <div class="auth-wrapper__inner">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from '@/components/global/TopNav'
export default {
  name: 'AuthLayout',
  components: { TopNav },
}
</script>

<style lang="scss" scoped>

.auth-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-wrapper__inner {
  margin-top: 9vh
}

</style>
