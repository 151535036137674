<template>
  <div>
    <div v-if="leads && leads.total_calc" class="campaign-trouble-states-totals">
      <span
        v-for="state in troubleStatesForTotals"
        :key="state.key"
        :class="[campaignTroubleStateTotalClass(state.key)]"
      >
        {{ state.label }}: {{ leads.total_calc.trouble_states_counters[state.key] }}
        <span v-if="leads.total_calc.trouble_states_percentages[state.key]">
          ({{ leads.total_calc.trouble_states_percentages[state.key] }}%)
        </span>
      </span>
    </div>
    <div class="main-wrapper">
      <div class="shadow" />
      <div ref="leftTableWrapper" class="table-left-wrapper" @scroll="onLeftSideScroll">
        <table class="table-left">
          <thead class="table-left__thead">
          <tr class="first-tr-row">
            <th colspan="9" />
          </tr>
          <tr class="second-tr-row" v-if="leads && leads.total_calc">
            <th />

            <th>
              {{ leads.total_calc.unique_clients_count }}
            </th>
            <th>
              {{ leads.total_calc.ppc_count }}
              <br />
              {{ leads.total_calc.ppc_percentage }}%
            </th>
            <th />
            <th>
              {{ leads.total_calc.target_average }}
            </th>
            <th class="color-success">
              {{ leads.total_calc.verified_leads_count }}
            </th>
            <th class="color-danger">
              {{ leads.total_calc.missed_leads_count }}
            </th>
            <th />
            <th>
              {{ leads.total_calc.run_rate_average }}
            </th>
          </tr>
          <tr class="third-tr-row">
            <th class="sort">
              <SortLabel
                :order="$_sortingMixin_checkOrder('campaign')"
                label="Campaign"
                @click.native="$_sortingMixin_doSort('campaign')"
              />
            </th>
            <th class="sort">
              <SortLabel
                :order="$_sortingMixin_checkOrder('client')"
                label="Client"
                @click.native="$_sortingMixin_doSort('client')"
              />
            </th>
            <th class="sort">
              <SortLabel
                :order="$_sortingMixin_checkOrder('ppc')"
                label="PPC"
                @click.native="$_sortingMixin_doSort('ppc')"
              />
            </th>
            <th class="sort">
              <SortLabel
                :order="$_sortingMixin_checkOrder('trouble_state')"
                label="Status"
                @click.native="$_sortingMixin_doSort('trouble_state')"
              />
            </th>
            <th class="sort">
              <SortLabel
                :order="$_sortingMixin_checkOrder('target')"
                label="Target"
                @click.native="$_sortingMixin_doSort('target')"
              />
            </th>
            <th class="sort">
              <SortLabel
                :order="$_sortingMixin_checkOrder('verified')"
                label="Verif."
                @click.native="$_sortingMixin_doSort('verified')"
              />
            </th>
            <th class="sort">
              <SortLabel
                :order="$_sortingMixin_checkOrder('missed')"
                label="Miss."
                @click.native="$_sortingMixin_doSort('missed')"
              />
            </th>
            <th class="sort">
              <SortLabel
                :order="$_sortingMixin_checkOrder('days_left')"
                label="Days Left"
                @click.native="$_sortingMixin_doSort('days_left')"
              />
            </th>
            <th class="sort">
              <SortLabel
                :order="$_sortingMixin_checkOrder('run_date')"
                label="Run Rate"
                @click.native="$_sortingMixin_doSort('run_date')"
              />
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(campaign, key) in leads && leads.campaigns" :key="key" :class="[campaignTroubleStateClass(campaign.trouble_state)]">
            <td :title="campaign.name" class="campaign-cell">
              {{ campaign.name }}
            </td>
            <td class="client-cell">
              {{ campaign.client }}
            </td>
            <td class="ppc-cell">
              <el-switch
                v-model="campaign.is_ppc"
                :active-value="1"
                :inactive-value="0"
                @change="switchPPC(campaign)"
              />
            </td>
            <td>
              <el-select
                v-model="campaign.trouble_state"
                placeholder="Select"
                size="mini"
              >
                <el-option
                  v-for="state in troubleStates"
                  :key="state.key"
                  :label="state.label"
                  :value="state.key"
                  @click.native="updateTroubleState(campaign.id, state.key)"
                />
              </el-select>
            </td>
            <td class="target-cell text-center">
              {{ campaign.leads_target }}
            </td>
            <td class="current-leads-cell text-center">
              <span class="color-success">{{ campaign.current_leads }}</span>
            </td>
            <td class="text-center">
              <span class="color-danger">{{ campaign.missed_leads }}</span>
            </td>
            <td class="text-center">{{ campaign.days_left }}</td>
            <td
              :class="{'color-danger': campaign.run_rate < 1 && campaign.leads_target != 0 && campaign.days_left <= 22}"
              class="text-center"
            >
              {{ campaign.leads_target == 0 ? '' : campaign.run_rate }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div ref="rightTableWrapper" v-if="leads" class="table-right-wrapper" @scroll="onRightSideScroll">
        <table class="table-right">
          <thead class="table-right__thead">
          <tr class="first-tr-row">
            <th
              v-for="(month, index) in leads.weeks" :key="'' + month.month + month.year + index"
              :colspan="month.count + 1"
              class="month-name-cell"
            >
              {{ getMonthName(month.month) }}

              ({{ leads.totals[index].weeks.total.avg_confirmed_for_campaign }})
            </th>
          </tr>

          <tr class="second-tr-row">
            <template v-for="(total) in leads.totals">
              <th
                v-for="(week, key) in sortWeeks(total.weeks)"
                :key="'' + key + total.month + total.year + key"
                :class="{'total-cell total-cell--divider': key === 'total'}"
              >
                <span class="color-success">{{ (week.confirmed != 0 ? week.confirmed : null) }}</span>
                <br>
                <span class="color-danger">{{ (week.missed != 0 ? week.missed : null) }}</span>
              </th>
            </template>
          </tr>

          <tr class="third-tr-row">
            <template v-for="(weeks, index) in leads.weeks">
              <th :key="'' + index + weeks.year + weeks.month + index" class="total-cell total-cell--divider">
                <div class="label-wrapper">
                  <SortLabel
                    :order="$_sortingMixin_checkOrder(`total.${weeks.month}-${weeks.year}`)"
                    label="Total"
                    @click.native="$_sortingMixin_doSort(`total.${weeks.month}-${weeks.year}`)"
                  />
                </div>
              </th>
              <th
                v-for="week in getRange(weeks.count)"
                :key="'' + week + index + week"
              >
                <div class="label-wrapper">
                  <SortLabel
                    :label="`w${week}`"
                    :order="$_sortingMixin_checkOrder(`w${week}.${weeks.month}-${weeks.year}`)"
                    @click.native="$_sortingMixin_doSort(`w${week}.${weeks.month}-${weeks.year}`)"
                  />
                </div>
              </th>
            </template>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(campaign, key) in leads && leads.campaigns" :key="'' + campaign.id + key">
            <template v-for="(leads, index) in campaign.leads">
              <td
                v-for="(count, key) in sortWeeks(leads.weeks)"
                :key="'' + key + index"
                :class="{'lead-cell--divider': key === 0}"
                class="lead-cell"
              >
                <span class="color-success">{{ (count.confirmed != 0 ? count.confirmed : '&nbsp;') }}</span>
                {{ (count.confirmed != 0 && count.missed != 0 ? '/' : '') }}
                <span class="color-danger">{{ (count.missed != 0 ? count.missed : '&nbsp;') }}</span>
              </td>
            </template>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {format} from 'date-fns'
import SortLabel from '@/components/global/SortLabel'
import sortingMixin from '@/mixins/sortingMixin'
import {CAMPAIGN_TROUBLE_STATES} from "@/store/campaigns";

export default {
  name: 'LeadsTable',
  components: {SortLabel},
  mixins: [sortingMixin],
  data() {
    return {
      isSyncingLeftScroll: false,
      isSyncingRightScroll: false,
    }
  },
  computed: {
    ...mapState('leads', {
      leads: 'leadsForTable',
    }),
    ...mapState('campaigns', ['troubleStates']),
    troubleStatesForTotals() {
      return this.troubleStates;
    }
  },
  methods: {
    ...mapActions('campaigns', [
      'toggleIsPpc',
      'updateCampaignTroubleState',
    ]),
    campaignTroubleStateClass(troubleState) {
      switch (troubleState) {
        case CAMPAIGN_TROUBLE_STATES.TROUBLE: {
          return 'campaign_state-error';
        }
        case CAMPAIGN_TROUBLE_STATES.MONITOR: {
          return 'campaign_state-warning';
        }
        case CAMPAIGN_TROUBLE_STATES.NO_ISSUES: {
          return 'campaign_state-success';
        }
        default: {
          return '';
        }
      }
    },
    campaignTroubleStateTotalClass(troubleState) {
      switch (troubleState) {
        case CAMPAIGN_TROUBLE_STATES.TROUBLE: {
          return 'color-danger';
        }
        case CAMPAIGN_TROUBLE_STATES.MONITOR: {
          return 'color-warning';
        }
        case CAMPAIGN_TROUBLE_STATES.NO_ISSUES: {
          return 'color-success';
        }
        default: {
          return '';
        }
      }
    },
    switchPPC(campaign) {
      this.toggleIsPpc(campaign.id)
    },
    updateTroubleState(campaignId, troubleState) {
      this.updateCampaignTroubleState({campaignId, troubleState});
    },
    getMonthName(val) {
      return format(new Date(2000, val - 1, 1), 'LLLL')
    },
    getRange(val) {
      return [...Array(val).keys()].map(x => ++x)
    },
    onLeftSideScroll() {
      let leftDiv = this.$refs.leftTableWrapper
      let rightDiv = this.$refs.rightTableWrapper

      if (!this.isSyncingLeftScroll) {
        this.isSyncingRightScroll = true
        rightDiv.scrollTop = leftDiv.scrollTop

        if(rightDiv.scrollTop !== leftDiv.scrollTop) {
          leftDiv.scrollTop = rightDiv.scrollTop
        }
      }
      this.isSyncingLeftScroll = false
    },
    onRightSideScroll() {
      let leftDiv = this.$refs.leftTableWrapper
      let rightDiv = this.$refs.rightTableWrapper

      if (!this.isSyncingRightScroll) {
        this.isSyncingLeftScroll = true
        leftDiv.scrollTop = rightDiv.scrollTop

        if(rightDiv.scrollTop !== leftDiv.scrollTop) {
          rightDiv.scrollTop = leftDiv.scrollTop
        }
      }
      this.isSyncingRightScroll = false
    },
    sortWeeks(obj) {
      if (!obj) return []
      return Object.entries(obj).sort((a, b) => a[0] > b[0] ? 1 : -1).map(i => i[1])
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.campaign-trouble-states-totals {
  display: flex;
  gap: 20px;
  font-weight: bold;
}

.main-wrapper {
  display: flex;
  min-height: 150px;
  position: relative;
}

.table-left-wrapper {
  width: 814px;
  max-width: 814px;
  min-width: 814px;
  margin-right: -2px;
  position: relative;
  z-index: 2;


}

.table-left__thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

.table-right-wrapper {
  width: calc(100% - 814px);
  overflow-x: auto;
  position: relative;
}

.table-left, .table-right {
  border-collapse: collapse;
  white-space: nowrap;
  font-weight: 500;

  .campaign_state-success {
    background: #d8f5c8;
  }

  .campaign_state-warning {
    background: #FFFFE2;
  }

  .campaign_state-error {
    background: #ffb6b6;
  }

  th, td {
    padding: 3px 4px;
    min-width: 20px;
    vertical-align: middle;
  }

  th {
    border-right: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    background: #FFFFFF;
  }

  td {
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    //background: #FFFFFF;
    height: 35px;
  }

  thead th {
    border-bottom-width: 1px;
  }
}

.table-left {
  width: 100%;
}

.table-right {
  text-align: right;


  th {
    text-align: right;
  }

  td {
    border-right: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
  }
}

.campaign-cell {
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: #666666;
}

.client-cell {
  max-width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: #666666;
}

.ppc-cell {
  width: 50px;
  max-width: 50px;
  padding-top: 2px !important;
}

.target-cell {
  width: 60px;
  max-width: 60px;
}

.current-leads-cell {
  width: 60px;
  max-width: 60px;
}

.color-success {
  color: green
}

.color-danger {
  color: $color-danger
}

.color-warning {
  color: orange
}

.lead-cell {
  color: #cccccc;
}

.first-tr-row {
  th {
    background: #505050;
    color: #ffffff;
    height: 35px;
    font-weight: 500;
    box-shadow: inset 0 0 0 1px #ffffff;
    border: none;
  }

  .month-name-cell {
    text-align: center;
  }

}

.second-tr-row {
  height: 46px;

  th {
    font-weight: 600;
    border-left: 2px solid #ffffff;
    background: #f1f1f1;
  }
}

.third-tr-row {
  th {
    box-shadow: inset 0 -1px 0 0 #aaaaaa;
  }
}

.label-wrapper {
  display: inline-block;
  text-align: left;
}

.lead-cell {
  &.lead-cell--divider {
    border-left: 2px solid #505050;
  }
}

.total-cell {
  &.total-cell--divider {
    border-left: 2px solid #505050 !important;
    border-right: 0;
  }
}

.shadow {
  width: 20px;
  position: absolute;
  left: 811px;
  top: 2px;
  bottom: 0;
  z-index: 2;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2px;
    box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.3);
  }
}


.table-right-wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 50px);
  padding-bottom: 5px;
}

.table-left-wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 60px);
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  overflow-x: hidden;
  padding-bottom: 5px;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}

.table-right__thead {
  position: sticky;
  top: 0;
  transition: none;
}


</style>
