<template>
  <div>
    <div class="page-title-group">
      <h1>Client Sites</h1>

      <el-button
          type="accent"
          icon="el-icon-plus"
          @click="openAddEditDialog"
      >
        Create New Client Site
      </el-button>
    </div>

    <FilterPanel>
      <ClientSitesFilters @filter="doFilter"/>
    </FilterPanel>

    <MainPanel>
      <div class="action-bar">
        <PageLimitSwitcher :active="filterObject.per_page" @click="setPageLimit"/>

        <el-pagination
            v-if="clientSites"
            background
            layout="prev, pager, next"
            :page-size="filterObject.per_page"
            :current-page="filterObject.page"
            :total="clientSites.total"
            hide-on-single-page
            @current-change="changePage"
        />

        <div class="table-total">
          Total Items: {{ clientSites && clientSites.total }}
        </div>
      </div>

      <ClientSitesList
          :loading="loading"
          @sort="doSort"
          @edit="openAddEditDialog"
          @show-logs="openLogsDialog"
          @refetch-data="getClientSites"
      />
    </MainPanel>

    <AddEditSiteDialog
        :visible.sync="addEditDialogVisible"
        @close="closeAddEditDialog"
        @refetch-data="getClientSites"
    />

    <SiteLogsDialog
        :visible.sync="logsDialogVisible"
        @close="closeLogsDialog"
    />

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PageLimitSwitcher from '@/components/global/PageLimitSwitcher'
import ClientSitesList from '@/components/client-sites/ClientSitesList'
import AddEditSiteDialog from '@/components/client-sites/AddEditSiteDialog'
import SiteLogsDialog from '@/components/client-sites/SiteLogsDialog'
import FilterPanel from '@/components/global/FilterPanel'
import MainPanel from '@/components/global/MainPanel'
import ClientSitesFilters from '@/components/client-sites/ClientSitesFilters'

export default {
  name: 'ClientSitesPage',
  metaInfo: {
    title: 'Client Sites'
  },
  components: {
    ClientSitesFilters,
    MainPanel,
    FilterPanel,
    SiteLogsDialog,
    AddEditSiteDialog,
    ClientSitesList,
    PageLimitSwitcher
  },
  data() {
    return {
      loading: false,
      addEditDialogVisible: false,
      logsDialogVisible: false,
      filterObject: {
        page: 1,
        per_page: 200
      }
    }
  },
  computed: {
    ...mapState('sites', [
      'clientSites'
    ])
  },
  mounted() {
    this.getClientSites()
    this.fetchAllCampaigns()
  },
  methods: {
    ...mapActions('sites', [
      'fetchClientSites'
    ]),
    ...mapActions('campaigns', [
      'fetchAllCampaigns'
    ]),
    async getClientSites() {
      this.loading = true
      await this.fetchClientSites(this.filterObject)
      this.loading = false
    },
    doFilter(filters) {
      this.filterObject = { ...this.filterObject, ...filters }
      this.getClientSites()
    },
    doSort(sort) {
      this.filterObject = { ...this.filterObject, ...sort }
      this.filterObject.page = 1
      this.getClientSites()
    },
    setPageLimit(val) {
      this.filterObject.per_page = val
      this.filterObject.page = 1
      this.getClientSites()
    },
    changePage(page) {
      this.filterObject.page = page
      this.getClientSites()
    },
    openAddEditDialog() {
      this.addEditDialogVisible = true
    },
    closeAddEditDialog() {
      this.addEditDialogVisible = false
    },
    openLogsDialog() {
      this.logsDialogVisible = true
    },
    closeLogsDialog() {
      this.logsDialogVisible = false
    }
  }
}
</script>
