<template>
  <div>
    <div class="page-title-group">
      <h1>Leads Chart</h1>
    </div>

    <FilterPanel>
      <ChartFilters
        :defaultDataValues="defaultDataValues"
        :chart-type="chartType"
        @filter="doFilter"
        @change-chart-type="changeChartType"
      />
    </FilterPanel>

    <MainPanel>
      <div class="chart-top-bar">
        <el-select
          v-model="filterObject.campaign_id"
          class="multiselect campaign-select"
          filterable
          @change="getLeadsChart"
        >
          <el-option
            v-for="campaign in [{id: null, name: 'All campaigns'}, ...allCampaigns]"
            :key="campaign.id"
            :label="campaign.name"
            :value="campaign.id"
          />
        </el-select>

        <ChartSummary v-if="filterObject.campaign_id" :loading="loading" />
      </div>

      <Chart :loading="loading" :chart-type="chartType" />
    </MainPanel>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import MainPanel from '@/components/global/MainPanel'
import Chart from '@/components/leads/Chart'
import FilterPanel from '@/components/global/FilterPanel'
import ChartFilters from '@/components/leads/ChartFilters'
import {format, sub} from 'date-fns'
import ChartSummary from '@/components/leads/ChartSummary'

const defaultDataValues = {
  startDate: format(sub(Date.now(), {years: 1}), 'yyyy-MM-dd'),
  endDate: format(Date.now(), 'yyyy-MM-dd'),
}

export default {
  name: 'LeadsChartsPage',
  metaInfo: {
    title: 'Leads Chart',
  },
  components: {
    ChartSummary,
    ChartFilters,
    FilterPanel,
    Chart,
    MainPanel,
  },
  data() {
    return {
      defaultDataValues,
      loading: false,
      chartType: 'line',
      filterObject: {
        date_from: defaultDataValues.startDate,
        date_to: defaultDataValues.endDate,
        campaign_id: null,
        group: 'week',
      },
    }
  },
  computed: {
    ...mapState('campaigns', [
      'allCampaigns',
    ]),
  },
  mounted() {
    this.getLeadsChart()
    this.fetchAllCampaigns()
  },
  methods: {
    ...mapActions('leads', [
      'fetchLeadsChart',
    ]),
    ...mapActions('campaigns', [
      'fetchAllCampaigns',
    ]),
    async getLeadsChart() {
      this.loading = true
      await this.fetchLeadsChart(this.filterObject)
      this.loading = false
    },
    doFilter(filters) {
      this.filterObject = {...this.filterObject, ...filters}
      this.filterObject.date_from = filters.date_range[0]
      this.filterObject.date_to = filters.date_range[1]
      this.getLeadsChart()
    },
    changeChartType(type) {
      this.chartType = type
    }
  },
}
</script>

<style lang="scss" scoped>

.chart-top-bar {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.campaign-select {
  min-width: 500px;
  width: 500px;

  ::v-deep .el-input__inner {
    font-size: 20px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 12px;
    height: 45px;
  }
}

.campaign-select-wrapper {
  margin-top: 0;
}

</style>
