<template>
  <div v-if="!isGuest">
    <TopNav @edit-profile="openEditProfileDialog"/>
    <div>
      <div :style="`background: ${sidebarColor}`" class="sidebar">
        <SideNav></SideNav>
      </div>

      <div class="site-content authenticated">
        <router-view :key="$route.path">
          <slot />
        </router-view>
      </div>
    </div>

    <ProfileEditDialog
      :visible.sync="addEditDialogVisible"
      @close="closeEditProfileDialog"
    />
  </div>
</template>

<script>
import SideNav from '@/components/global/SideNav'
import TopNav from '@/components/global/TopNav'
import {mapGetters} from 'vuex'
import ProfileEditDialog from '@/components/profile/ProfileEditDialog'

export default {
  name: 'DefaultLayout',
  components: {
    SideNav,
    TopNav,
    ProfileEditDialog,
  },
  data() {
    return {
      addEditDialogVisible: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['isGuest']),
    sidebarColor() {
      return ['local', 'staging', 'development'].includes(process.env.VUE_APP_ENV) ? '#ff6601' : '#222222'
    },
  },
  methods: {
    openEditProfileDialog() {
      this.addEditDialogVisible = true
    },
    closeEditProfileDialog() {
      this.addEditDialogVisible = false
    },
  },
}
</script>
