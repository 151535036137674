<template>
  <el-dialog
      :visible="visible"
      :before-close="closeModal"
      title="Connected accounts"
      width="94%"
  >
    <div class="table-wrapper-alt">
      <table class="table">
        <tr v-for="item in list" :key="item.id">
          <td>
            {{ item.account_name }}
          </td>
          <td class="text-center">
            {{ item.email }}
          </td>
          <td class="text-right">
            <el-button
                type="danger"
                size="mini"
                @click="unlinkAccount(item.id)"
            >
              Unlink
            </el-button>
          </td>
        </tr>
      </table>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button
          type="accent"
          icon="el-icon-plus"
          @click="linkNewAccount"
      >
        Add New Account
      </el-button>
      <el-button @click="closeModal">Close</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import modalMixin from '@/mixins/modalMixin'

export default {
  name: 'AddEditCategoryDialog',
  mixins: [modalMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('gmbs', {
      list: 'GMBAccounts'
    })

  },
  mounted() {
    this.fetchGmbAccounts()
  },
  methods: {
    ...mapActions('gmbs', [
      'fetchGmbAccounts',
      'linkNewAccount',
      'unlinkAccount'
    ]),
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 600px;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}

::v-deep .dialog-footer {
  display: flex;
  justify-content: space-between;
}

.table-wrapper-alt {
  margin-right: -5px;
  margin-left: -5px;
}

td {
  padding: 5px;
}

</style>
