<template>
  <div>
    <div class="page-title-group">
      <h1>Leads Table</h1>

      <el-button
        :loading="exportLoading"
        type="accent"
        @click="doExport"
      >
        <i class="fas fa-cloud-download-alt" />
        Export
      </el-button>
    </div>

    <FilterPanel>
      <LeadsTableFilters @filter="doFilter" />
    </FilterPanel>

    <MainPanel>
      <div class="action-bar">
        <div/>

        <div class="table-total">
          Total Campaigns: {{ leads && leads.campaigns.length }}
        </div>
      </div>

      <div v-loading="loading" class="table-loading">
        <LeadsTable
          @sort="doSort"
          @refetch-data="getLeads"
        />
      </div>

    </MainPanel>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FilterPanel from '@/components/global/FilterPanel'
import MainPanel from '@/components/global/MainPanel'
import LeadsTable from '@/components/leads/LeadsTable'
import LeadsTableFilters from '@/components/leads/LeadsTableFilters'

const defaultDataValues = {
  year: null,
  campaign_category_ids: [],
}

export default {
  name: 'LeadsTablePage',
  metaInfo: {
    title: 'Leads'
  },
  components: {
    LeadsTableFilters,
    LeadsTable,
    MainPanel,
    FilterPanel,
  },
  data() {
    return {
      loading: true,
      exportLoading: false,
      filterObject: {...defaultDataValues}
    }
  },
  computed: {
    ...mapState('leads', {
      leads: 'leadsForTable'
    }),
  },
  mounted() {
    this.getLeads()
  },
  methods: {
    ...mapActions('leads', [
      'fetchLeadsForTable',
      'exportLeadsChart',
    ]),
    async getLeads() {
      this.loading = true
      await this.fetchLeadsForTable(this.filterObject)
      this.loading = false
    },
    doFilter(filters) {
      this.filterObject = { ...this.filterObject, ...filters }
      this.getLeads()
    },
    doSort(sort) {
      this.filterObject = { ...this.filterObject, ...sort }
      this.getLeads()
    },
    async doExport() {
      this.exportLoading = true
      await this.exportLeadsChart(this.filterObject)
      this.exportLoading = false
    },
  }
}
</script>

<style lang="scss" scoped>

.action-bar {
  margin-bottom: 0 !important;
  margin-top: 0;
}

.table-loading {
  min-height: 200px;
}

</style>
