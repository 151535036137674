<template>
  <div v-loading="loading">
    <div v-if="spammers.length || loading" class="table-wrapper">
      <table class="table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th class="fixed-right-column text-center">Actions</th>
        </tr>
        </thead>

        <tbody v-if="spammers">
        <tr v-for="spammer in spammers" :key="spammer.id">
          <td>
            {{ spammer.name }}
          </td>
          <td>
            {{ spammer.phone }}
          </td>
          <td>
            {{ spammer.email }}
          </td>
          <td class="fixed-right-column">
            <el-button-group>
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-delete"
                title="Remove from the list"
                @click="onDelete(spammer)"
              />
            </el-button-group>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else />
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import EmptyList from '@/components/global/EmptyList'

export default {
  name: 'SpammersList',
  components: {
    EmptyList,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('leads', [
      'spammers',
    ]),
  },
  methods: {
    ...mapActions('leads', [
      'deleteSpammer',
    ]),
    async onDelete(spammer) {
      try {
        const res = await this.$confirm('Are you sure to remove this item?')
        if (res) {
          this.$emit('enable-loading')
          await this.deleteSpammer(spammer.id)
          this.$emit('refetch-data')
        }
      } catch {}
    },
  },
}
</script>

<style scoped>

.table {
  margin-bottom: 0;
}

::v-deep .empty-list {
  margin-bottom: 0;
}

</style>
