<template>
  <el-form
      label-position="top"
      class="filter-form"
  >
    <el-form-item label="Campaigns" class="multiselect-campaign-wrapper">
      <el-select
          v-model="filters.campaigns_ids"
          filterable
          multiple
          @change="doFilter"
      >
        <el-option
            v-for="campaign in allCampaigns"
            :key="campaign.id"
            :label="campaign.name"
            :value="campaign.id"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Category">
      <el-select
          v-model="filters.category_id"
          filterable
          @change="doFilter"
      >
        <el-option
            v-for="category in campaignCategories"
            :key="category.id"
            :label="category.name"
            :value="category.id"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Clients only">
      <el-switch
          v-model="filters.client_only"
          :inactive-value="0"
          :active-value="1"
          @change="doFilter"
      />
    </el-form-item>

    <el-button
        v-if="isFiltersChanged"
        type="gray"
        @click="clearFilters"
    >
      Reset filters
    </el-button>
  </el-form>
</template>

<script>
import { mapState, mapActions } from 'vuex'

const defaultValues = {
  campaigns_ids: [],
  category_id: null,
  client_only: 0
}

export default {
  name: 'GmbsMapFilters',
  data() {
    return {
      originFilters: { ...defaultValues },
      filters: { ...defaultValues }
    }
  },
  computed: {
    ...mapState('campaigns', [
      'allCampaigns',
      'campaignCategories'
    ]),
    isFiltersChanged() {
      return JSON.stringify(this.originFilters) !== JSON.stringify(this.filters)
    }
  },
  created() {
    this.fetchAllCampaigns()
    this.fetchCampaignCategories()
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchAllCampaigns',
      'fetchCampaignCategories'
    ]),
    async doFilter() {
      this.$emit('filter', this.filters)
    },
    clearFilters() {
      this.filters = { ...defaultValues }
      this.doFilter()
    }
  }
}
</script>

<style lang="scss" scoped>

.panel-body {
  flex-grow: 1
}


.multiselect-campaign-wrapper {
  flex-grow: 1;
  max-width: 700px;
}

</style>
