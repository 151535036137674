import api from '@/utils/api'
import { Message } from 'element-ui'
import fileSaver from '@/utils/fileSaver'

export default {
    state: () => ({
        clientSites: null,
        allClientSites: null,
        networkSites: null,
        siteStatusList: [
            { id: 1, label: 'Live' },
            { id: 0, label: 'Offline' }
        ],
        activeClientSite: null,
        activeNetworkSite: null,
        clientSiteLog: null,
        networkSiteLog: null
    }),
    mutations: {
        setClientSites(state, payload) {
            state.clientSites = Object.freeze(payload)
        },

        setAllClientSites(state, payload) {
            state.allClientSites = Object.freeze(payload)
        },

        setActiveClientSite(state, payload) {
            state.activeClientSite = payload
        },

        setClientSiteLog(state, payload) {
            state.clientSiteLog = Object.freeze(payload)
        },

        setNetworkSites(state, payload) {
            state.networkSites = Object.freeze(payload)
        },

        setActiveNetworkSite(state, payload) {
            state.activeNetworkSite = payload
        },

        setNetworkSiteLog(state, payload) {
            state.networkSiteLog = Object.freeze(payload)
        }
    },
    getters: {
        getSiteStatusById(state) {
            return (status_id) => {
                const res = state.siteStatusList.find(item => item.id === +status_id)
                return res ? res.label : '-'
            }
        }
    },
    actions: {
        async fetchClientSites({ commit }, params) {
            const result = await api.http.get('clientsite', { params })
            commit('setClientSites', result?.data)
        },

        async fetchAllClientSites({ commit }) {
            const result = await api.http.get('clientsite/all')
            commit('setAllClientSites', result?.data)
        },

        async deleteClientSite(_, site_id) {
            await api.http.delete(`clientsite/${site_id}`)
            Message.success('Client Site was removed successfully')
        },

        async createClientSite(_, form) {
            await api.http.post('clientsite', form)
            Message.success('Client Site was created successfully')
        },

        async updateClientSite(_, form) {
            await api.http.put(`clientsite/${form.id}`, form)
            Message.success('Client Site was updated successfully')
        },

        async fetchClientSiteLog({ commit }, params) {
            const result = await api.http.get(`clientsite/${params.id}/logs`, { params: params })
            commit('setClientSiteLog', result?.data)
        },

        async fetchNetworkSites({ commit }, params) {
            const result = await api.http.get(`networksite`, { params })
            commit('setNetworkSites', result?.data)
        },

        async exportNetworkSites(_, params) {
            const response = await api.http.get(`networksite/export`, { params })
            fileSaver.saveFileFromResponse(response, 'network_sites.csv')
        },

        async deleteNetworkSite(_, site_id) {
            await api.http.delete(`networksite/${site_id}`)
            Message.success('Site was removed successfully')
        },

        async createNetworkSite(_, form) {
            await api.http.post('networksite', form)
            Message.success('Site was created successfully')
        },

        async updateNetworkSite(_, form) {
            await api.http.put(`networksite/${form.id}`, form)
            Message.success('Site was updated successfully')
        },

        async fetchNetworkSiteLog({ commit }, params) {
            const result = await api.http.get(`networksite/${params.id}/logs`, { params: params })
            commit('setNetworkSiteLog', result?.data)
        }
    },
    namespaced: true
}
