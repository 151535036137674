<template>
  <div>
    <div class="page-title-group">
      <h1 v-if="category">
        <a href="#" @click.prevent="removeParams">
          Campaigns
        </a>
        <span>/ {{ category.name }} Category</span>
      </h1>
      <h1 v-else>Campaigns</h1>

      <div class="top-action-bar">
        <SearchFilterAlt @filter="doFilter" placeholder="Search by Campaign or Client Name"/>

        <el-button
          icon="el-icon-plus"
          type="accent"
          @click="openAddEditDialog"
        >
          Create New Campaign
        </el-button>
      </div>
    </div>

    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="Active Campaigns" name="active-campaigns">
        <CampaignsList
          ref="activeList"
          @edit-campaign="triggerCampaignEdit"
          @edit-area="triggerEditArea"
          @edit-working-hours="triggerWorkingHoursEdit"
        />
        <CampaignsSummaryTable />
      </el-tab-pane>
      <el-tab-pane label="Archived Campaigns" name="archived-campaigns">
        <CampaignsList
          ref="archivedList"
          isArchived
          @edit-campaign="triggerCampaignEdit"
          @edit-area="triggerEditArea"
          @edit-working-hours="triggerWorkingHoursEdit"
        />
        <CampaignsSummaryTable isArchived />
      </el-tab-pane>
    </el-tabs>

    <AddEditCampaignDialog
      :activeCampaign="activeCampaign"
      :visible.sync="addEditDialogVisible"
      @close="closeAddEditDialog"
    />

    <EditAreaDialog
      v-if="editAreaDialogVisible"
      :activeCampaign="activeCampaign"
      :visible.sync="editAreaDialogVisible"
      @close="closeEditAreaDialog"
    />

    <EditWorkingHoursDialog
      :visible.sync="editWorkingHoursDialogVisible"
      :campaign="activeCampaign"
      @close="closeWorkingHoursEdit"
    />
  </div>
</template>

<script>
import CampaignsList from '@/components/campaigns/CampaignsList'
import AddEditCampaignDialog from '@/components/campaigns/AddEditCampaignDialog'
import CampaignsSummaryTable from '@/components/campaigns/CampaignsSummaryTable'
import EditAreaDialog from '@/components/campaigns/EditAreaDialog'
import {mapActions} from 'vuex'
import SearchFilterAlt from '@/components/global/SearchFilterAlt'
import EditWorkingHoursDialog from "@/components/campaigns/EditWorkingHoursDialog.vue";

export default {
  name: 'CampaignsPage',
  metaInfo: {
    title: 'Campaigns',
  },
  components: {
    EditWorkingHoursDialog,
    SearchFilterAlt,
    EditAreaDialog,
    AddEditCampaignDialog,
    CampaignsList,
    CampaignsSummaryTable,
  },
  data() {
    return {
      activeName: 'active-campaigns',
      addEditDialogVisible: false,
      editAreaDialogVisible: false,
      editWorkingHoursDialogVisible: false,
      activeCampaign: null,
      category: null,
    }
  },
  created() {
    this.getCategoryName()
    this.fetchTimezones();
  },
  methods: {
    ...mapActions('clients', [
      'fetchTimezones',
    ]),
    ...mapActions('campaigns', [
      'fetchCampaignCategoryById',
    ]),
    triggerCampaignEdit(campaign) {
      this.activeCampaign = campaign
      this.addEditDialogVisible = true
    },
    triggerEditArea(campaign) {
      this.activeCampaign = campaign
      this.editAreaDialogVisible = true
    },
    triggerWorkingHoursEdit(campaign) {
      this.activeCampaign = campaign
      this.editWorkingHoursDialogVisible = true
    },
    openAddEditDialog() {
      this.addEditDialogVisible = true
    },
    closeAddEditDialog() {
      this.activeCampaign = null
      this.addEditDialogVisible = false
    },
    closeEditAreaDialog() {
      this.activeCampaign = null
      this.editAreaDialogVisible = false
    },
    closeWorkingHoursEdit() {
      this.activeCampaign = null
      this.editWorkingHoursDialogVisible = false
    },
    removeParams() {
      this.$router.push({query: {clear: undefined}})
      this.category = null
      this.$refs.activeList.attachGetParams()
      this.$refs.activeList.getCampaigns()
      this.$refs.archivedList.attachGetParams()
      this.$refs.archivedList.getCampaigns()
    },
    doFilter(filters) {
      this.$refs.activeList.doFilter(filters)
      this.$refs.archivedList.doFilter(filters)
    },
    async getCategoryName() {
      const id = this.$route.query?.campaign_category_id
      if (id) {
        this.category = await this.fetchCampaignCategoryById(id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.top-action-bar {
  display: flex;
  align-items: center;
}

</style>
