import { format, parseISO } from 'date-fns'

export default {
	methods: {
		$_formatterMixin_formatDate(date) {
			return format(parseISO(date), 'MM/dd/yyyy')
		},
		$_formatterMixin_formatCurrency(val) {
			if (val) {
				return new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD'
				}).format(Number(val.replace(/[^0-9.-]+/g,"")))
			}
			return val
		},
		$_formatterMixin_formatPercent(val) {
			return val ? `${val}%` : ''
		}
	}
}
