<template>
  <div>
    <div class="page-title-group">
      <h1>Network Categories</h1>

      <el-button
          type="accent"
          icon="el-icon-plus"
          @click="openAddEditDialog"
      >
        Create New Category
      </el-button>
    </div>

    <MainPanel>
      <div class="action-bar">
        <div></div>
        <div class="table-total">
          Total Items: {{ networkCategories && networkCategories.total || '-' }}
        </div>
      </div>

      <NetworkCategoriesList
          :loading="loading"
          @edit="openAddEditDialog"
          @refetch-data="getCategoriesList(false)"
      />
    </MainPanel>

    <AddEditCategoryDialog
        :visible.sync="addEditDialogVisible"
        @close="closeAddEditDialog"
        @refetch-data="getCategoriesList(false)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import NetworkCategoriesList from '@/components/network-categories/NetworkCategoriesList'
import AddEditCategoryDialog from '@/components/network-categories/AddEditCategoryDialog'
import MainPanel from '@/components/global/MainPanel'

export default {
  name: 'NetworkCategoriesPage',
  metaInfo: {
    title: 'Network Categories'
  },
  components: {
    MainPanel,
    AddEditCategoryDialog,
    NetworkCategoriesList
  },
  data() {
    return {
      loading: false,
      addEditDialogVisible: false
    }
  },
  computed: {
    ...mapState('network', [
      'networkCategories'
    ]),
  },
  mounted() {
    this.getCategoriesList()
  },
  methods: {
    ...mapActions('network', [
      'fetchNetworkCategories'
    ]),
    async getCategoriesList() {
      this.loading = true
      await this.fetchNetworkCategories()
      this.loading = false
    },
    openAddEditDialog() {
      this.addEditDialogVisible = true
    },
    closeAddEditDialog() {
      this.addEditDialogVisible = false
    }
  }
}
</script>
