<template>
<div v-loading="loading">
<table class="table">
  <thead>
  <tr>
    <th>Client</th>
    <th class="text-center">PPC</th>
    <th class="text-center">Target</th>
    <th class="text-center">Verified</th>
    <th class="text-center">Missed</th>
    <th class="text-center">Days Left</th>
    <th class="text-center">Run Rate</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td>{{campaign.client}}</td>
    <td class="text-center">
      <el-switch
        v-model="campaign.is_ppc"
        :active-value="1"
        :inactive-value="0"
        @change="switchPPC"
      />
    </td>
    <td class="text-center">{{campaign.leads_target}}</td>
    <td class="text-center verified-cell">{{campaign.current_leads}}</td>
    <td class="text-center missed-cell">{{campaign.missed_leads}}</td>
    <td class="text-center">{{campaign.days_left}}</td>
    <td class="text-center">{{campaign.run_rate}}</td>
  </tr>
  </tbody>
</table>
</div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'ChartSummary',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ppc: true,
    }
  },
  computed: {
    ...mapState('leads', [
      'leadsChart',
    ]),
    campaign() {
      return this.leadsChart?.campaign
    }
  },
  methods: {
    ...mapActions('campaigns', [
        'toggleIsPpc'
    ]),
    switchPPC() {
      this.toggleIsPpc(this.campaign.id)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.table {
  border-collapse: collapse;
}

th, td {
  border: 1px solid #d7d5d5 !important;
  padding: 5px 15px !important;
}

.verified-cell {
  color: $color-success;
  font-weight: 600;
}

.missed-cell {
  color: $color-danger;
  font-weight: 600;
}

</style>
