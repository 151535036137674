<template>
  <el-dialog
      :visible="visible"
      :before-close="handleClose"
      :title="title"
      width="94%"
      top="70px"
  >
    <NetworkSiteLog/>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import NetworkSiteLog from '@/components/network-sites/NetworkSiteLog'

export default {
  name: 'SiteLogsDialog',
  components: { NetworkSiteLog },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('sites', [
      'activeNetworkSite'
    ]),
    title() {
      return `Status logs for ${this.activeNetworkSite?.url}`
    }
  },
  methods: {
    ...mapMutations('sites', [
      'setActiveNetworkSite',
      'setNetworkSiteLog'
    ]),
    async handleClose() {
      this.setActiveNetworkSite(null)
      this.setNetworkSiteLog(null)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 500px;
}

::v-deep .el-dialog__body {
  padding-top: 15px;
}

</style>
