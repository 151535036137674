import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import campaigns from './campaigns'
import activityLog from './activity-log'
import admin from './admin'
import network from './network'
import sites from './sites'
import clients from './clients'
import gmbs from './gmbs'
import leads from './leads'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		layout: 'default-layout'
	},
	mutations: {
		setLayout(state, payload) {
			state.layout = payload
		}
	},
	namespaced: true,
	modules: {
		auth,
		campaigns,
		'activity-log': activityLog,
		admin,
		network,
		sites,
		clients,
		gmbs,
		leads
	}
})
