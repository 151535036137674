<template>
  <div>
    <div v-if="log && log.data.length">
      <div class="action-bar action-bar--without-paginator">
        <PageLimitSwitcher :active="filterObject.per_page" @click="setPageLimit"/>

        <div class="table-total">
          Total Items: {{ log.total || '-' }}
        </div>
      </div>

      <el-pagination
          background
          layout="prev, pager, next"
          :page-size="filterObject.per_page"
          :current-page="filterObject.page"
          :total="log.total"
          hide-on-single-page
          @current-change="changePage"
      />

    </div>

    <SiteLogList
        :loading="loading"
        :log="log && log.data"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PageLimitSwitcher from '@/components/global/PageLimitSwitcher'
import SiteLogList from '@/components/client-sites/SiteLogList'

export default {
  name: 'ClientSiteLog',
  components: {
    SiteLogList,
    PageLimitSwitcher
  },
  data() {
    return {
      loading: false,
      filterObject: {
        page: 1,
        per_page: 200
      }
    }
  },
  computed: {
    ...mapState('sites', [
      'activeClientSite',
      'clientSiteLog'
    ]),
    log() {
      return this.clientSiteLog
    }
  },
  watch: {
    activeClientSite() {
      if (this.activeClientSite) {
        this.getLog()
      }
    }
  },
  methods: {
    ...mapActions('sites', [
      'fetchClientSiteLog'
    ]),
    async getLog() {
      this.loading = true
      this.filterObject = {...this.activeClientSite}
      await this.fetchClientSiteLog(this.filterObject)
      this.loading = false
    },
    setPageLimit(val) {
      this.filterObject.per_page = val
      this.filterObject.page = 1
      this.getLog()
    },
    changePage(page) {
      this.filterObject.page = page
      this.getLog()
    }
  }
}
</script>
