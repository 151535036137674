import api from '@/utils/api'
import {Message} from 'element-ui'
import fileSaver from '@/utils/fileSaver'

export default {
  state: () => ({
    ipsList: null,
    networkCategories: [],
    activeNetworkCategory: null,
    outgoingLinksList: [],
    redirectDomains: [],
  }),
  mutations: {
    setIPslist(state, payload) {
      state.ipsList = Object.freeze(payload)
    },

    setNetworkCategories(state, payload) {
      state.networkCategories = Object.freeze(payload)
    },

    setActiveNetworkCategory(state, payload) {
      state.activeNetworkCategory = payload
    },

    setOutgoingLinks(state, payload) {
      state.outgoingLinksList = Object.freeze(payload)
    },

    setRedirectDomains(state, payload) {
      state.redirectDomains = Object.freeze(payload)
    },
  },
  getters: {},
  actions: {
    async fetchIPs({state, commit}, params) {
      if (params.cache && state.ipsList) return
      const result = await api.http.get('ip', {params})
      commit('setIPslist', result?.data)
    },

    async fetchNetworkCategories({state, commit}, cache = true) {
      if (cache && state.networkCategories.length) return
      const result = await api.http.get('networkcategory')
      commit('setNetworkCategories', result?.data)
    },

    async deleteNetworkCategory(_, category_id) {
      await api.http.delete(`networkcategory/${category_id}`)
      Message.success('Network Category was removed successfully')
    },

    async createNetworkCategory(_, form) {
      await api.http.post('networkcategory', form)
      Message.success('Network Category was created successfully')
    },

    async updateNetworkCategory(_, form) {
      await api.http.put(`networkcategory/${form.id}`, form)
      Message.success('Network Category was updated successfully')
    },

    async fetchOutgoingLinks({commit}, params) {
      const result = await api.http.get('networksite/outgoing-links', {params: params})
      commit('setOutgoingLinks', result?.data)
    },

    async fetchRedirectDomains({commit}, params) {
      const result = await api.http.get('redirect_domains', {params: params})
      commit('setRedirectDomains', result?.data)
    },

    async deleteRedirectDomain(_, id) {
      await api.http.delete(`redirect_domains/${id}`)
      Message.success('Redirect Domain was removed successfully')
    },

    async exportOutgoingLinks(_, params) {
      const response = await api.http.post(`networksite/outgoing-links/export`, params)
      fileSaver.saveFileFromResponse(response, 'outgoing-links.csv')
    },

    async createRedirect(_, params) {
      await api.http.post('redirect_domains', params)
      Message.success('Redirect was created successfully')
    },
  },
  namespaced: true,
}
