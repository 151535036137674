<template>
  <div v-loading="loading">
    <div v-if="(log && log.length) || loading" class="table-wrapper">
      <table class="table">
        <thead>
        <tr>
          <th>Time</th>
          <th>Status</th>
        </tr>
        </thead>

        <tbody v-if="log">
        <tr v-for="item in log" :key="item.id">
          <td>
            {{ item.created_at }}
          </td>
          <td>
            <div class="text-danger">
              {{item.changed ? 'Has difference' : ''}}
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EmptyList from '@/components/global/EmptyList'

export default {
  name: 'GmbLogList',
  components: {
    EmptyList
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('gmbs', [
      'gmbLog'
    ]),
    log() {
      return this.gmbLog?.data
    }
  },
}
</script>

<style scoped>

.table-wrapper {
  max-height: calc(100vh - 340px) !important;
  min-height: 50px;
}

.table {
  margin-bottom: 0;
}

::v-deep .empty-list {
  margin-bottom: 0;
}

</style>
