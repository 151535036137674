<template>
  <div>
    <div class="page-title-group">
      <h1>GMBs</h1>

      <el-button
          type="accent"
          icon="el-icon-plus"
          @click="openAddEditDialog"
      >
        Add New Campaign GMB
      </el-button>
    </div>

    <FilterPanel>
      <SearchFilter @filter="doFilter"/>
    </FilterPanel>

    <MainPanel>
      <div class="action-bar">
        <PageLimitSwitcher :active="filterObject.per_page" @click="setPageLimit"/>

        <el-pagination
            v-if="gmbs"
            background
            layout="prev, pager, next"
            :page-size="filterObject.per_page"
            :current-page="filterObject.page"
            :total="gmbs.total"
            hide-on-single-page
            @current-change="changePage"
        />

        <div class="table-total">
          Total Items: {{ gmbs && gmbs.total }}
        </div>
      </div>

      <GmbsList
          :loading="loading"
          :list="gmbs.data"
          @sort="doSort"
          @edit="openAddEditDialog"
          @refetch-data="getGmbs"
          @show-logs="openLogsDialog"
      />

      <GmbLogsDialog
          :visible.sync="logsDialogVisible"
          @close="closeLogsDialog"
      />

    </MainPanel>

    <AddEditGMBDialog
        :visible.sync="addEditDialogVisible"
        @refetch-data="getGmbs"
        @close="closeAddEditDialog"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PageLimitSwitcher from '@/components/global/PageLimitSwitcher'
import SearchFilter from '@/components/redirect-domains/SearchFilter'
import FilterPanel from '@/components/global/FilterPanel'
import MainPanel from '@/components/global/MainPanel'
import GmbsList from '@/components/gmbs/GmbsList'
import GmbLogsDialog from '@/components/gmbs/GmbLogsDialog'
import AddEditGMBDialog from '@/components/gmbs/AddEditGMBDialog'

export default {
  name: 'GMBsPage',
  metaInfo: {
    title: 'GMBs'
  },
  components: {
    AddEditGMBDialog,
    GmbLogsDialog,
    GmbsList,
    MainPanel,
    FilterPanel,
    SearchFilter,
    PageLimitSwitcher
  },
  data() {
    return {
      loading: false,
      addEditDialogVisible: false,
      logsDialogVisible: false,
      filterObject: {
        page: 1,
        per_page: 200
      }
    }
  },
  computed: {
    ...mapState('gmbs', [
      'gmbs'
    ])
  },
  mounted() {
    this.fetchStatusesList()
    this.getGmbs()
  },
  methods: {
    ...mapActions('gmbs', [
      'fetchGmbs',
      'fetchStatusesList'
    ]),
    async getGmbs() {
      this.loading = true
      await this.fetchGmbs(this.filterObject)
      this.loading = false
    },
    doFilter(filters) {
      this.filterObject = { ...this.filterObject, ...filters }
      this.getGmbs()
    },
    doSort(sort) {
      this.filterObject = { ...this.filterObject, ...sort }
      this.filterObject.page = 1
      this.getGmbs()
    },
    setPageLimit(val) {
      this.filterObject.per_page = val
      this.filterObject.page = 1
      this.getGmbs()
    },
    changePage(page) {
      this.filterObject.page = page
      this.getGmbs()
    },
    openLogsDialog() {
      this.logsDialogVisible = true
    },
    closeLogsDialog() {
      this.logsDialogVisible = false
    },
    openAddEditDialog() {
      this.addEditDialogVisible = true
    },
    closeAddEditDialog() {
      this.addEditDialogVisible = false
    }
  }
}
</script>
