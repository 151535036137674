<template>
  <div>
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/css/app.scss'
import { mapActions } from 'vuex'
import DefaultLayout from '@/layouts/DefaultLayout'
import AuthLayout from '@/layouts/AuthLayout'
import SimpleLayout from "@/layouts/SimpleLayout.vue";


export default {
  name: 'App',
  metaInfo: {
    titleTemplate: '%s | Network Manager'
  },
  components: {
    DefaultLayout,
    AuthLayout,
    SimpleLayout,
  },
  created() {
    try {
      if (!['Login', 'RateLead', 'RateNotLead'].includes(this.$route.name) && !this.$route?.query?.token) {
        this.loadUser()
      }
    } catch (e) {
      console.log(e)
    }
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'default-layout'
    }
  },
  methods: {
    ...mapActions('auth', ['loadUser'])
  }
}
</script>
