<template>
  <div v-loading="loading">
    <div v-if="(list && list.length) || loading" class="table-wrapper">
      <table class="table">
        <thead>
        <tr>
          <th>
            <SortLabel
                label="URL"
                :order="$_sortingMixin_checkOrder('url')"
                @click.native="$_sortingMixin_doSort('url')"
            />
          </th>
          <th>
            <SortLabel
                label="Campaign"
                :order="$_sortingMixin_checkOrder('campaign_id')"
                @click.native="$_sortingMixin_doSort('campaign_id')"
            />
          </th>
          <th class="text-center">
            <SortLabel
                label="Status"
                :order="$_sortingMixin_checkOrder('status')"
                @click.native="$_sortingMixin_doSort('status')"
            />
          </th>
          <th>
            <SortLabel
                label="IP"
                :order="$_sortingMixin_checkOrder('ip')"
                @click.native="$_sortingMixin_doSort('ip')"
            />
          </th>
          <th class="text-center">
            <SortLabel
                label="Domains"
                :order="$_sortingMixin_checkOrder('incoming_domains_from_links_count')"
                @click.native="$_sortingMixin_doSort('incoming_domains_from_links_count')"
            />
          </th>
          <th class="text-center">
            <SortLabel
                label="Links"
                :order="$_sortingMixin_checkOrder('incoming_links_count')"
                @click.native="$_sortingMixin_doSort('incoming_links_count')"
            />
          </th>
          <th class="text-center">
            <SortLabel
                label="301's"
                :order="$_sortingMixin_checkOrder('incoming_redirects_count')"
                @click.native="$_sortingMixin_doSort('incoming_redirects_count')"
            />
          </th>
          <th class="fixed-right-column text-center">
            Actions
          </th>
        </tr>
        </thead>

        <tbody v-if="list">
        <tr v-for="site in list" :key="site.id">
          <td>
            <a :href="`//${site.url}`" target="_blank" class="external-link">
              {{ site.url }} <i class="fas fa-external-link-alt"></i>
            </a>
          </td>
          <td>
            {{ site.campaign && site.campaign.name }}
          </td>
          <td class="text-center">
            <div :class="+site.status ? 'text-success' : 'text-danger'">
              {{ getSiteStatusById(site.status) }}
            </div>
          </td>
          <td>
            {{ site.ip && site.ip.ip }}
          </td>
          <td class="text-center">
            <router-link
                v-if="site.incoming_domains_from_links_count"
                :to="{name: 'NetworkSites', params: {host: site.url}}"
                class="external-link"
            >
              {{ site.incoming_domains_from_links_count }} <i class="fas fa-external-link-alt"></i>
            </router-link>
            <div v-else>
              {{ site.incoming_domains_from_links_count }}
            </div>
          </td>

          <td class="text-center">
            <router-link
                v-if="site.incoming_links_count"
                :to="{name: 'OutgoingLinks', params: {url: site.url}}"
                class="external-link"
            >
              {{ site.incoming_links_count }} <i class="fas fa-external-link-alt"></i>
            </router-link>
            <div v-else>
              {{ site.incoming_links_count }}
            </div>
          </td>


          <td class="text-center">
            <router-link
                v-if="site.incoming_redirects_count"
                :to="{name: 'RedirectDomains', params: {url: site.url}}"
                class="external-link"
            >
              {{ site.incoming_redirects_count }} <i class="fas fa-external-link-alt"></i>
            </router-link>
            <div v-else>
              {{ site.incoming_redirects_count }}
            </div>
          </td>

          <td class="fixed-right-column table__action-column">
            <el-button-group>
              <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  title="Edit"
                  @click="handleEdit(site)"
              />
              <el-button
                  type="gray"
                  size="mini"
                  title="Logs"
                  icon="el-icon-tickets"
                  @click="handleLogs(site)"
              >
                Logs
              </el-button>
              <el-button
                  type="danger"
                  size="mini"
                  title="Delete"
                  icon="el-icon-delete"
                  @click="handleDelete(site)"
              />
            </el-button-group>
          </td>

        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import EmptyList from '@/components/global/EmptyList'
import SortLabel from '@/components/global/SortLabel'
import sortingMixin from '@/mixins/sortingMixin'

export default {
  name: 'ClientSitesList',
  mixins: [sortingMixin],
  components: {
    EmptyList,
    SortLabel
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('sites', [
      'clientSites'
    ]),
    ...mapGetters('sites', [
      'getSiteStatusById'
    ]),
    list() {
      return this.clientSites?.data
    }
  },
  methods: {
    ...mapMutations('sites', [
      'setActiveClientSite'
    ]),
    ...mapActions('sites', [
      'deleteClientSite'
    ]),
    async handleDelete(site) {
      try {
        const res = await this.$confirm('Are you sure to remove this site?')
        if (res) {
          await this.deleteClientSite(site.id)
          this.$emit('refetch-data')
        }
      } catch {}
    },
    handleEdit(site) {
      this.$emit('edit')
      this.setActiveClientSite(site)
    },
    handleLogs(site) {
      this.$emit('show-logs')
      this.setActiveClientSite(site)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.table-wrapper {
  max-height: calc(100vh - 340px) !important;
}

</style>
