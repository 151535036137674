<template>
  <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
  >
    <el-dialog
        :visible="visible"
        :before-close="handleClose"
        :title="isEdit ? 'Edit Client' : 'Create Client'"
        width="94%"
    >

      <div class="row">
        <div class="col-md-6">
          <el-form-item label="Name" prop="name">
            <el-input v-model="form.name"/>
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item label="Nickname" prop="username">
            <el-input v-model="form.username"/>
          </el-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <el-form-item label="Email" prop="email">
            <el-input v-model="form.email"/>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item
              :label="isEdit ? 'Change Password' : 'Password'"
              prop="password"
              :rules="isEdit ? rules.passwordForEdit : rules.passwordForCreate"
          >
            <el-input
                v-model="form.password"
                :placeholder="isEdit ? '*********' : ''"
            />
          </el-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <el-form-item label="Additional Emails" prop="emails">
            <div class="label-description">
              Separate with line break
            </div>
            <el-input
                v-model="form.emails"
                type="textarea"
                :rows="5"
            />
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item label="Timezone" prop="timezone">
            <el-select
                v-model="form.timezone"
                filterable
                class="multiselect"
            >
              <el-option
                  v-for="(key, value) in timezones"
                  :key="key"
                  :label="key"
                  :value="value"
              />
            </el-select>
          </el-form-item>

          <el-form-item v-if="isEdit">
            <div class="switch-wrapper">
              <el-switch
                  v-model="form.disable_notifications"
                  :inactive-value="0"
                  :active-value="1"
              />
              <div class="switch-label">
                Disable missed call notifications and warnings
              </div>
            </div>
          </el-form-item>
        </div>
      </div>


      <div v-if="isEdit && remoteSSOToken">
        <label>Remote SSO Token</label>
        <div class="token">
          <CopyToClipboard class="token__overlay" :copy="remoteSSOToken">
            <i class="fas fa-copy"></i>
          </CopyToClipboard>
          {{ remoteSSOToken }}
        </div>
      </div>
      <el-button
          v-if="isEdit"
          type="gray"
          @click="generateToken"
          class="token__button"
          size="mini"
          icon="el-icon-refresh-right"
      >
        Generate Remote Token
      </el-button>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="onSubmit">Save</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import modalMixin from '@/mixins/modalMixin'
import CopyToClipboard from '@/components/global/CopyToClipboard'

const defaultFormValues = {
  id: null,
  name: null,
  username: '',
  email: '',
  timezone: null,
  emails: null,
  disable_notifications: 0,
}

export default {
  name: 'AddEditClientDialog',
  components: { CopyToClipboard },
  mixins: [modalMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: { ...defaultFormValues },
      originFormValues: { ...defaultFormValues },
      fullClientInfo: null,
      rules: {
        name: [
          { required: true, message: 'Please input name', trigger: 'change' }
        ],
        username: [
          { required: true, message: 'Please input nickname', trigger: 'change' }
        ],
        email: [
          { required: true, message: 'Please enter email', trigger: 'change' }
        ],
        passwordForEdit: [
          { min: 8, message: 'The password must be at least 8 character', trigger: 'blur' },
          { required: false, trigger: 'change' }
        ],
        passwordForCreate: [
          { min: 8, message: 'The password must be at least 8 character', trigger: 'blur' },
          { required: true, message: 'Please enter password', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapState('clients', [
      'timezones',
      'activeClient'
    ]),
    isEdit() {
      return !!(this.activeClient?.id)
    },
    remoteSSOToken() {
      return this.fullClientInfo?.user?.remote_token
    },
    isFormChanged() {
      return  JSON.stringify(this.originFormValues) !== JSON.stringify(this.form)
    }
  },
  watch: {
    async activeClient() {
      if (this.isEdit) {
        this.fullClientInfo = await this.fetchClientById(this.activeClient.id)
        this.fillForm()
      }
    }
  },
  methods: {
    ...mapMutations('clients', [
      'setActiveClient'
    ]),
    ...mapActions('clients', [
      'createClient',
      'updateClient',
      'fetchClientById',
      'generateRemoteToken'
    ]),
    fillForm() {
      this.form = {...this.form, ...this.activeClient}
      this.form.username = this.activeClient?.user?.username
      this.form.email = this.activeClient?.user?.email
      this.form.disable_notifications = this.fullClientInfo?.disable_notifications
      this.originFormValues = {...this.form}
    },
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.isEdit) {
            await this.updateClient(this.form)
          } else {
            await this.createClient(this.form)
          }
          this.$emit('refetch-data')
          this.closeModal()
        }
      })
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal, this.isFormChanged)
    },
    closeModal() {
      this.resetForm()
      this.setActiveClient(null)
      this.$emit('close')
    },
    resetForm() {
      this.form = { ...defaultFormValues }
      this.$refs.form.resetFields()
    },
    async generateToken() {
      this.fullClientInfo.user.remote_token = await this.generateRemoteToken(this.activeClient.user.id)
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 700px;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}

.token {
  overflow-wrap: break-word;
  color: #AAAAAA;
  font-size: 12px;
  position: relative;

  &:hover {
    .token__overlay {
      opacity: 1;
    }
  }
}

.token__overlay {
  position: absolute;
  top: -6px;
  bottom: -6px;
  right: -6px;
  left: -6px;
  background: rgba(97, 196, 241, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 6px;
  opacity: 0;
  transition: 0.4s;
}

.token__button {
  margin-top: 10px;
}

.switch-wrapper {
  display: flex;
  align-items: center;
}

.switch-label {
  padding-left: 15px;
  line-height: 1.1;
}
</style>
