<template>
  <div v-loading="loading">
    <div v-if="(list && list.length) || loading" class="table-wrapper">
      <table class="table">
        <thead>
        <tr>
          <th>
            <SortLabel
                label="URL"
                :order="$_sortingMixin_checkOrder('url')"
                @click.native="$_sortingMixin_doSort('url')"
            />
          </th>
          <th>
            <SortLabel
                label="Category"
                :order="$_sortingMixin_checkOrder('category_id')"
                @click.native="$_sortingMixin_doSort('category_id')"
            />
          </th>
          <th class="text-center">
            <SortLabel
                label="Status"
                :order="$_sortingMixin_checkOrder('status')"
                @click.native="$_sortingMixin_doSort('status')"
            />
          </th>
          <th class="text-center">
            <SortLabel
                label="Http"
                :order="$_sortingMixin_checkOrder('http_status_code')"
                @click.native="$_sortingMixin_doSort('http_status_code')"
            />
          </th>
          <th>
            <SortLabel
                label="Registrar"
                :order="$_sortingMixin_checkOrder('registrar')"
                @click.native="$_sortingMixin_doSort('registrar')"
            />
          </th>
          <th>
            <SortLabel
                label="Registrant"
                :order="$_sortingMixin_checkOrder('registrant')"
                @click.native="$_sortingMixin_doSort('registrant')"
            />
          </th>
          <th>
            <SortLabel
                label="IP"
                :order="$_sortingMixin_checkOrder('ip')"
                @click.native="$_sortingMixin_doSort('ip')"
            />
          </th>
          <th>
            <SortLabel
                label="Registration Date"
                :order="$_sortingMixin_checkOrder('reverse_ip')"
                @click.native="$_sortingMixin_doSort('reverse_ip')"
            />
          </th>
          <th>
            <SortLabel
                label="Expiration Date"
                :order="$_sortingMixin_checkOrder('expiration_date')"
                @click.native="$_sortingMixin_doSort('expiration_date')"
            />
          </th>
          <th>
            <SortLabel
                label="Nameserver 1"
                :order="$_sortingMixin_checkOrder('nameserver_1')"
                @click.native="$_sortingMixin_doSort('nameserver_1')"
            />
          </th>
          <th>
            <SortLabel
                label="Nameserver 2"
                :order="$_sortingMixin_checkOrder('nameserver_2')"
                @click.native="$_sortingMixin_doSort('nameserver_2')"
            />
          </th>
          <th>
            <SortLabel
                label="Google Index"
                :order="$_sortingMixin_checkOrder('last_google_index')"
                @click.native="$_sortingMixin_doSort('last_google_index')"
            />
          </th>
          <th>
            <SortLabel
                label="301"
                :order="$_sortingMixin_checkOrder('redirect_url')"
                @click.native="$_sortingMixin_doSort('redirect_url')"
            />
          </th>
          <th>
            <SortLabel
                label="Reverse IP"
                :order="$_sortingMixin_checkOrder('reverse_ip')"
                @click.native="$_sortingMixin_doSort('reverse_ip')"
            />
          </th>
          <th>
            <SortLabel
              label="Outgoing links last error"
              :order="$_sortingMixin_checkOrder('outgoing_links_parsing_failed_at')"
              @click.native="$_sortingMixin_doSort('outgoing_links_parsing_failed_at')"
            />
          </th>
          <th class="fixed-right-column text-center">
            Actions
          </th>
        </tr>
        </thead>

        <tbody v-if="list">
        <tr v-for="site in list" :key="site.id">
          <td>
            <a :href="`//${site.url}`" target="_blank" class="external-link">
              {{ site.url }} <i class="fas fa-external-link-alt"></i>
            </a>
          </td>
          <td>
            {{ site.category && site.category.name }}
          </td>
          <td class="text-center">
            <div :class="+site.status ? 'text-success' : 'text-danger'">
              {{ getSiteStatusById(site.status) }}
            </div>
          </td>
          <td class="text-center">
            <div :class="site.http_status_code === 200 ? 'text-success' : 'text-danger'">
              {{ site.http_status_code }}
            </div>
          </td>
          <td>
            {{ site.registrar }}
          </td>
          <td>
            {{ site.registrant }}
          </td>
          <td>
            {{ site.ip && site.ip.ip }}
          </td>
          <td>
            {{ site.registration_date }}
          </td>
          <td>
            {{ site.expiration_date }}
          </td>
          <td class="cell-ns">
            {{ site.nameserver_1 }}
          </td>
          <td class="cell-ns">
            {{ site.nameserver_2 }}
          </td>
          <td>
            {{ site.last_google_index }}
          </td>
          <td>
            {{ site.redirect_url }}
          </td>
          <td>
            {{ site.reverse_ip }}
          </td>
          <td>
            <span v-if="site.outgoing_links_parsing_error" class="cell-links-parsing-errors">
              <el-tag type="danger">{{ site.outgoing_links_parsing_error }}</el-tag>
              <br />
              {{ site.outgoing_links_parsing_failed_at }}
            </span>
          </td>
          <td class="fixed-right-column table__action-column">
            <el-button-group>
              <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  title="Edit"
                  @click="handleEdit(site)"
              />
              <el-button
                  type="gray"
                  size="mini"
                  title="Logs"
                  icon="el-icon-tickets"
                  @click="handleLogs(site)"
              >
                Logs
              </el-button>
              <el-button
                  type="gray-alt"
                  size="mini"
                  title="Links"
                  icon="el-icon-link"
                  @click="handleLinks(site)"
              >
                Links
              </el-button>
              <el-button
                  type="danger"
                  size="mini"
                  title="Delete"
                  icon="el-icon-delete"
                  @click="handleDelete(site)"
              />
            </el-button-group>
          </td>

        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import EmptyList from '@/components/global/EmptyList'
import SortLabel from '@/components/global/SortLabel'
import sortingMixin from '@/mixins/sortingMixin'

export default {
  name: 'NetworkSitesList',
  mixins: [sortingMixin],
  components: {
    EmptyList,
    SortLabel
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState('sites', [
      'networkSites'
    ]),
    ...mapGetters('sites', [
      'getSiteStatusById'
    ]),
    list() {
      return this.networkSites?.data
    }
  },
  methods: {
    ...mapMutations('sites', [
      'setActiveNetworkSite'
    ]),
    ...mapActions('sites', [
      'deleteNetworkSite'
    ]),
    async handleDelete(site) {
      try {
        const res = await this.$confirm('Are you sure to remove this site?')
        if (res) {
          await this.deleteNetworkSite(site.id)
          this.$emit('refetch-data')
        }
      } catch {}
    },
    handleEdit(site) {
      this.$emit('edit')
      this.setActiveNetworkSite(site)
    },
    handleLogs(site) {
      this.$emit('show-logs')
      this.setActiveNetworkSite(site)
    },
    handleLinks(site) {
      this.$emit('show-links')
      this.setActiveNetworkSite(site)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.table-wrapper {
  max-height: calc(100vh - 340px) !important;
}

.cell-ns {
  font-size: 13px;
  text-transform: lowercase;
}

.cell-links-parsing-errors {
  white-space: nowrap;
}

</style>
