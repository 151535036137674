<template>
  <div v-loading="loading">
    <div v-if="(list && list.length) || loading" class="table-wrapper">
      <table class="table">
        <thead>
        <tr>
          <th>
            <SortLabel
                label="Title"
                :order="$_sortingMixin_checkOrder('title')"
                @click.native="$_sortingMixin_doSort('title')"
            />
          </th>
          <th class="text-center">
            Map Link
          </th>
          <th>
            <SortLabel
                label="Address"
                :order="$_sortingMixin_checkOrder('address')"
                @click.native="$_sortingMixin_doSort('address')"
            />
          </th>
          <th>
            <SortLabel
                label="Phone"
                :order="$_sortingMixin_checkOrder('phone')"
                @click.native="$_sortingMixin_doSort('phone')"
            />
          </th>
          <th>
            <SortLabel
                label="Website"
                :order="$_sortingMixin_checkOrder('website')"
                @click.native="$_sortingMixin_doSort('website')"
            />
          </th>
          <th>
            Service Area
          </th>
          <th>
            Primary Category
          </th>
          <th>
            Categories
          </th>
          <th>
            <SortLabel
                label="Description"
                :order="$_sortingMixin_checkOrder('description')"
                @click.native="$_sortingMixin_doSort('description')"
            />
          </th>
          <th>
            <SortLabel
                label="Email"
                :order="$_sortingMixin_checkOrder('email')"
                @click.native="$_sortingMixin_doSort('email')"
            />
          </th>


          <th class="fixed-right-column text-center">
            Actions
          </th>
        </tr>
        </thead>

        <tbody v-if="list">
        <tr v-for="item in list" :key="item.id">
          <td>
            {{ item.title }}
          </td>
          <td class="text-center">
            <a :href="item.google_maps_url" target="_blank">
              <el-button
                  type="info"
                  size="mini"
                  title="Google maps link"
                  class="link-button"
              >
                <i class="fas fa-external-link-alt"/>
              </el-button>
            </a>
          </td>
          <td>
            {{ item.address }}
          </td>
          <td class="no-wrap">
            {{ item.phone }}
          </td>
          <td>
            {{ item.website }}
          </td>
          <td>
            {{ item.service_area }}
          </td>
          <td class="no-wrap">
            {{ item.primary_category && item.primary_category.name }}
          </td>
          <td>
            <div v-for="category in item.categories" :key="category.id" class="no-wrap">
              {{category.name}}
            </div>
          </td>
          <td>
            {{ item.description }}
          </td>
          <td>
            {{ item.email }}
          </td>
          <td class="fixed-right-column table__action-column">
            <el-button-group>
              <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  title="Edit"
                  @click="handleEdit(item)"
              />
            </el-button-group>
          </td>

        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else/>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import EmptyList from '@/components/global/EmptyList'
import SortLabel from '@/components/global/SortLabel'
import sortingMixin from '@/mixins/sortingMixin'

export default {
  name: 'GMBLocationsList',
  mixins: [sortingMixin],
  components: {
    EmptyList,
    SortLabel
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('gmbs', [
      'gmbLocations'
    ]),
    list() {
      return this.gmbLocations?.data
    }
  },
  methods: {
    ...mapMutations('gmbs', [
      'setActiveGMBLocation'
    ]),
    handleEdit(item) {
      this.$emit('edit')
      this.setActiveGMBLocation(item)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.table-wrapper {
  max-height: calc(100vh - 340px) !important;
}

.table {
  td {
    vertical-align: top!important;
  }
}

.link-button {
  padding-right: 10px;

  i {
    margin-right: 0;
  }
}

.no-wrap {
  white-space: nowrap;
}

</style>
