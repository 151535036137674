import api from '@/utils/api'
import { Message } from 'element-ui'

export default {
    state: () => ({
        clientsList: null,
        activeClient: null,
        timezones: null
    }),
    mutations: {
        setClientsList(state, payload) {
            state.clientsList = Object.freeze(payload)
        },

        setActiveClient(state, payload) {
            state.activeClient = payload
        },

        setTimezones(state, payload) {
            state.timezones = Object.freeze(payload)
        }
    },
    getters: {
        getFormattedTimezone(state) {
            return (val) => {
                if (state.timezones) {
                    return state.timezones[val] || val
                }
                return val
            }
        }
    },
    actions: {
        async fetchClientsList({ commit }, params) {
            const result = await api.http.get('client', { params })
            commit('setClientsList', result?.data)
        },

        async fetchClientById(context, id) {
            const result = await api.http.get(`client/${id}`)
            return result?.data
        },

        async deleteClient(_, id) {
            await api.http.delete(`client/${id}`)
            Message.success('Client was removed successfully')
        },

        async createClient(_, form) {
            await api.http.post('client', form)
            Message.success('Client was created successfully')
        },

        async updateClient(_, form) {
            await api.http.put(`client/${form.id}`, form)
            Message.success('Client was updated successfully')
        },

        async toggleActivityStatus({ dispatch }, client) {
            await api.http.post('client/status', client)
            dispatch('fetchClientsList', false)
        },

        async fetchTimezones({ state, commit }, cache = true) {
            if (cache && state.timezones) return
            const result = await api.http.get('client/timezones')
            commit('setTimezones', result?.data)
        },

        async generateRemoteToken(context, user_id) {
            const result = await api.http.post(`user/${user_id}/remoteToken`)
            Message.success('New Token was generated')
            return result?.data?.token
        }
    },
    namespaced: true
}
