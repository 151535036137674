<template>
  <div v-loading="loading">
    <div v-if="(timezones && list && list.length) || loading" class="table-wrapper">
      <table class="table">
        <thead>
        <tr>
          <th>
            <SortLabel
                label="Client Name"
                :order="$_sortingMixin_checkOrder('name')"
                @click.native="doSort('name')"
            />
          </th>
          <th>
            <SortLabel
                label="Client Email"
                :order="$_sortingMixin_checkOrder('email')"
                @click.native="doSort('email')"
            />
          </th>
          <th class="text-center">
            <SortLabel
                label="Notifications"
                :order="$_sortingMixin_checkOrder('disable_notifications')"
                @click.native="doSort('disable_notifications')"
            />
          </th>
          <th>
            <SortLabel
                label="Client timezone"
                :order="$_sortingMixin_checkOrder('timezone')"
                @click.native="doSort('timezone')"
            />
          </th>
          <th>
            Campaigns
          </th>
          <th class="text-center">
            Active
          </th>
          <th class="fixed-right-column text-center">Actions</th>
        </tr>
        </thead>

        <tbody v-if="list">
        <tr v-for="client in list" :key="client.id">
          <td>
            {{ client.name }}
          </td>
          <td>
            {{ client.user && client.user.email }}
          </td>
          <td class="text-center">
            {{ client.disable_notifications ? 'Disabled' : 'Enabled' }}
          </td>
          <td>
            {{ getFormattedTimezone(client.timezone) }}
          </td>
          <td>
            <div
                v-for="campaign in client.campaigns"
                :key="campaign.id"
            >
              {{ campaign.name }}
            </div>
          </td>
          <td class="text-center">
            <el-switch
                :value="checkActivity(client.user)"
                active-color="#67C23A"
                @change="changeActivity(client)"
            />
          </td>

          <td class="fixed-right-column table__action-column">
            <el-button-group>
              <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  title="Edit"
                  @click="handleEdit(client)"
              />

              <el-button
                  type="danger"
                  size="mini"
                  title="Delete"
                  icon="el-icon-delete"
                  @click="handleDelete(client)"
              />
            </el-button-group>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else/>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import EmptyList from '@/components/global/EmptyList'
import sortingMixin from '@/mixins/sortingMixin'
import SortLabel from '@/components/global/SortLabel'

export default {
  name: 'ClientsList',
  mixins: [sortingMixin],
  components: {
    SortLabel,
    EmptyList
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: null
    }
  },
  computed: {
    ...mapState('clients', [
      'timezones'
    ]),
    ...mapGetters('clients', [
      'getFormattedTimezone'
    ])
  },
  methods: {
    ...mapMutations('clients', [
      'setActiveClient'
    ]),
    ...mapActions('clients', [
      'deleteClient',
      'toggleActivityStatus'
    ]),
    async handleDelete(client) {
      try {
        const res = await this.$confirm('Are you sure to remove this client?')
        if (res) {
          await this.deleteClient(client.id)
          this.$emit('refetch-data')
        }
      } catch { console.log('canceled')}
    },
    async handleEdit(client) {
      this.setActiveClient(client)
      this.$emit('edit')
    },
    async changeActivity(client) {
      client.user.is_active = client.user.is_active === 1 ? 0 : 1
      await this.toggleActivityStatus({
        id: client.user.id,
        is_active: client.user.is_active
      })
      this.$emit('refetch-data')
    },
    doSort(sortBy) {
      this.$_sortingMixin_prepareSort(sortBy)
      this.$emit('sort', {
        sort: this.sortingMixin_sortBy,
        order: this.sortingMixin_order
      })
    },
    checkActivity(val) {
      return val ? !!val.is_active : false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.table-wrapper {
  max-height: calc(100vh - 340px) !important;
}

</style>
