<template>
  <div>
    <div class="page-title-group">
      <h1>Links</h1>
    </div>

    <FilterPanel>
      <OutgoingLinksFilters
          :exportLoading="exportLoading"
          @filter="doFilter"
          @export="doExport"
      />
    </FilterPanel>

    <MainPanel>
      <div class="action-bar">
        <PageLimitSwitcher :active="filterObject.per_page" @click="setPageLimit"/>

        <el-pagination
            v-if="outgoingLinksList"
            background
            layout="prev, pager, next"
            :page-size="filterObject.per_page"
            :current-page="filterObject.page"
            :total="outgoingLinksList.total"
            hide-on-single-page
            @current-change="changePage"
        />

        <div class="table-total">
          Total Items: {{ outgoingLinksList && outgoingLinksList.total || '-' }}
        </div>
      </div>

      <OutgoingLinksList
          :loading="loading"
          @sort="doSort"
      />
    </MainPanel>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PageLimitSwitcher from '@/components/global/PageLimitSwitcher'
import OutgoingLinksList from '@/components/outgoing-links/OutgoingLinksList'
import OutgoingLinksFilters from '@/components/outgoing-links/OutgoingLinksFilters'
import FilterPanel from '@/components/global/FilterPanel'
import MainPanel from '@/components/global/MainPanel'

export default {
  name: 'OutgoingLinksPage',
  metaInfo: {
    title: 'Links'
  },
  components: {
    MainPanel,
    FilterPanel,
    OutgoingLinksFilters,
    OutgoingLinksList,
    PageLimitSwitcher
  },
  data() {
    return {
      loading: false,
      exportLoading: false,
      filterObject: {
        page: 1,
        per_page: 200
      }
    }
  },
  computed: {
    ...mapState('network', [
      'outgoingLinksList'
    ])
  },
  mounted() {
    this.getOutgoingLinks()
  },
  methods: {
    ...mapActions('network', [
      'fetchOutgoingLinks',
      'exportOutgoingLinks'
    ]),
    async getOutgoingLinks() {
      this.loading = true
      await this.fetchOutgoingLinks(this.filterObject)
      this.loading = false
    },
    doFilter(filters) {
      this.filterObject = { ...this.filterObject, ...filters }
      this.getOutgoingLinks()
    },
    doSort(sort) {
      this.filterObject = { ...this.filterObject, ...sort }
      this.filterObject.page = 1
      this.getOutgoingLinks()
    },
    doExport() {
      this.exportOutgoingLinks(this.filterObject)
    },
    setPageLimit(val) {
      this.filterObject.per_page = val
      this.filterObject.page = 1
      this.getOutgoingLinks()
    },
    changePage(page) {
      this.filterObject.page = page
      this.getOutgoingLinks()
    }
  }
}
</script>
