<template>
  <div>
    <TopNav/>

    <div class="site-content">
      <div class="site-content__inner">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from "@/components/global/TopNav.vue";

export default {
  name: "SimpleLayout",
  components: {TopNav}
}
</script>

<style scoped>
  .site-content {
    display: flex;
    justify-content: center;
  }

  .site-content__inner {
    width: 600px;
  }
</style>
