<template>
  <el-dialog
    :visible="visible"
    :before-close="closeModal"
    @open="onModelOpen"
    title="Edit Working Hours"
  >
    <el-form v-if="visible">
      <el-form-item label="Timezone" prop="timezone">
        <el-select
          v-model="timezone"
          filterable
          class="multiselect"
        >
          <el-option
            v-for="(key, value) in timezones"
            :key="key"
            :label="key"
            :value="value"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="row" v-for="(daySchedule, index) in schedule" :key="index">
        <el-checkbox size="medium" class="col-sm-1" v-model="daySchedule.enabled"></el-checkbox>
        <div class="col-sm-3 text-capitalize">{{ daySchedule.day }}</div>
        <div class="col-sm-4">
          <el-time-select
            placeholder="Start time"
            :value="daySchedule.startTime"
            v-model="daySchedule.startTime"
            :disabled="!daySchedule.enabled"
            :picker-options="{step: '00:30', start: '00:00', end: '24:00', format: 'HH:mm', maxTime: daySchedule.endTime}"
          >
          </el-time-select>
        </div>
        <div class="col-sm-4">
          <el-time-select
            placeholder="End time"
            v-model="daySchedule.endTime"
            :disabled="!daySchedule.enabled"
            :picker-options="{step: '00:30', start: '00:00', end: '24:00', format: 'HH:mm', minTime: daySchedule.startTime}"
          >
          </el-time-select>
        </div>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal">Cancel</el-button>
      <el-button type="primary" @click="onSave" :disabled="inProgress" :loading="inProgress">Save</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: 'EditWorkingHoursDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    campaign: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      inProgress: false,
      timezone: null,
      schedule: this.getDefaultScheduleValue(),
    };
  },
  computed: {
    ...mapState('clients', ['timezones']),
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchCampaignWorkingHours',
      'updateCampaignWorkingHours'
    ]),
    closeModal() {
      this.$emit('close')
    },
    async onModelOpen() {
      const { schedule, timezone } = await this.fetchCampaignWorkingHours(this.campaign.id);
      this.schedule = schedule || this.getDefaultScheduleValue();
      this.timezone = timezone;
    },
    async onSave() {
      if (this.inProgress) {
        return;
      }
      this.inProgress = true;
      try {
        await this.updateCampaignWorkingHours({
          id: this.campaign.id,
          workingHours: this.schedule,
          timezone: this.timezone,
        })
      } finally {
        this.inProgress = false;
      }
    },
    getDefaultScheduleValue() {
      return [
        {
          enabled: true,
          day: 'monday',
          startTime: '10:00',
          endTime: '19:00',
        },
        {
          enabled: true,
          day: 'tuesday',
          startTime: '10:00',
          endTime: '19:00',
        },
        {
          enabled: true,
          day: 'wednesday',
          startTime: '10:00',
          endTime: '19:00',
        },
        {
          enabled: true,
          day: 'thursday',
          startTime: '10:00',
          endTime: '19:00',
        },
        {
          enabled: true,
          day: 'friday',
          startTime: '10:00',
          endTime: '19:00',
        },
        {
          enabled: false,
          day: 'saturday',
          startTime: '10:00',
          endTime: '15:00',
        },
        {
          enabled: false,
          day: 'sunday',
          startTime: '10:00',
          endTime: '15:00',
        },
      ];
    },
  }
}
</script>

<style scoped lang="scss">

</style>
