<template>
  <div class="record">
    <div class="record__audio-wrapper" v-if="url">
      <audio ref="audio" controls="controls" :src="url" autoplay="autoplay"/>

      <div class="record__speed-list">
        <div
            class="record__speed-item"
            :class="{'record__speed-item--active': currentSpeed === 1}"
            @click="changeSpeed(1)"
        >
          1x
        </div>
        <div class="record__speed-divider"/>
        <div
            class="record__speed-item"
            :class="{'record__speed-item--active': currentSpeed === 1.5}"
            @click="changeSpeed(1.5)"
        >
          1.5x
        </div>
        <div class="record__speed-divider"/>
        <div
            class="record__speed-item"
            :class="{'record__speed-item--active': currentSpeed === 2}"
            @click="changeSpeed(2)"
        >
          2x
        </div>
      </div>
    </div>

    <el-tooltip
        v-else
        effect="dark"
        content="Play"
        placement="left"
    >
      <div class="record__icon-wrapper" @click="play">
        <i class="fa fa-play-circle record__play-button"/>
        <div class="record__duration">
          {{ formattedDuration }}
        </div>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LeadRecord',
  props: {
    lead: {
      type: Object,
    },
    recordingUrl: {
      type: String,
    }
  },
  data() {
    return {
      url: null,
      currentSpeed: 1
    }
  },
  computed: {
    formattedDuration() {
      if(!this.lead) return
      if (this.lead.lead_type === 'Form' || this.lead.answered === 'Answered') {
        return this.lead.duration_formatted
      } else if (this.lead.answered !== null && this.lead.answered !== 'Answered') {
        return this.lead.answered
      }
      return this.lead.duration_formatted
    }
  },
  methods: {
    ...mapActions('leads', [
      'fetchRecord'
    ]),
    async play() {
      if (!this.recordingUrl) {
        this.url = await this.fetchRecord(this.lead.id)
      } else {
        this.url = this.recordingUrl;
      }
    },
    changeSpeed(val) {
      this.currentSpeed = val
      this.$refs.audio.playbackRate = val
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.record {
  display: flex;
  align-items: flex-start;
}

.record__audio-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.record__speed-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}

.record__speed-item {
  font-size: 11px;
  cursor: pointer;
  color: #aaaaaa;

  &:hover {
    text-decoration: underline;
  }

  &.record__speed-item--active {
    color: #555555;
    font-weight: bold;
  }
}

.record__speed-divider {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #aaaaaa;
  margin-right: 6px;
  margin-left: 6px;
  position: relative;
  top: -1px;
}

.record__play-button {
  font-size: 30px;
  color: $color-primary;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: $color-success;
  }
}

audio {
  &:active, &:focus {
    outline: none;
  }
}

.record__icon-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.record__duration {
  margin-left: 10px;
}

</style>
