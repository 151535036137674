<template>
  <div v-if="summary" class="summary-table-wrapper">
    <table class="summary-table table">
      <thead>
      <tr>
        <th></th>
        <th>Approved PPC</th>
        <th>NET</th>
        <th>PPC</th>
        <th>Service fee</th>
      </tr>
      </thead>

      <tbody>
      <tr>
        <td>Total:</td>
        <td>{{ $_formatterMixin_formatCurrency(summary.approved_ppc.total) }}</td>
        <td>{{ $_formatterMixin_formatCurrency(summary.net.total) }}</td>
        <td>-</td>
        <td>{{ $_formatterMixin_formatCurrency(summary.service_fee.total) }}</td>
      </tr>
      <tr>
        <td>Average:</td>
        <td>{{ $_formatterMixin_formatCurrency(summary.approved_ppc.avg) }}</td>
        <td>-</td>
        <td>{{ $_formatterMixin_formatPercent(summary.ppc.avg) }}</td>
        <td>{{ $_formatterMixin_formatCurrency(summary.service_fee.avg) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import formatterMixin from '@/mixins/formatterMixin'

export default {
  name: 'CampaignsSummaryTable',
  mixins: [formatterMixin],
  props: {
    isArchived: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('campaigns', {
      activeCampaignsSummary: state => state.activeCampaignsSummary,
      archivedCampaignsSummary: state => state.archivedCampaignsSummary
    }),
    summary() {
      return this.isArchived ? this.archivedCampaignsSummary : this.activeCampaignsSummary
    }
  }
}
</script>

<style lang="scss" scoped>

.summary-table {
  margin-bottom: 0;
  max-width: 500px;
  margin-top: 10px;
}
</style>
