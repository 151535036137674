<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    label-position="top"
  >
    <el-dialog
      :before-close="handleClose"
      :title="isEdit ? 'Edit Campaign' : 'Create New Campaign'"
      :visible="visible"
      width="94%"
    >
      <div class="row">
        <div class="col-md-6">
          <el-form-item label="Campaign Name" prop="name">
            <el-input v-model="form.name" />
          </el-form-item>

          <el-form-item label="Categories" prop="categories">
            <el-select v-model="form.categories" multiple>
              <el-option
                v-for="category in campaignCategories"
                :key="category.id"
                :label="category.name"
                :value="category.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="Client" prop="client_id">
            <el-select v-model="form.client_id">
              <el-option
                v-for="client in clientsList && clientsList.data"
                :key="client.id"
                :label="client.name"
                :value="client.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="Client Sites">
            <el-select v-model="form.client_sites" multiple>
              <el-option
                v-for="site in clientSites"
                :key="site.id"
                :label="site.url"
                :value="site.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="Manager" prop="manager">
            <el-input v-model="form.manager" />
          </el-form-item>

          <el-link type="primary">Edit working hours</el-link>
        </div>

        <div class="col-md-6">
          <div class="row">
            <div class="col-sm-6">
              <el-form-item label="Start Date" prop="start_date">
                <el-date-picker
                  v-model="form.start_date"
                  format="MM/dd/yyyy"
                  type="date"
                >
                </el-date-picker>
              </el-form-item>
            </div>

            <div class="col-sm-6">
              <el-form-item label="Due Date" prop="due_date">
                <el-select v-model.number="form.due_date">
                  <el-option
                    v-for="day in Array.from({length: 31}, (_, i) => i + 1)"
                    :key="day"
                    :label="day"
                    :value="day"
                  />
                </el-select>
              </el-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <el-form-item label="Initial Target" prop="initial_target">
                <el-input :value="form.initial_target" @input="toNumber($event, 'initial_target')" />
              </el-form-item>
            </div>

            <div class="col-sm-4">
              <el-form-item label="3 Month Target" prop="month_3_target">
                <el-input :value="form.month_3_target" @input="toNumber($event, 'month_3_target')" />
              </el-form-item>
            </div>

            <div class="col-sm-4">
              <el-form-item label="6 Month Target" prop="month_6_target">
                <el-input :value="form.month_6_target" @input="toNumber($event, 'month_6_target')" />
              </el-form-item>
            </div>

            <div class="col-sm-4">
              <el-form-item label="9 Month Target" prop="month_9_target">
                <el-input :value="form.month_9_target" @input="toNumber($event, 'month_9_target')" />
              </el-form-item>
            </div>

            <div class="col-sm-4">
              <el-form-item label="12 Month Target" prop="month_12_target">
                <el-input :value="form.month_12_target" @input="toNumber($event, 'month_12_target')" />
              </el-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <el-form-item label="Service Fee">
                <currency-input
                  v-model="form.service_fee"
                  class="el-input__inner"
                />
              </el-form-item>
            </div>

            <div class="col-sm-6">
              <el-form-item label="Approved PPC">
                <currency-input
                  v-model="form.approved_ppc"
                  class="el-input__inner"
                />
              </el-form-item>
            </div>
          </div>

          <el-form-item>
            <el-switch
              v-model="form.fixed_to_top"
              :active-value="1"
              :inactive-value="0"
              active-text="Sticky in changelog"
            />
          </el-form-item>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="onSubmit" :disabled="inProgress" :loading="inProgress">Confirm</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import modalMixin from '@/mixins/modalMixin'

const defaultFormValues = {
  id: null,
  name: null,
  categories: [],
  client_id: null,
  client_sites: [],
  manager: null,
  start_date: null,
  due_date: null,
  initial_target: null,
  month_3_target: null,
  month_6_target: null,
  month_9_target: null,
  month_12_target: null,
  service_fee: null,
  approved_ppc: null,
  fixed_to_top: 0,
}

export default {
  name: 'AddEditCampaignDialog',
  mixins: [modalMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    activeCampaign: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {...defaultFormValues,},
      originFormValues: {...defaultFormValues,},
      inProgress: false,
      rules: {
        name: [
          {required: true, message: 'Please input campaign name', trigger: 'blur'},
        ],
        categories: [
          {required: true, message: 'Please select a category', trigger: 'blur'},
        ],
        client_id: [
          {required: true, message: 'Please select a client', trigger: 'change'},
        ],
        manager: [
          {required: true, message: 'Please input manager', trigger: 'blur'},
        ],
        start_date: [
          {required: true, message: 'Please pick start date', trigger: 'change'},
        ],
        due_date: [
          {required: true, message: 'Please select due date', trigger: 'change'},
        ],
        initial_target: [
          {required: true, message: 'Please input initial target', trigger: 'change'},
          {type: 'number', message: 'Input a number'},
        ],
        month_3_target: [
          {type: 'number', message: 'Input a number'},
        ],
        month_6_target: [
          {type: 'number', message: 'Input a number'},
        ],
        month_9_target: [
          {type: 'number', message: 'Input a number'},
        ],
        month_12_target: [
          {type: 'number', message: 'Input a number'},
        ],
      },
    }
  },
  computed: {
    ...mapState('campaigns', [
      'campaignCategories',
    ]),
    ...mapState('clients', [
      'clientsList',
    ]),
    ...mapState('sites', [
      'clientSites',
    ]),
    isEdit() {
      return !!(this.activeCampaign?.id)
    },
    isFormChanged() {
      return JSON.stringify(this.originFormValues) !== JSON.stringify(this.form)
    }
  },
  mounted() {
    this.fetchCampaignCategories()
    this.fetchClientsList({nopag: 1})
    this.fetchClientSites({nopag: 1, cache: true})
  },
  watch: {
    activeCampaign() {
      if (this.isEdit) {
        this.fillForm()
      }
    },
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchCampaignCategories',
      'editCampaign',
      'createCampaign',
    ]),
    ...mapActions('clients', [
      'fetchClientsList',
    ]),
    ...mapActions('sites', [
      'fetchClientSites',
    ]),
    toNumber(e, t) {
      this.$data.form[t] = isNaN(parseInt(e)) ? null : parseInt(e)
    },
    fillForm() {
      this.form = {...this.form, ...this.activeCampaign}
      this.form.client_id = this.activeCampaign?.client?.id

      this.form.client_sites = this.activeCampaign?.client_sites.map(x=>x.id)
      this.form.categories = this.activeCampaign?.categories.map(x=>x.id)

      this.form.initial_target = this.activeCampaign?.targets?.initial_target
      this.form.month_3_target = this.activeCampaign?.targets?.month_3_target
      this.form.month_6_target = this.activeCampaign?.targets?.month_6_target
      this.form.month_9_target = this.activeCampaign?.targets?.month_9_target
      this.form.month_12_target = this.activeCampaign?.targets?.month_12_target
      this.form.service_fee = parseFloat(this.activeCampaign.service_fee)
      this.form.approved_ppc = parseFloat(this.activeCampaign.approved_ppc)
      this.originFormValues = { ...this.form }
    },
    onSubmit() {
      if (this.inProgress) {
        return;
      }
      this.inProgress = true;
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            if (this.isEdit) {
              await this.editCampaign(this.form)
            } else {
              await this.createCampaign(this.form)
            }
            this.$emit('refetch-data')
            this.closeModal()
          } finally {
            this.inProgress = false;
          }
        } else {
          this.inProgress = false;
        }
      })
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal, this.isFormChanged)
    },
    closeModal() {
      this.resetForm()
      this.$emit('close')
    },
    resetForm() {
      this.form = {...defaultFormValues,}
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 1000px;
}

</style>
