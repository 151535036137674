<template>
  <div>
    <div class="page-title-group">
      <h1>Clients</h1>

      <el-button
          type="accent"
          icon="el-icon-plus"
          @click="openAddEditDialog"
      >
        Create New Client
      </el-button>
    </div>

    <FilterPanel>
      <SearchFilter @filter="doFilter"/>
    </FilterPanel>

    <MainPanel>
      <div class="action-bar">
        <PageLimitSwitcher :active="filterObject.per_page" @click="setPageLimit"/>

        <el-pagination
            v-if="list"
            background
            layout="prev, pager, next"
            :page-size="filterObject.per_page"
            :current-page="filterObject.page"
            :total="list.meta.total"
            hide-on-single-page
            @current-change="changePage"
        />

        <div class="table-total">
          Total Items: {{ list && list.meta.total || '-' }}
        </div>
      </div>

      <ClientsList
          :loading="loading"
          :list="list && list.data"
          @edit="openAddEditDialog"
          @refetch-data="getClientsList"
          @sort="doSort"
      />
    </MainPanel>

    <AddEditClientDialog
        :visible.sync="addEditDialogVisible"
        @close="closeAddEditDialog"
        @refetch-data="getClientsList"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ClientsList from '@/components/clients/ClientsList'
import AddEditClientDialog from '@/components/clients/AddEditClientDialog'
import MainPanel from '@/components/global/MainPanel'
import FilterPanel from '@/components/global/FilterPanel'
import SearchFilter from '@/components/redirect-domains/SearchFilter'
import PageLimitSwitcher from '@/components/global/PageLimitSwitcher'

export default {
  name: 'ClientsPage',
  metaInfo: {
    title: 'Clients'
  },
  components: {
    PageLimitSwitcher,
    SearchFilter,
    FilterPanel,
    MainPanel,
    AddEditClientDialog,
    ClientsList,
  },
  data() {
    return {
      loading: false,
      addEditDialogVisible: false,
      filterObject: {
        page: 1,
        per_page: 200
      }
    }
  },
  computed: {
    ...mapState('clients', {
      list: 'clientsList'
    })
  },
  mounted() {
    this.fetchTimezones()
    this.getClientsList()
  },
  methods: {
    ...mapActions('clients', [
      'fetchClientsList',
      'fetchTimezones'
    ]),
    async getClientsList() {
      this.loading = true
      await this.fetchClientsList(this.filterObject)
      this.loading = false
    },
    openAddEditDialog() {
      this.addEditDialogVisible = true
    },
    closeAddEditDialog() {
      this.addEditDialogVisible = false
    },
    doFilter(filters) {
      this.filterObject = { ...this.filterObject, ...filters }
      this.filterObject.search = filters.q
      this.getClientsList()
    },
    doSort(sort) {
      this.filterObject = { ...this.filterObject, ...sort }
      this.filterObject.page = 1
      this.getClientsList()
    },
    setPageLimit(val) {
      this.filterObject.per_page = val
      this.filterObject.page = 1
      this.getClientsList()
    },
    changePage(page) {
      this.filterObject.page = page
      this.getClientsList()
    }
  }
}
</script>
