<template>
  <el-form
      :model="filters"
      ref="form"
      label-position="top"
      class="filter-form"
  >

    <el-form-item label="IP">
      <el-input v-model.trim="filters.ip" v-debounce="doFilter"/>
    </el-form-item>

    <el-form-item label="Owner">
      <el-input v-model.trim="filters.owner" v-debounce="doFilter"/>
    </el-form-item>

    <el-form-item label="Country">
      <el-input v-model.trim="filters.country" v-debounce="doFilter"/>
    </el-form-item>

    <el-form-item label="Region">
      <el-input v-model.trim="filters.region" v-debounce="doFilter"/>
    </el-form-item>


    <el-button
        v-if="isFiltersChanged"
        type="gray"
        @click="clearFilters"
    >
      Reset filters
    </el-button>
  </el-form>
</template>

<script>

const defaultValues = {
  ip: null,
  owner: null,
  country: null,
  region: null,
}

export default {
  name: 'ActivityLogFilters',
  data() {
    return {
      originFilters: {...defaultValues},
      filters: {...defaultValues}
    }
  },
  computed: {
    isFiltersChanged() {
      return JSON.stringify(this.originFilters) !== JSON.stringify(this.filters)
    }
  },
  methods: {
    async doFilter() {
      this.$emit('filter', this.filters)
    },
    async clearFilters() {
      this.filters = {...defaultValues}
      this.$emit('filter', this.filters)
    }
  }
}
</script>
