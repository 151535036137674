<template>
  <div v-loading="loading">
    <div v-if="(list && list.length) || loading" class="table-wrapper">
      <table class="table">
        <thead>
        <tr>
          <th>
            <SortLabel
                label="Title"
                :order="$_sortingMixin_checkOrder('title')"
                @click.native="doSort('title')"
            />
          </th>
          <th>
            <SortLabel
                label="Address"
                :order="$_sortingMixin_checkOrder('address')"
                @click.native="doSort('address')"
            />
          </th>
          <th>
            <SortLabel
                label="Website"
                :order="$_sortingMixin_checkOrder('website')"
                @click.native="doSort('website')"
            />
          </th>
          <th>
            <SortLabel
                label="Phone"
                :order="$_sortingMixin_checkOrder('phone')"
                @click.native="doSort('phone')"
            />
          </th>
          <th>
            <SortLabel
                label="Campaign"
                :order="$_sortingMixin_checkOrder('campaign.name')"
                @click.native="doSort('campaign.name')"
            />
          </th>
          <th>
            <SortLabel
                label="Email"
                :order="$_sortingMixin_checkOrder('email')"
                @click.native="doSort('email')"
            />
          </th>
          <th class="text-center">
            <SortLabel
                label="Was changed"
                :order="$_sortingMixin_checkOrder('changed')"
                @click.native="doSort('changed')"
            />
          </th>
          <th>
            <SortLabel
                label="Last update"
                :order="$_sortingMixin_checkOrder('updated_at')"
                @click.native="doSort('updated_at')"
            />
          </th>
          <th>
            <SortLabel
                label="Review link"
                :order="$_sortingMixin_checkOrder('review_link')"
                @click.native="doSort('review_link')"
            />
          </th>
          <th class="text-center">
            <SortLabel
                label="Reviews Count"
                :order="$_sortingMixin_checkOrder('reviews_count')"
                @click.native="doSort('reviews_count')"
            />
          </th>
          <th>
            <SortLabel
                label="Recovery email"
                :order="$_sortingMixin_checkOrder('recovery_email')"
                @click.native="doSort('recovery_email')"
            />
          </th>
          <th>
            <SortLabel
                label="Notes"
                :order="$_sortingMixin_checkOrder('notes')"
                @click.native="doSort('notes')"
            />
          </th>
          <th>
            <SortLabel
                label="Status"
                :order="$_sortingMixin_checkOrder('status')"
                @click.native="doSort('status')"
            />
          </th>


          <th>
            <SortLabel
                label="Client owned"
                :order="$_sortingMixin_checkOrder('client_owned')"
                @click.native="doSort('client_owned')"
            />
          </th>
          <th>
            <SortLabel
                label="Client location"
                :order="$_sortingMixin_checkOrder('is_client_location')"
                @click.native="doSort('is_client_location')"
            />
          </th>

          <th class="fixed-right-column text-center">
            Actions
          </th>
        </tr>
        </thead>

        <tbody v-if="list">
        <tr
            v-for="item in list"
            :key="item.id"
            :class="{'tr-suspended': item.changed}"
        >
          <td class="cell-title">
            {{ item.title }}
          </td>
          <td class="cell-address">
            {{ item.address }}
          </td>
          <td>
            <a v-if="item.website" :href="item.website" target="_blank" class="external-link">
              {{ item.website }} <i class="fas fa-external-link-alt"></i>
            </a>
            <div v-else>
              &mdash;
            </div>
          </td>
          <td class="cell-phone">
            {{ item.phone }}
          </td>
          <td>
            {{ item.campaign && item.campaign.name }}
          </td>
          <td>
            {{ item.email }}
          </td>
          <td class="text-center">
            {{ item.changed ? 'Yes' : 'No' }}
          </td>
          <td class="cell-updated-at">
            {{ item.updated_at }}
          </td>
          <td>
            {{ item.review_link }}
          </td>
          <td class="text-center">
            {{ item.reviews_count }}
          </td>
          <td>
            {{ item.recovery_email }}
          </td>
          <td>
            {{ item.notes }}
          </td>
          <td>
            {{ getStatusById(item.status) }}
          </td>
          <td>
            <el-switch
                v-model="item.client_owned"
                active-color="#67C23A"
                @change="changeClientOwned(item.id)"
            />
          </td>
          <td>
            <el-switch
                v-model="item.is_client_location"
                active-color="#67C23A"
                @change="changeClientLocation(item.id)"
            />
          </td>

          <td class="fixed-right-column table__action-column">
            <el-button-group>
              <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  title="Edit"
                  @click="handleEdit(item)"
              />

              <el-button
                  type="gray"
                  size="mini"
                  title="Show Logs"
                  icon="el-icon-tickets"
                  @click="handleLogs(item)"
              >
                Logs
              </el-button>

              <a :href="item.link" target="_blank">
                <el-button
                    type="gray-alt"
                    size="mini"
                    title="View Campaign GMB"
                    icon="el-icon-map-location"
                >
                  GMap
                </el-button>
              </a>

              <el-button
                  type="danger"
                  size="mini"
                  title="Delete"
                  icon="el-icon-delete"
                  @click="handleDelete(item.id)"
              />
            </el-button-group>
          </td>

        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else/>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import EmptyList from '@/components/global/EmptyList'
import SortLabel from '@/components/global/SortLabel'
import sortingMixin from '@/mixins/sortingMixin'

export default {
  name: 'GmbsList',
  mixins: [sortingMixin],
  components: {
    EmptyList,
    SortLabel
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: null
    }
  },
  computed: {
    ...mapGetters('gmbs', [
      'getStatusById'
    ])
  },
  methods: {
    ...mapMutations('gmbs', [
      'setActiveGmb'
    ]),
    ...mapActions('gmbs', [
      'deleteGmb',
      'changeClientOwned',
      'changeClientLocation'
    ]),
    doSort(sortBy) {
      this.$_sortingMixin_prepareSort(sortBy)
      this.$emit('sort', {
        sort: this.sortingMixin_sortBy,
        order: this.sortingMixin_order
      })
    },
    async handleDelete(id) {
      const res = await this.deleteGmb(id)
      if (res) this.$emit('refetch-data')
    },
    handleEdit(item) {
      this.$emit('edit')
      this.setActiveGmb(item)
    },
    handleLogs(item) {
      this.$emit('show-logs')
      this.setActiveGmb(item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.table-wrapper {
  max-height: calc(100vh - 340px) !important;
}

.tr-suspended {
  td {
    background: $color-danger-bg !important;
  }
}

td {
  max-height: 50px !important;
}

.cell-title {
  min-width: 400px;
}

.cell-address {
  min-width: 220px;
}

.cell-phone {
  white-space: nowrap;
}

.cell-updated-at {
  min-width: 90px;
}

</style>
