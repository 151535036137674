<template>
  <div class="login-page">
    <div class="logo-wrapper">
      <img src="~@/assets/img/logo.png" alt="Logo" class="logo">
    </div>
    <div class="panel panel-default">
      <div class="panel-heading">
        Administrator Login
      </div>
      <div class="panel-body">
        <el-alert
            class="alert"
            type="info"
            :closable="false"
            show-icon>
          <template #title>
            If you are a client please click <a href='https://clients.markmywordsmedia.com'><strong>here</strong></a> to log in.
          </template>
        </el-alert>
        <div>

        </div>
        <LoginForm />
      </div>
    </div>
  </div>

</template>

<script>
import LoginForm from '@/components/auth/LoginForm'
export default {
  name: 'LoginPage',
  components: { LoginForm },
  metaInfo: {
    title: 'Login'
  },
}
</script>

<style lang="scss" scoped>

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.panel {
  min-width: 400px;
}

.panel-heading {
  text-align: center;
  font-size: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.logo {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.alert {
  margin-bottom: 20px;
}

::v-deep .el-alert__title {
  font-size: 14px;
}

</style>
