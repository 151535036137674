<template>
  <div v-loading="loading">
    <div v-if="(list && list.length) || loading" class="table-wrapper">
      <table class="table">
        <thead>
        <tr>
          <th>
            Network Name
          </th>
          <th class="text-center">
            Sites
          </th>
          <th class="fixed-right-column text-center">Actions</th>
        </tr>
        </thead>

        <tbody v-if="list">
        <tr v-for="category in list" :key="category.id">
          <td>
            {{ category.name }}
          </td>
          <td class="text-center">
            <router-link
                v-if="category.sites_count"
                :to="{name: 'NetworkSites', params: {category_id: category.id}}"
                class="external-link"
            >
              {{ category.sites_count }} <i class="fas fa-external-link-alt"></i>
            </router-link>
            <div v-else>
              {{ category.sites_count }}
            </div>
          </td>

          <td class="fixed-right-column table__action-column">
            <el-button-group>
              <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  title="Edit"
                  @click="handleEdit(category)"
              />

              <el-button
                  type="danger"
                  size="mini"
                  title="Delete"
                  icon="el-icon-delete"
                  @click="handleDelete(category)"
              />
            </el-button-group>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else/>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import EmptyList from '@/components/global/EmptyList'

export default {
  name: 'NetworkCategoriesList',
  components: {
    EmptyList
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('network', [
      'networkCategories'
    ]),
    list() {
      return this.networkCategories?.data
    }
  },
  methods: {
    ...mapMutations('network', [
      'setActiveNetworkCategory'
    ]),
    ...mapActions('network', [
      'deleteNetworkCategory'
    ]),
    async handleDelete(category) {
      try {
        const res = await this.$confirm('Are you sure to remove this category?')
        if (res) {
          await this.deleteNetworkCategory(category.id)
          this.$emit('refetch-data')
        }
      } catch {}
    },
    async handleEdit(category) {
      this.$emit('edit')
      this.setActiveNetworkCategory(category)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.table-wrapper {
  max-height: calc(100vh - 230px) !important;
}

</style>
