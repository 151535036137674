<template>
  <div class="panel" :class="{'panel-default': !errorMessage, 'panel-danger': !!errorMessage}">
    <div v-if="errorMessage" class="panel-heading">
      {{ errorMessage }}
    </div>
    <div v-if="lead" class="panel-body">
      <el-descriptions :column="1" border>
        <el-descriptions-item label="Campaign">{{ lead.campaign.name }}</el-descriptions-item>
        <el-descriptions-item label="Name">{{ lead.lead_name }}</el-descriptions-item>
        <el-descriptions-item label="Type">{{ lead.lead_type }}</el-descriptions-item>
        <el-descriptions-item label="Time">{{ lead.lead_date }}</el-descriptions-item>

        <el-descriptions-item v-if="lead.lead_email" label="Email">{{ lead.lead_email }}</el-descriptions-item>
        <el-descriptions-item v-if="lead.lead_phone" label="Phone">{{ lead.lead_phone }}</el-descriptions-item>
        <el-descriptions-item v-if="lead.lead_location" label="Location">{{ lead.lead_location }}</el-descriptions-item>
        <el-descriptions-item v-if="lead.message" label="Message">{{ lead.message }}</el-descriptions-item>

        <el-descriptions-item v-if="lead.recording" label="Recording">
          <LeadRecord :recording-url="lead.recordingUrl" />
        </el-descriptions-item>

        <el-descriptions-item v-if="lead.notes" label="Message">{{ lead.notes }}</el-descriptions-item>
        <el-descriptions-item v-if="lead.tags.length" label="Tags">
          <el-tag v-for="(tag, index) in lead.tags" :key="index">{{ tag.name }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item v-if="leadForm.client_progress !== 5" label="Hit Target">
          <el-rate v-model="leadForm.rating" @change="onRateChange" />
        </el-descriptions-item>

        <el-descriptions-item label="Progress">
          <el-radio-group v-model="leadForm.client_progress" size="mini">
            <el-radio-button :label="1">Won</el-radio-button>
            <el-radio-button :label="2">In Progress</el-radio-button>
            <el-radio-button :label="3">Lost</el-radio-button>
            <el-radio-button :label="4">Passed</el-radio-button>
            <el-radio-button :label="5">Not Lead</el-radio-button>
          </el-radio-group>
        </el-descriptions-item>

        <el-descriptions-item label="Company\Name">
          <el-input v-model="leadForm.company_name" />
        </el-descriptions-item>

        <el-descriptions-item label="Notes">
          <el-input v-model="leadForm.client_notes" />
        </el-descriptions-item>

        <el-descriptions-item label="Value">
          <el-input v-model="leadForm.client_value" />
        </el-descriptions-item>

        <el-descriptions-item label="Profit">
          <el-input v-model="leadForm.client_profit" />
        </el-descriptions-item>
      </el-descriptions>

      <div class="spacer-top">
        <el-button type="success" @click="saveLead">Save Lead</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import LeadRecord from "@/components/leads/LeadRecord.vue";
import {Message} from "element-ui";

export default {
  name: "RateLeadPage",
  components: {LeadRecord},
  data: () => ({
    errorMessage: null,
    lead: null,
    recordingUrl: null,
    authKey: null,
    leadForm: {
      client_progress: null,
      client_value: '',
      client_profit: '',
      client_notes: '',
      company_name: '',
      rating: 1,
    }
  }),
  async created() {
    const leadId = this.$route.query?.lead;
    if (!leadId) {
      this.errorMessage = 'Lead Not Found';
    }

    this.authKey = this.$route.query?.auth_key;
    if (!this.authKey) {
      this.errorMessage = 'Access Denied';
    }

    try {
      const responseData = await this.fetchLeadForRate({
        leadId,
        authKey: this.authKey,
      });
      this.lead = responseData.lead;
      //We need to cast string into int. This is huck
      this.lead.recordingUrl = responseData.recordingUrl;
      this.loadLeadIntoForm();
    } catch (error) {
      this.errorMessage = error.response?.data?.message || 'Lead Not Found';
    }

    //next, we should save rating or "not lead" mark based on page url
    if (this.$route.name === 'RateLead') {
      this.leadForm.rating = this.$route.query?.rate;
    } else {
      this.leadForm.client_progress = 5;
    }
    await this.saveLead();
  },
  computed: {
    ...mapState('leads', ['clientProgressList']),
  },
  methods: {
    ...mapActions('leads', ['fetchLeadForRate', 'saveLeadFromMail']),
    async onRateChange(newRate) {
      this.leadForm.rating = newRate;
      await this.saveLead();
    },
    loadLeadIntoForm() {
      this.lead.rating *= 1;
      this.leadForm.client_progress = this.lead.client_progress;
      this.leadForm.client_value = this.lead.client_value;
      this.leadForm.client_profit = this.lead.client_profit;
      this.leadForm.client_notes = this.lead.client_notes;
      this.leadForm.company_name = this.lead.company_name;
      this.leadForm.rating = this.lead.rating;
    },
    async saveLead() {
      try {
        const responseData = await this.saveLeadFromMail({
          leadId: this.lead.id,
          authKey: this.authKey,
          leadData: this.leadForm,
        });
        if (responseData.message) {
          Message.success(responseData.message);
        }
      } catch (error) {
        this.errorMessage = error.response?.data?.message || "Can't save lead";
      }
    }
  }
}
</script>

<style scoped>

</style>
