<template>
  <div>
    <div class="page-title-group">
      <h1>Activity Log</h1>

      <el-button
          type="accent"
          icon="el-icon-plus"
          @click="openAddEditDialog"
      >
        Create New Item
      </el-button>
    </div>

    <FilterPanel>
      <ActivityLogFilters
          @filter="doFilter"
          :defaultDataValues="defaultDataValues"
      />
    </FilterPanel>

    <MainPanel>
      <div class="action-bar">
        <PageLimitSwitcher :active="filterObject.per_page" @click="setPageLimit"/>

        <el-pagination
            v-if="activityLog"
            background
            layout="prev, pager, next"
            :page-size="filterObject.per_page"
            :current-page="filterObject.page"
            :total="activityLog.total"
            hide-on-single-page
            @current-change="changePage"
        />

        <div class="table-total">
          Total Items: {{ activityLog && activityLog.total }}
        </div>
      </div>

      <ActivityLogList
          :loading="loading"
          @edit="editItem"
          @refetch-data="getActivityLog"
      />
    </MainPanel>

    <AddEditItemDialog
        :visible.sync="addEditDialogVisible"
        @close="closeAddEditDialog"
        @refetch-data="getActivityLog"
    />

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { sub, add, format } from 'date-fns'
import ActivityLogList from '@/components/activity-log/ActivityLogList'
import ActivityLogFilters from '@/components/activity-log/ActivityLogFilters'
import PageLimitSwitcher from '@/components/global/PageLimitSwitcher'
import AddEditItemDialog from '@/components/activity-log/AddEditItemDialog'
import FilterPanel from '@/components/global/FilterPanel'
import MainPanel from '@/components/global/MainPanel'

const defaultDataValues = {
  startDate: format(sub(Date.now(), { months: 1 }), 'yyyy-MM-dd'),
  endDate: format(add(Date.now(), { months: 1 }), 'yyyy-MM-dd')
}

export default {
  name: 'ActivityLogPage',
  metaInfo: {
    title: 'Activity Log'
  },
  components: {
    MainPanel,
    FilterPanel,
    AddEditItemDialog,
    PageLimitSwitcher,
    ActivityLogFilters,
    ActivityLogList
  },
  data() {
    return {
      loading: false,
      addEditDialogVisible: false,
      defaultDataValues,
      filterObject: {
        from: defaultDataValues.startDate,
        to: defaultDataValues.endDate,
        page: 1,
        per_page: 200
      }
    }
  },
  computed: {
    ...mapState('activity-log', [
      'activityLog'
    ])
  },
  mounted() {
    this.getActivityLog()
  },
  methods: {
    ...mapActions('activity-log', [
      'fetchActivityLog'
    ]),
    ...mapActions('campaigns', [
      'fetchCampaignsWithTeams'
    ]),
    openAddEditDialog() {
      this.addEditDialogVisible = true
    },
    closeAddEditDialog() {
      this.addEditDialogVisible = false
    },
    editItem() {
      this.openAddEditDialog()
    },
    async getActivityLog() {
      this.loading = true
      await this.fetchActivityLog(this.filterObject)
      this.loading = false
    },
    doFilter(filters) {
      this.filterObject = { ...this.filterObject, ...filters }
      this.filterObject.from = filters.dateRange[0]
      this.filterObject.to = filters.dateRange[1]
      this.filterObject.page = 1
      this.getActivityLog()
    },
    setPageLimit(val) {
      this.filterObject.per_page = val
      this.filterObject.page = 1
      this.getActivityLog()
    },
    changePage(page) {
      this.filterObject.page = page
      this.getActivityLog()
    }
  }
}
</script>
