<template>
  <el-form
      :model="filters"
      ref="form"
      label-position="top"
      class="filter-form"
  >
    <el-form-item v-if="filters.host" label="Host">
      <el-input v-model="filters.host" disabled v-debounce="doFilter"/>
    </el-form-item>

    <el-form-item label="Outgoing Link">
      <el-input v-model="filters.q" v-debounce="doFilter"/>
    </el-form-item>

    <el-form-item v-if="!networkSiteDetermined" label="Network Site">
      <el-input v-model="filters.network_site_q" v-debounce="doFilter"/>
    </el-form-item>

    <el-button
        v-if="isFiltersChanged"
        type="gray"
        @click="clearFilters"
    >
      Reset filters
    </el-button>

    <el-button
        type="success"
        :loading="exportLoading"
        @click="$emit('export')"
    >
      <i class="fas fa-cloud-download-alt"></i>
      Export
    </el-button>

  </el-form>
</template>

<script>

const defaultValues = {
  q: '',
  network_site_q: '',
  host: null
}

export default {
  name: 'OutgoingLinksFilters',
  props: {
    exportLoading: {
      type: Boolean,
      default: false
    },
    networkSiteDetermined: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filters: { ...defaultValues },
      originFilters: { ...defaultValues }
    }
  },
  created() {
    this.applyIncomingParams()
  },
  computed: {
    isFiltersChanged() {
      return JSON.stringify(this.originFilters) !== JSON.stringify(this.filters)
    }
  },
  methods: {
    async doFilter() {
      this.$emit('filter', this.filters)
    },
    async clearFilters() {
      this.filters = { ...defaultValues }
      this.$emit('filter', this.filters)
    },
    applyIncomingParams() {
      const params = this.$route?.params
      if (params?.url) {
        this.filters.host = params?.url
        this.doFilter()
      }
    },
  }
}
</script>
