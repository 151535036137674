<template>
  <div v-loading="loading">
    <div v-if="(log && log.length) || loading" class="table-wrapper">
      <table class="table">
        <thead>
        <tr>
          <th>Time</th>
          <th>Status</th>
        </tr>
        </thead>

        <tbody v-if="log">
        <tr v-for="item in log" :key="item.id">
          <td>
            {{ item.created_at }}
          </td>
          <td>
            <div :class="+item.status ? 'text-success' : 'text-danger'">
              {{ getSiteStatusById(item.status) }}
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EmptyList from '@/components/global/EmptyList'

export default {
  name: 'ClientSiteLogList',
  components: {
    EmptyList
  },
  props: {
    log: {
      type: Array,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('sites', [
      'getSiteStatusById'
    ])
  }
}
</script>

<style scoped>

.table-wrapper {
  max-height: calc(100vh - 340px) !important;
  min-height: 50px;
}

.table {
  margin-bottom: 0;
}

::v-deep .empty-list {
  margin-bottom: 0;
}

</style>
