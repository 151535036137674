export default {
  data() {
    return {
      sortingMixin_sortBy: null,
      sortingMixin_order: null,
    }
  },

  methods: {
    $_sortingMixin_switchSortOrder() {
      this.sortingMixin_order = this.sortingMixin_order === 'asc' ? 'desc' : 'asc'
    },
    $_sortingMixin_checkOrder(sortedBy) {
      if (this.sortingMixin_sortBy === sortedBy) {
        return this.sortingMixin_order
      }
    },
    $_sortingMixin_prepareSort(sortedBy, defaultSorting = null) {
      if (this.sortingMixin_order === 'desc') {
        this.$_sortingMixin_switchSortOrder()
        this.sortingMixin_sortBy = defaultSorting
      } else if (this.sortingMixin_sortBy === sortedBy) {
        this.$_sortingMixin_switchSortOrder()
        this.sortingMixin_sortBy = sortedBy
      } else {
        this.sortingMixin_order = 'asc'
        this.sortingMixin_sortBy = sortedBy
      }
    },
    $_sortingMixin_doSort(sortBy) {
      this.$_sortingMixin_prepareSort(sortBy)
      this.$emit('sort', {
        sort: this.sortingMixin_sortBy,
        order: this.sortingMixin_order,
      })
    },
  },
}
