<template>
  <div v-loading="loading" class="changelog-table-outer">
    <div v-if="changelog || loading" class="table-wrapper">
      <table v-if="campaignsAndTeamsList" class="changelog-table table">
        <thead>
        <tr>
          <th class="fixed-left-column changelog-table__first-th">
            <div class="changelog-table__total-wrapper">
              <div
                  :class="{'changelog-table__badge--accent': changelog.total_changelog_count > 0}"
                  class="changelog-table__badge changelog-table__badge--total"
              >
                {{ changelog.total_changelog_count }}
              </div>
              Campaign
            </div>
          </th>
          <th v-for="(data, date) in changelog.days_list" :key="date">
            <div class="changelog-table__date-wrapper">
              {{ date }}
              <div
                  :class="{'changelog-table__badge--accent': data.total > 0}"
                  class="changelog-table__badge"
              >
                {{ data.total }}
              </div>
            </div>
          </th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="campaign in campaignsAndTeamsList" :key="campaign.id">
          <td class="fixed-left-column changelog-table__first-td">
            <div class="changelog-table__campaign-name">
              {{ campaign.name }}
            </div>

            <div>
              <div class="changelog-table__campaign-note">
                {{ campaign.note }}
              </div>

              <div
                  class="changelog-table__button changelog-table__button--primary"
                  @click="editCampaignNote(campaign)"
              >
                {{ campaign.note ? 'Edit Note' : 'Add Note' }}
              </div>
            </div>

          </td>
          <td
              v-for="(data, date) in changelog.changelogs_by_campaign[campaign.id].data"
              :key="date"
          >
            <div class="changelog-table__cell-header">
              <div class="changelog-table__notes-label">
                Notes: {{ data.total }}
              </div>
              <div
                  class="changelog-table__button"
                  @click="addNote(campaign.id, date)"
              >
                Add Note
              </div>
            </div>

            <div class="changelog-table__cell-body">
              <div v-if="data.data">
                <div
                    v-for="item in data.data"
                    :key="item.id"
                    class="changelog-table__note"
                    @click="editNote(item)"
                >

                  <div class="changelog-table__note-heading">
                    <div class="changelog-table__note-author">
                      <i class="changelog-table__note-img fas fa-user-circle"/>
                      {{ item.user && item.user.username }}
                    </div>
                    <div class="changelog-table__note-date">
                      <i class="far fa-clock"></i> {{ formatTime(item.created_at) }}
                    </div>
                  </div>

                  <nl2br tag="div" :text="item.note"/>

                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else/>
  </div>
</template>

<script>
import EmptyList from '@/components/global/EmptyList'
import { mapMutations, mapState } from 'vuex'
import Nl2br from 'vue-nl2br'
import { format, parse, parseISO } from 'date-fns'

export default {
  name: 'ChangelogTable',
  components: {
    EmptyList,
    Nl2br
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('activity-log', [
      'changelog'
    ]),
    campaignsAndTeamsList() {
      if (!this.changelog) return
      return [
        ...this.changelog.sticky_campaigns || [],
        ...this.changelog.campaigns || [],
        ...this.changelog.teams || []
      ]
    }
  },
  methods: {
    ...mapMutations('activity-log', [
      'setActiveCampaign',
      'setChangelogGridParams',
      'setActiveChangelogItem'
    ]),
    formatTime(date) {
      return format(parseISO(date), 'hh:mm')
    },
    editCampaignNote(campaign) {
      this.setActiveCampaign(campaign)
      this.$emit('edit-campaign-note')
    },
    addNote(campaignId, date) {
      this.setChangelogGridParams({ campaignId, date: format(parse(date, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd')})
      this.$emit('add-note')
    },
    editNote(item) {
      this.setActiveChangelogItem(item)
      this.$emit('edit-note')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.changelog-table-outer {
  margin: -7px;
}

.table-wrapper {
  max-height: calc(100vh - 270px) !important;
}

.changelog-table {

  position: relative;

  th {
    padding: 10px;
    background: #333333;
    border: 5px solid #fafafa;
    color: #aaa;
    font-weight: normal;
  }

  td {
    padding: 0;
    border: 5px solid #fafafa;
    background: transparent;
    vertical-align: top !important;
  }
}

th.changelog-table__first-th {
  background: #e8e8e8;
  border-left: 0;
  border-right: 0;
  color: #000;
}

td.changelog-table__first-td {
  vertical-align: top !important;
  padding-left: 5px;
  padding-top: 12px;
  padding-bottom: 13px;
  border-bottom: 1px solid #ffffff;
  border-top: 1px solid #ffffff;
}

.changelog-table__date-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 280px;
}

.changelog-table__total-wrapper {
  display: flex;
  align-items: center;
}

.changelog-table__badge {
  height: 20px;
  width: 20px;
  border-radius: 100px;
  background: #484848;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  font-size: 12px;

  &.changelog-table__badge--accent {
    background: $color-danger;
    color: #FFFFFF;
  }

  &.changelog-table__badge--total {
    width: 30px;
    margin-left: 0;
    margin-right: 30px;
  }
}

.changelog-table__cell-header {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e8e8e8;
  padding: 5px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.changelog-table__cell-body {
  min-height: 100px;
  background: #FFFFFF;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 300px;
  font-size: 13px;
  overflow-wrap: break-word;
}

.changelog-table__notes-label {
  color: #636363;
}

.changelog-table__button {
  background: $color-accent;
  color: #FFFFFF;
  padding: 3px 6px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  display: inline-block;
  min-width: 60px;
  text-align: center;

  &:hover {
    background: darken($color-accent, 6%);
  }

  &.changelog-table__button--primary {
    background: $color-primary;

    &:hover {
      background: darken($color-primary, 6%);
    }
  }

  &.changelog-table__button--success {
    background: $color-success;

    &:hover {
      background: darken($color-success, 6%);
    }
  }
}

.changelog-table__note {
  border-bottom: 1px solid #ececec;
  cursor: pointer;
  padding: 10px;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background: #fafafa;
  }
}

.changelog-table__note-heading {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.changelog-table__note-author {
  font-style: italic;
  display: flex;
  align-items: center;
}

.changelog-table__note-img {
  margin-right: 6px;
  font-size: 24px;
}

.changelog-table__note-date {
  font-size: 12px;
}

.changelog-table__campaign-name {
  margin-bottom: 7px;
  font-weight: 500;
  color: #222222;
}

.changelog-table__campaign-note {
  font-weight: normal;
  margin-bottom: 16px;
}

.changelog-table__campaign-note-input {
  margin-bottom: 10px;
}


</style>
